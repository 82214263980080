import * as React from "react";
import { SVGProps } from "react";
export const WebsiteSvgIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <clipPath id="b">
                <path
                    fill="none"
                    d="M0 0h160.487v138.059H0z"
                    data-name="Rectangle 13576"
                />
            </clipPath>
            <filter
                id="a"
                width={342}
                height={342}
                x={0}
                y={0}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur result="blur" stdDeviation={3} />
                <feFlood floodOpacity={0.161} />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g data-name="Groupe 1020">
            <g filter="url(#a)">
                <rect
                    width={324}
                    height={324}
                    fill="#f8f8f6"
                    data-name="Rectangle 13568"
                    rx={50}
                    transform="translate(9 6)"
                />
            </g>
            <g data-name="Groupe 1016">
                <g
                    clipPath="url(#b)"
                    data-name="Groupe 1015"
                    transform="translate(90.756 98.971)"
                >
                    <path
                        fill="#161615"
                        d="M63.6 115.48c-14.08 0-27.233.05-40.387-.039a32.764 32.764 0 0 1-8.279-.9A20.15 20.15 0 0 1 .076 95.05c-.085-25-.123-50.01.014-75.015A20.116 20.116 0 0 1 20.587.011q59.653-.02 119.306 0c11.349 0 20.428 8.679 20.491 20.024q.211 37.506.007 75.015c-.058 11.761-9.158 20.291-21.529 20.4-12.236.111-24.473.026-36.71.027h-5.333c1.946 3.795 3.48 7.055 5.311 10.139.377.637 1.85.839 2.828.866 3.588.1 7.181.014 10.773.053 4.8.051 7.221 1.883 7.32 5.465.1 3.661-2.679 6-7.307 6.009q-33.518.03-67.035.009c-1.862 0-3.733.109-5.584-.042-3.419-.279-5.862-2.856-5.683-5.989.2-3.534 2.355-5.269 5.714-5.4 3.852-.151 7.719-.187 11.567-.014 2.449.111 3.977-.629 4.934-2.96 1.039-2.533 2.377-4.943 3.941-8.137M80.632 11.127H22.754c-8.113 0-11.473 3.3-11.477 11.268q-.013 35.326 0 70.652c0 7.526 3.429 11.083 10.885 11.088q58.077.049 116.155 0c7.447-.006 10.872-3.567 10.877-11.1q.022-35.326 0-70.651c0-7.8-3.4-11.253-11.088-11.258q-28.74-.019-57.479 0"
                        data-name="Trac\xE9 1484"
                    />
                </g>
            </g>
        </g>
    </svg>
);
