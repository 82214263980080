import React from "react";
import { Modal, ModalBody, ModalFooter, Alert, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import NProgress from "nprogress";
import swal from "@sweetalert/with-react";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

import { logoutUser } from "@store/actions";

import PasswordStrengthMeter from "@pages/Authentication/ForgotPasswordModal/PasswordStrengthMeter";

import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import VerifiedSvgIcon from "@components/Common/SvgIcons/VerifiedSvgIcon";

import "./index.css";
import "@pages/Registration/index.css";

export default function ResetPasswordModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const { user } = useSelector((state: any) => state.Login);

    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = React.useState<
        boolean
    >(false);
    const [email, setEmail] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(true);
    const [
        isCurrentPasswordDisplayed,
        setIsCurrentPasswordDisplayed,
    ] = React.useState<boolean>(false);
    const [
        isConfirmedPasswordDisplayed,
        setIsConfirmedPasswordDisplayed,
    ] = React.useState<boolean>(false);
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [currentPassword, setCurrentPassword] = React.useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>(
        ""
    );
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(
        false
    );
    const [isPasswordConfirmed, setIsPasswordConfirmed] = React.useState<
        boolean
    >(false);
    const [isPasswordNotUpdated, setIsPasswordNotUpdated] = React.useState<
        boolean
    >(false);

    function checkConfirmedPasswords(e: any) {
        setConfirmNewPassword(e.target.value);

        if (newPassword === e.target.value) {
            setIsPasswordConfirmed(true);
            setNewPassword(newPassword);
        } else {
            setIsPasswordConfirmed(false);
        }
    }

    function checkCurrentPasswords(e: any) {
        setIsPasswordNotUpdated(false);
        setCurrentPassword(e.target.value);
    }

    function checkNewPassword(e: any) {
        setIsPasswordNotUpdated(false);
        setNewPassword(e.target.value);

        if (e.target.value === "") {
            setIsPasswordCorrect(false);
        } else if (
            e.target.value.match(/[0-9]/g) !== null &&
            e.target.value.length > 7 &&
            e.target.value.match(/[a-z]/g) !== null &&
            e.target.value.match(/[A-Z]/g) !== null &&
            e.target.value.match(/[^A-Za-z0-9]/g) !== null
        ) {
            setIsPasswordCorrect(true);
        } else {
            setIsPasswordCorrect(false);
        }

        if (confirmNewPassword !== e.target.value) {
            setIsPasswordConfirmed(false);
        } else if (e.target.value !== "") {
            setIsPasswordConfirmed(true);
            setNewPassword(e.target.value);
        }
    }

    async function sendUpdatePasswordRequest(
        email: string,
        currentPassword: string,
        newPassword: string,
        confirmNewPassword: string
    ) {
        NProgress.start();

        await fetch(`${process.env.REACT_APP_API_V1_URL}/auth/password`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${keycloak.token}`,
            },
            body: JSON.stringify({
                email: email,
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.error) {
                    setIsPasswordNotUpdated(true);
                    return swal({
                        icon: "error",
                        content: <p>{t(data.message)!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    setIsModalOpened(false);
                    dispatch(logoutUser(null));
                    return swal({
                        icon: "success",
                        content: <p>{t("Password updated successfully")}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            })
            .catch(() => {
                NProgress.done();
                setIsPasswordNotUpdated(true);
                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                    buttons: false,
                    timer: 2000,
                });
            });
    }

    React.useEffect(() => {
        if (user === null) {
            let user = JSON.parse(localStorage.getItem("authUser")!);
            setEmail(user.email);
        } else if (user !== undefined) {
            setEmail(user!.email);
        }
    }, [user]);

    return (
        <Modal size="lg" isOpen={isModalOpened} centered>
            <ModalBody className="mx-4 my-2">
                <div className="mb-4">
                    <div className="d-flex justify-content-center mb-2">
                        <VerifiedSvgIcon />
                    </div>
                    <p
                        className="text-uppercase"
                        style={{
                            textAlign: "center",
                            fontFamily: "Bebas Neue",
                            fontSize: "30px",
                        }}
                    >
                        {t("Please change your password")}
                    </p>
                </div>
                <div className="new-password-toggle__clz mb-3">
                    {isCurrentPasswordDisplayed ? (
                        <HidePasswordSvgIcon
                            onClick={() =>
                                setIsCurrentPasswordDisplayed(
                                    (prevState: boolean) => !prevState
                                )
                            }
                        />
                    ) : (
                        <ShowPasswordSvgIcon
                            onClick={() =>
                                setIsCurrentPasswordDisplayed(
                                    (prevState: boolean) => !prevState
                                )
                            }
                        />
                    )}
                </div>
                <div className="home-form-input-control__clz mb-3">
                    <h5>{t("Current password")}</h5>
                    <input
                        value={currentPassword}
                        type={isCurrentPasswordDisplayed ? "text" : "password"}
                        className="form-control custom-registration-input__clz"
                        placeholder={t("Current password")}
                        onChange={checkCurrentPasswords}
                    />
                </div>
                <div className="new-password-toggle__clz">
                    {isNewPasswordDisplayed ? (
                        <HidePasswordSvgIcon
                            onClick={() =>
                                setIsNewPasswordDisplayed(
                                    (prevState: boolean) => !prevState
                                )
                            }
                        />
                    ) : (
                        <ShowPasswordSvgIcon
                            onClick={() =>
                                setIsNewPasswordDisplayed(
                                    (prevState: boolean) => !prevState
                                )
                            }
                        />
                    )}
                </div>
                <div className="home-form-input-control__clz">
                    <h5>{t("New password")}</h5>
                    <input
                        value={newPassword}
                        type={isNewPasswordDisplayed ? "text" : "password"}
                        className={`custom-registration-input__clz form-control ${classnames(
                            {
                                "border-danger":
                                    !isPasswordCorrect &&
                                    newPassword !== undefined &&
                                    newPassword !== "",
                                "border-success": isPasswordCorrect,
                            }
                        )}`}
                        placeholder={t("New password")}
                        onChange={checkNewPassword}
                    />
                </div>
                <PasswordStrengthMeter password={newPassword} />
                <div className="new-password-toggle__clz">
                    {isConfirmedPasswordDisplayed ? (
                        <HidePasswordSvgIcon
                            onClick={() =>
                                setIsConfirmedPasswordDisplayed(
                                    (prevState: boolean) => !prevState
                                )
                            }
                        />
                    ) : (
                        <ShowPasswordSvgIcon
                            onClick={() =>
                                setIsConfirmedPasswordDisplayed(
                                    (prevState: boolean) => !prevState
                                )
                            }
                        />
                    )}
                </div>
                <div className="home-form-input-control__clz">
                    <h5>{t("Confirm password")}</h5>
                    <input
                        value={confirmNewPassword}
                        type={
                            isConfirmedPasswordDisplayed ? "text" : "password"
                        }
                        className={`form-control custom-registration-input__clz ${classnames(
                            {
                                "border-danger":
                                    !isPasswordConfirmed &&
                                    confirmNewPassword !== "" &&
                                    confirmNewPassword !== undefined,
                                "border-success": isPasswordConfirmed,
                            }
                        )}`}
                        placeholder={t("Confirm password")}
                        onChange={checkConfirmedPasswords}
                        disabled={!isPasswordCorrect}
                    />
                </div>
                {!isPasswordConfirmed &&
                confirmNewPassword !== "" &&
                confirmNewPassword !== undefined ? (
                    <Alert className="mt-2 mb-0" color="danger">
                        {t("Sorry, your passwords not confirmed")}.
                    </Alert>
                ) : null}
                {isPasswordNotUpdated ? (
                    <Alert className="mt-2 mb-0" color="danger">
                        {t("There's an error")}.
                    </Alert>
                ) : null}
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <Button
                    onClick={() => {
                        setNewPassword("");
                        setCurrentPassword("");
                        setConfirmNewPassword("");
                        setIsPasswordConfirmed(false);
                        setIsPasswordCorrect(false);
                        sendUpdatePasswordRequest(
                            email,
                            currentPassword,
                            newPassword,
                            confirmNewPassword
                        );
                    }}
                    disabled={
                        !isPasswordConfirmed ||
                        newPassword === "" ||
                        confirmNewPassword === ""
                    }
                    type="button"
                    color="primary"
                    className="w-50"
                >
                    {t("Validate")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
