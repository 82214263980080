import React from "react";
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "@vStore";
import classNames from "classnames";

export default function SidebarContent() {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { mainColor } = useSnapshot(store);

    React.useEffect(() => {
        var pathName = pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu")!;

            var items = ul.getElementsByTagName("a");

            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }

            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };

        initMenu();
    }, [pathname]);

    function activateParentDropdown(item: any): boolean {
        Array.from(document.querySelectorAll("li a.active")!).forEach(
            (element) => {
                element.classList.remove("active");
            }
        );

        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active");
                    parent3.childNodes[0].classList.add("mm-active");
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    }

    return (
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                    <Link
                        to="/dashboard"
                        className={classNames("waves-effect", {
                            "text-white": mainColor !== null,
                        })}
                    >
                        <i
                            className={classNames("bx bx-home", {
                                "text-white": mainColor !== null,
                            })}
                        ></i>
                        <span>{t("Dashboard")}</span>
                    </Link>
                </li>

                <li>
                    <Link
                        to="/#"
                        className={classNames("has-arrow waves-effect", {
                            "text-white": mainColor !== null,
                        })}
                    >
                        <i
                            className={classNames("bx bx-bar-chart", {
                                "text-white": mainColor !== null,
                            })}
                        ></i>
                        <span>{t("Statistics")}</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link
                                to="statistics-turnover"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Turnover")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="statistics-tickets"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Tickets")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="statistics-average-basket"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Average basket")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="statistics-sales-mode"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Sales mode")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="statistics-sales-support"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Sales support")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="regulation-modes"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Payment methods")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="families"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Families")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="sub-families"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Sub-families")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="statistics-products"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Items")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/#"
                                className={classNames(
                                    "has-arrow waves-effect",
                                    {
                                        "text-white": mainColor !== null,
                                    }
                                )}
                            >
                                <span>{t("Employees")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link
                                        to="employees-cashier"
                                        className={classNames({
                                            "text-white": mainColor !== null,
                                        })}
                                    >
                                        {t("Cashier")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="employees-cook"
                                        className={classNames({
                                            "text-white": mainColor !== null,
                                        })}
                                    >
                                        {t("Cook")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="employees-delivery-man"
                                        className={classNames({
                                            "text-white": mainColor !== null,
                                        })}
                                    >
                                        {t("Delivery man")}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link
                                to="statistics-out-of-stock"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Out of stock")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="shops"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Shops")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="clients"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Customers")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="transactions"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Transactions")}
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/#"
                        className={classNames("has-arrow waves-effect", {
                            "text-white": mainColor !== null,
                        })}
                    >
                        <i
                            className={classNames("bx bx-cog", {
                                "text-white": mainColor !== null,
                            })}
                        ></i>
                        <span>{t("Setting")}</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link
                                to="statistics-settings"
                                className={classNames({
                                    "text-white": mainColor !== null,
                                })}
                            >
                                {t("Statistics")}
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}
