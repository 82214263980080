import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";

import PageTitle from "@components/Common/PageTitle";
import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import CardSpinnerWrapper from "@components/Common/CardSpinnerWrapper";

import OrdersCancellationRow from "./OrdersCancellationRow";
import OrdersModificationRow from "./OrdersModificationRow";
import Top10EmployeesRow from "./Top10EmployeesRow";
import WorkedServiceRow from "./WorkedServiceRow";
import SelectFilter from "./SelectFilter";
import DiscountsRow from "./DiscountsRow";
import OffersRow from "./OffersRow";
import Cards from "./Cards";

export default function Cashier() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Cashier")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Cashier")}
                />
                <React.Suspense fallback={<CardSpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SelectFilter />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <Cards />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10EmployeesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <OrdersCancellationRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <OrdersModificationRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <OffersRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <DiscountsRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <WorkedServiceRow />
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
