import React from "react";

export const TurnoverSvgIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <filter
                id="Rectangle_13569"
                x={0}
                y={0}
                width={342}
                height={342}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.161} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g
            id="Groupe_1029"
            data-name="Groupe 1029"
            transform="translate(-7762 6583)"
        >
            <g
                transform="matrix(1, 0, 0, 1, 7762, -6583)"
                filter="url(#Rectangle_13569)"
            >
                <rect
                    id="Rectangle_13569-2"
                    data-name="Rectangle 13569"
                    width={324}
                    height={324}
                    rx={50}
                    transform="translate(9 6)"
                    fill="#f8f8f6"
                />
            </g>
            <g
                id="money_2_"
                data-name="money (2)"
                transform="translate(7829.4 -6511.065)"
            >
                <g
                    id="Groupe_930"
                    data-name="Groupe 930"
                    transform="translate(1 4.383)"
                >
                    <g
                        id="Groupe_899"
                        data-name="Groupe 899"
                        transform="translate(0 30.834)"
                    >
                        <g
                            id="Groupe_886"
                            data-name="Groupe 886"
                            transform="translate(0.081)"
                        >
                            <path
                                id="Trac\xE9_1253"
                                data-name="Trac\xE9 1253"
                                d="M214.379,60.591,35.364,102.182,1.018,52.909,180.033,11.316Z"
                                transform="translate(-1.018 -11.316)"
                                fill="#47a047"
                            />
                        </g>
                        <g
                            id="Groupe_887"
                            data-name="Groupe 887"
                            transform="translate(102.133 10.619)"
                        >
                            <path
                                id="Trac\xE9_1254"
                                data-name="Trac\xE9 1254"
                                d="M115.425,48.223,47.836,63.94c8.547-4.608,11.107-13.116,5.514-21.151a27.513,27.513,0,0,0-8.744-7.76,41.281,41.281,0,0,0-20.639-5.277L91.6,14.036a12.491,12.491,0,0,1,8.508.985,5.987,5.987,0,0,1,1.733,1.535L119.8,42.277C121.411,44.64,119.482,47.279,115.425,48.223Z"
                                transform="translate(-23.967 -13.704)"
                                fill="#96d696"
                            />
                        </g>
                        <g
                            id="Groupe_888"
                            data-name="Groupe 888"
                            transform="translate(164.696 14.13)"
                        >
                            <path
                                id="Trac\xE9_1255"
                                data-name="Trac\xE9 1255"
                                d="M46,15.291a3.224,3.224,0,0,1,1.028.892c.96,1.394-.184,2.962-2.587,3.514a7.254,7.254,0,0,1-5.02-.587,3.093,3.093,0,0,1-1.03-.909c-.984-1.38.184-2.962,2.538-3.514A7.382,7.382,0,0,1,46,15.291Z"
                                transform="translate(-38.036 -14.494)"
                                fill="#47a047"
                            />
                        </g>
                        <g
                            id="Groupe_889"
                            data-name="Groupe 889"
                            transform="translate(170.606 40.305)"
                        >
                            <path
                                id="Trac\xE9_1256"
                                data-name="Trac\xE9 1256"
                                d="M49.377,25.426c-.322.741-1.221,1.292-2.674,1.641a6.8,6.8,0,0,1-3.911-.116,6.057,6.057,0,0,1-.914-.429,5.622,5.622,0,0,1-1.805-1.662,2.7,2.7,0,0,1-.566-2.613c.348-.756,1.221-1.292,2.7-1.626a6.6,6.6,0,0,1,3.886.1,5.743,5.743,0,0,1,.972.445,5.374,5.374,0,0,1,1.771,1.66A2.647,2.647,0,0,1,49.377,25.426Zm-3.3.726c.9-.2,1.424-.566,1.522-1.089a2.2,2.2,0,0,0-.562-1.829A4.22,4.22,0,0,0,45.6,21.863c-.115-.066-.233-.127-.354-.183a3.714,3.714,0,0,0-2.407-.174c-.9.2-1.4.581-1.548,1.1a2.387,2.387,0,0,0,.537,1.844,4.594,4.594,0,0,0,1.456,1.354c.116.067.235.128.358.184a3.608,3.608,0,0,0,2.433.159"
                                transform="translate(-39.365 -20.38)"
                                fill="#414a61"
                            />
                        </g>
                        <g
                            id="Groupe_890"
                            data-name="Groupe 890"
                            transform="translate(162.858 42.272)"
                        >
                            <path
                                id="Trac\xE9_1257"
                                data-name="Trac\xE9 1257"
                                d="M41.529,20.822l4.321,6.229-1.728.392-3.664-5.3-2.179.509-.657-.929Z"
                                transform="translate(-37.623 -20.822)"
                                fill="#414a61"
                            />
                        </g>
                        <g
                            id="Groupe_891"
                            data-name="Groupe 891"
                            transform="translate(34.275 47.555)"
                        >
                            <path
                                id="Trac\xE9_1258"
                                data-name="Trac\xE9 1258"
                                d="M39.652,25.1a12.432,12.432,0,0,1,4.031,3.524c3.765,5.387-.712,11.514-10.025,13.663-6.934,1.615-14.6.567-19.591-2.316a12.311,12.311,0,0,1-4.029-3.535c-3.765-5.387.737-11.5,10.025-13.663C27,21.152,34.655,22.21,39.652,25.1Z"
                                transform="translate(-8.708 -22.01)"
                                fill="#47a047"
                            />
                        </g>
                        <g
                            id="Groupe_892"
                            data-name="Groupe 892"
                            transform="translate(139.213 20.039)"
                        >
                            <path
                                id="Trac\xE9_1259"
                                data-name="Trac\xE9 1259"
                                d="M40.252,16.611a3.17,3.17,0,0,1,1.041.915c.96,1.394-.184,2.962-2.587,3.514a7.254,7.254,0,0,1-5.02-.587,3.093,3.093,0,0,1-1.03-.909c-.984-1.38.184-2.962,2.562-3.528A7.323,7.323,0,0,1,40.252,16.611Z"
                                transform="translate(-32.306 -15.823)"
                                fill="#47a047"
                            />
                        </g>
                        <g
                            id="Groupe_893"
                            data-name="Groupe 893"
                            transform="translate(151.941 17.086)"
                        >
                            <path
                                id="Trac\xE9_1260"
                                data-name="Trac\xE9 1260"
                                d="M43.125,15.95a3.181,3.181,0,0,1,1.044.917c.986,1.379-.184,2.962-2.587,3.514a7.389,7.389,0,0,1-5.044-.617,3.24,3.24,0,0,1-1.03-.893c-.96-1.394.184-2.962,2.587-3.514A7.258,7.258,0,0,1,43.125,15.95Z"
                                transform="translate(-35.168 -15.159)"
                                fill="#47a047"
                            />
                        </g>
                        <g
                            id="Groupe_894"
                            data-name="Groupe 894"
                            transform="translate(181.194 37.849)"
                        >
                            <path
                                id="Trac\xE9_1261"
                                data-name="Trac\xE9 1261"
                                d="M51.759,24.875c-.322.741-1.221,1.292-2.674,1.641a6.8,6.8,0,0,1-3.911-.116,6.058,6.058,0,0,1-.914-.429,5.808,5.808,0,0,1-1.83-1.676,2.712,2.712,0,0,1-.541-2.6c.346-.755,1.221-1.292,2.673-1.641a6.8,6.8,0,0,1,3.911.116,6.286,6.286,0,0,1,.953.447,5.231,5.231,0,0,1,1.765,1.644A2.635,2.635,0,0,1,51.759,24.875Zm-3.3.726c.9-.2,1.424-.566,1.522-1.089a2.3,2.3,0,0,0-.562-1.829,4.323,4.323,0,0,0-1.436-1.372c-.113-.065-.231-.126-.352-.181a3.635,3.635,0,0,0-2.407-.174c-.9.2-1.45.581-1.548,1.1a2.217,2.217,0,0,0,.512,1.83,4.787,4.787,0,0,0,1.467,1.36c.113.065.229.124.347.178a3.679,3.679,0,0,0,2.458.174"
                                transform="translate(-41.746 -19.828)"
                                fill="#414a61"
                            />
                        </g>
                        <g
                            id="Groupe_895"
                            data-name="Groupe 895"
                            transform="translate(15 30.036)"
                        >
                            <path
                                id="Trac\xE9_1262"
                                data-name="Trac\xE9 1262"
                                d="M80.1,46.968a41.5,41.5,0,0,0,20.665,5.31L33.151,67.985a12.329,12.329,0,0,1-8.514-1.013,5.431,5.431,0,0,1-1.751-1.528L4.943,39.716c-1.618-2.352.324-5,4.353-5.938L76.914,18.071c-8.559,4.6-11.171,13.126-5.573,21.166A27.07,27.07,0,0,0,80.1,46.968ZM48.6,55.861c9.313-2.149,13.79-8.277,10.026-13.663a12.433,12.433,0,0,0-4.031-3.524C49.6,35.79,41.942,34.731,35,36.347c-9.288,2.164-13.791,8.276-10.026,13.663a12.312,12.312,0,0,0,4.029,3.535C34,56.429,41.664,57.476,48.6,55.861"
                                transform="translate(-4.373 -18.071)"
                                fill="#96d696"
                            />
                        </g>
                        <g
                            id="Groupe_896"
                            data-name="Groupe 896"
                            transform="translate(34.367 49.285)"
                        >
                            <path
                                id="Trac\xE9_1263"
                                data-name="Trac\xE9 1263"
                                d="M187.811,22.4l-.073,25.686L8.728,89.682,8.8,64Z"
                                transform="translate(-8.728 -22.399)"
                                fill="#47a047"
                            />
                        </g>
                        <g
                            id="Groupe_897"
                            data-name="Groupe 897"
                            transform="translate(34.368 50.309)"
                        >
                            <path
                                id="Trac\xE9_1264"
                                data-name="Trac\xE9 1264"
                                d="M187.747,26.282,8.738,67.55l-.005,1.843L187.742,28.127Z"
                                transform="translate(-8.714 -10.042)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1265"
                                data-name="Trac\xE9 1265"
                                d="M187.749,25.551,8.74,66.817l-.005,1.846L187.744,27.4Z"
                                transform="translate(-8.707 -12.56)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1266"
                                data-name="Trac\xE9 1266"
                                d="M187.757,22.63,8.748,63.9l-.005,1.843L187.752,24.473Z"
                                transform="translate(-8.679 -22.63)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1267"
                                data-name="Trac\xE9 1267"
                                d="M187.753,24.09,8.744,65.356,8.739,67.2,187.748,25.936Z"
                                transform="translate(-8.693 -17.596)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1268"
                                data-name="Trac\xE9 1268"
                                d="M187.751,24.821,8.742,66.087l-.005,1.846L187.746,26.666Z"
                                transform="translate(-8.7 -15.078)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1269"
                                data-name="Trac\xE9 1269"
                                d="M187.743,27.742,8.734,69.01l-.005,1.843L187.738,29.588Z"
                                transform="translate(-8.729 -5.008)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1270"
                                data-name="Trac\xE9 1270"
                                d="M187.755,23.36,8.746,64.624,8.741,66.47,187.75,25.2Z"
                                transform="translate(-8.686 -20.112)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1271"
                                data-name="Trac\xE9 1271"
                                d="M187.745,27.012,8.736,68.278l-.005,1.843L187.74,28.855Z"
                                transform="translate(-8.722 -7.524)"
                                fill="#5faf5f"
                            />
                        </g>
                        <path
                            id="Trac\xE9_1272"
                            data-name="Trac\xE9 1272"
                            d="M23.776,32.9,23.7,58.584l25.682-5.968.073-25.684Z"
                            transform="translate(77.255 42.509)"
                            fill="#ed5d71"
                        />
                        <path
                            id="Trac\xE9_1273"
                            data-name="Trac\xE9 1273"
                            d="M24.509,32.729l-.073,25.684,25.682-5.968.073-25.684Z"
                            transform="translate(79.783 41.922)"
                            fill="#e3e7f0"
                        />
                        <g
                            id="Groupe_898"
                            data-name="Groupe 898"
                            transform="translate(0 41.605)"
                        >
                            <path
                                id="Trac\xE9_1274"
                                data-name="Trac\xE9 1274"
                                d="M35.44,69.949l-.073,25.686L1,46.358l.073-25.686Z"
                                transform="translate(-1 -20.672)"
                                fill="#5faf5f"
                            />
                        </g>
                    </g>
                    <g
                        id="Groupe_928"
                        data-name="Groupe 928"
                        transform="translate(7.566)"
                    >
                        <g id="Groupe_926" data-name="Groupe 926">
                            <g id="Groupe_907" data-name="Groupe 907">
                                <g id="Groupe_906" data-name="Groupe 906">
                                    <g
                                        id="Groupe_900"
                                        data-name="Groupe 900"
                                        transform="translate(128.429 74.148)"
                                    >
                                        <path
                                            id="Trac\xE9_1275"
                                            data-name="Trac\xE9 1275"
                                            d="M92.212,21.057,92.135,48.15,31.582,83.339l.077-27.093Z"
                                            transform="translate(-31.582 -21.057)"
                                            fill="#47a047"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_901"
                                        data-name="Groupe 901"
                                        transform="translate(0 35.19)"
                                    >
                                        <path
                                            id="Trac\xE9_1276"
                                            data-name="Trac\xE9 1276"
                                            d="M131.207,86.444l-.077,27.093L2.7,39.389,2.778,12.3Z"
                                            transform="translate(-2.701 -12.296)"
                                            fill="#47a047"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_902"
                                        data-name="Groupe 902"
                                        transform="translate(0 35.189)"
                                    >
                                        <path
                                            id="Trac\xE9_1277"
                                            data-name="Trac\xE9 1277"
                                            d="M2.709,16.643,131.138,90.754l0-1.387L2.713,15.256Z"
                                            transform="translate(-2.674 -2.09)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1278"
                                            data-name="Trac\xE9 1278"
                                            d="M2.711,15.922,131.14,90.032l0-1.385L2.715,14.535Z"
                                            transform="translate(-2.667 -4.577)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1279"
                                            data-name="Trac\xE9 1279"
                                            d="M131.151,86.445,2.722,12.3l0,1.723L131.147,88.13Z"
                                            transform="translate(-2.646 -12.296)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1280"
                                            data-name="Trac\xE9 1280"
                                            d="M2.7,19.24,131.13,93.387l0-1.135L2.7,18.142Z"
                                            transform="translate(-2.701 7.854)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1281"
                                            data-name="Trac\xE9 1281"
                                            d="M2.705,18.084,131.134,92.195l0-1.385L2.709,16.7Z"
                                            transform="translate(-2.688 2.883)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1282"
                                            data-name="Trac\xE9 1282"
                                            d="M2.7,18.8,131.132,92.918l0-1.387L2.707,17.42Z"
                                            transform="translate(-2.695 5.368)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1283"
                                            data-name="Trac\xE9 1283"
                                            d="M2.707,17.363,131.136,91.475l0-1.387L2.711,15.978Z"
                                            transform="translate(-2.681 0.396)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1284"
                                            data-name="Trac\xE9 1284"
                                            d="M131.149,87.2,2.719,13.093l0,1.387L131.144,88.588Z"
                                            transform="translate(-2.653 -9.548)"
                                            fill="#77cb77"
                                        />
                                        <path
                                            id="Trac\xE9_1285"
                                            data-name="Trac\xE9 1285"
                                            d="M2.717,13.814l0,1.385L131.142,89.31l0-1.385Z"
                                            transform="translate(-2.66 -7.062)"
                                            fill="#77cb77"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_903"
                                        data-name="Groupe 903"
                                        transform="translate(56.485 67.802)"
                                    >
                                        <path
                                            id="Trac\xE9_1286"
                                            data-name="Trac\xE9 1286"
                                            d="M38.307,32.808,38.23,59.9,15.4,46.722,15.48,19.63Z"
                                            transform="translate(-15.404 -19.63)"
                                            fill="#d3dbea"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_904"
                                        data-name="Groupe 904"
                                        transform="translate(54.066 66.404)"
                                    >
                                        <path
                                            id="Trac\xE9_1287"
                                            data-name="Trac\xE9 1287"
                                            d="M14.936,19.315l-.077,27.093,2.419,1.4.077-27.093Z"
                                            transform="translate(-14.859 -19.315)"
                                            fill="#e34454"
                                        />
                                        <path
                                            id="Trac\xE9_1288"
                                            data-name="Trac\xE9 1288"
                                            d="M20.615,22.594l-.077,27.093,2.419,1.4.077-27.093Z"
                                            transform="translate(4.713 -8.015)"
                                            fill="#e34454"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_905"
                                        data-name="Groupe 905"
                                        transform="translate(0.077)"
                                    >
                                        <path
                                            id="Trac\xE9_1289"
                                            data-name="Trac\xE9 1289"
                                            d="M191.7,78.531l-60.553,35.19L2.719,39.572,63.272,4.383Z"
                                            transform="translate(-2.719 -4.383)"
                                            fill="#47a047"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g
                                id="Groupe_922"
                                data-name="Groupe 922"
                                transform="translate(17.595 9.849)"
                            >
                                <g
                                    id="Groupe_912"
                                    data-name="Groupe 912"
                                    transform="translate(99.766 75.22)"
                                >
                                    <g
                                        id="Groupe_908"
                                        data-name="Groupe 908"
                                        transform="translate(1.713 0.997)"
                                    >
                                        <path
                                            id="Trac\xE9_1290"
                                            data-name="Trac\xE9 1290"
                                            d="M35.913,24.144c.66.381.874.8.618,1.28a3.712,3.712,0,0,1-1.613,1.457,7.2,7.2,0,0,1-2.532.953,3.176,3.176,0,0,1-2.189-.367q-.955-.551-.641-1.266a3.8,3.8,0,0,1,1.613-1.484,7.223,7.223,0,0,1,2.533-.926A3.246,3.246,0,0,1,35.913,24.144Z"
                                            transform="translate(-29.478 -23.737)"
                                            fill="#96d696"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_909"
                                        data-name="Groupe 909"
                                        transform="translate(0 0)"
                                    >
                                        <path
                                            id="Trac\xE9_1291"
                                            data-name="Trac\xE9 1291"
                                            d="M39.622,26.265A4.107,4.107,0,0,1,37.542,28.4a8.888,8.888,0,0,1-3.683,1.211,5.64,5.64,0,0,1-3.458-.722c-1.037-.6-1.464-1.28-1.257-2q.276-1.062,2.1-2.123a8.991,8.991,0,0,1,3.659-1.225,5.629,5.629,0,0,1,3.435.735C39.4,24.89,39.83,25.543,39.622,26.265Zm-5.908,2.341a7.2,7.2,0,0,0,2.532-.953A3.712,3.712,0,0,0,37.859,26.2c.256-.476.042-.9-.618-1.28a3.246,3.246,0,0,0-2.211-.354,7.223,7.223,0,0,0-2.533.926,3.8,3.8,0,0,0-1.613,1.483q-.314.715.641,1.266a3.176,3.176,0,0,0,2.189.367"
                                            transform="translate(-29.093 -23.512)"
                                            fill="#4c5671"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_910"
                                        data-name="Groupe 910"
                                        transform="translate(9.316 5.38)"
                                    >
                                        <path
                                            id="Trac\xE9_1292"
                                            data-name="Trac\xE9 1292"
                                            d="M37.612,25.13c1.448.836.442,1.9-1,2.736s-3.264,1.427-4.721.586c-1.436-.829-.472-1.911.972-2.75a7.221,7.221,0,0,1,2.533-.926A3.247,3.247,0,0,1,37.612,25.13Z"
                                            transform="translate(-31.188 -24.722)"
                                            fill="#96d696"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_911"
                                        data-name="Groupe 911"
                                        transform="translate(7.595 4.383)"
                                    >
                                        <path
                                            id="Trac\xE9_1293"
                                            data-name="Trac\xE9 1293"
                                            d="M39.248,29.388c-2.166,1.259-4.91,1.778-7.141.49s-1.377-2.833.846-4.124a8.989,8.989,0,0,1,3.659-1.225,5.629,5.629,0,0,1,3.436.735C42.267,26.545,41.456,28.1,39.248,29.388Zm-3.829.2a7.2,7.2,0,0,0,2.532-.953c1.439-.836,2.443-1.9,1-2.736a3.247,3.247,0,0,0-2.211-.354,7.221,7.221,0,0,0-2.533.926c-1.443.839-2.408,1.921-.972,2.75a3.176,3.176,0,0,0,2.189.367"
                                            transform="translate(-30.801 -24.498)"
                                            fill="#4c5671"
                                        />
                                    </g>
                                </g>
                                <g
                                    id="Groupe_913"
                                    data-name="Groupe 913"
                                    transform="translate(138.383 62.729)"
                                >
                                    <path
                                        id="Trac\xE9_1294"
                                        data-name="Trac\xE9 1294"
                                        d="M45.258,21.449c1.721.994,1.73,2.6.02,3.594a6.828,6.828,0,0,1-6.2,0c-1.721-.994-1.731-2.6-.02-3.594A6.83,6.83,0,0,1,45.258,21.449Z"
                                        transform="translate(-37.777 -20.704)"
                                        fill="#47a047"
                                    />
                                </g>
                                <g
                                    id="Groupe_914"
                                    data-name="Groupe 914"
                                    transform="translate(120.11 52.179)"
                                >
                                    <path
                                        id="Trac\xE9_1295"
                                        data-name="Trac\xE9 1295"
                                        d="M41.149,19.076c1.7.98,1.706,2.587,0,3.58a6.82,6.82,0,0,1-6.181.014c-1.721-.994-1.731-2.6-.02-3.594A6.83,6.83,0,0,1,41.149,19.076Z"
                                        transform="translate(-33.668 -18.331)"
                                        fill="#5faf5f"
                                    />
                                </g>
                                <g
                                    id="Groupe_915"
                                    data-name="Groupe 915"
                                    transform="translate(129.256 57.459)"
                                >
                                    <path
                                        id="Trac\xE9_1296"
                                        data-name="Trac\xE9 1296"
                                        d="M43.185,20.252c1.721.994,1.73,2.6.02,3.594a6.828,6.828,0,0,1-6.2,0c-1.7-.98-1.707-2.586,0-3.58A6.823,6.823,0,0,1,43.185,20.252Z"
                                        transform="translate(-35.725 -19.519)"
                                        fill="#47a047"
                                    />
                                </g>
                                <g
                                    id="Groupe_916"
                                    data-name="Groupe 916"
                                    transform="translate(95.06 71.669)"
                                >
                                    <path
                                        id="Trac\xE9_1297"
                                        data-name="Trac\xE9 1297"
                                        d="M36.945,24.334l-7.661,4.452-1.25-.721,6.513-3.785-1.556-.9,1.148-.667Z"
                                        transform="translate(-28.035 -22.714)"
                                        fill="#4c5671"
                                    />
                                </g>
                                <g id="Groupe_921" data-name="Groupe 921">
                                    <g id="Groupe_917" data-name="Groupe 917">
                                        <path
                                            id="Trac\xE9_1298"
                                            data-name="Trac\xE9 1298"
                                            d="M99.329,35.832c-10.018-2.561-23.636-.734-33.5,5C55.943,46.575,52.843,54.459,57.3,60.25L8.834,32.269C5.958,30.609,5.919,27.9,8.8,26.225L40.423,7.848a11.508,11.508,0,0,1,10.435,0ZM32.612,35.55c6.672,3.852,17.436,3.852,24.066,0S63.272,25.463,56.6,21.61s-17.436-3.852-24.065,0-6.6,10.088.077,13.94"
                                            transform="translate(-6.658 -6.597)"
                                            fill="#96d696"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_918"
                                        data-name="Groupe 918"
                                        transform="translate(20.927 12.124)"
                                    >
                                        <path
                                            id="Trac\xE9_1299"
                                            data-name="Trac\xE9 1299"
                                            d="M40.379,12.213c6.673,3.852,6.708,10.087.079,13.939s-17.394,3.853-24.066,0-6.708-10.087-.077-13.94S33.707,8.36,40.379,12.213Z"
                                            transform="translate(-11.364 -9.324)"
                                            fill="#47a047"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_919"
                                        data-name="Groupe 919"
                                        transform="translate(48.65 28.102)"
                                    >
                                        <path
                                            id="Trac\xE9_1300"
                                            data-name="Trac\xE9 1300"
                                            d="M67.866,16.517a14.451,14.451,0,0,1,4.291,3.617c4.467,5.8,1.358,13.7-8.508,19.429-9.884,5.744-23.485,7.565-33.51,5a24.9,24.9,0,0,1-6.264-2.477,14.426,14.426,0,0,1-4.286-3.614c-4.456-5.791-1.356-13.675,8.528-19.419,9.866-5.733,23.483-7.56,33.5-5A24.781,24.781,0,0,1,67.866,16.517Z"
                                            transform="translate(-17.598 -12.917)"
                                            fill="#47a047"
                                        />
                                    </g>
                                    <g
                                        id="Groupe_920"
                                        data-name="Groupe 920"
                                        transform="translate(61.19 35.319)"
                                    >
                                        <path
                                            id="Trac\xE9_1301"
                                            data-name="Trac\xE9 1301"
                                            d="M110.9,42.518c2.9,1.674,2.939,4.383.057,6.058L79.33,66.953a11.516,11.516,0,0,1-10.458-.013L20.418,38.965c10.025,2.569,23.626.748,33.51-5C63.794,28.236,66.9,20.342,62.436,14.54ZM71.352,64.925a8.886,8.886,0,0,0,3.683-1.211c2.208-1.283,3.02-2.843.8-4.125a5.629,5.629,0,0,0-3.436-.735,8.99,8.99,0,0,0-3.659,1.225c-2.223,1.292-3.074,2.838-.846,4.124a5.639,5.639,0,0,0,3.458.722M63.76,60.541a8.887,8.887,0,0,0,3.683-1.211,4.107,4.107,0,0,0,2.079-2.137c.208-.722-.219-1.375-1.28-1.988a5.629,5.629,0,0,0-3.435-.735A8.991,8.991,0,0,0,61.147,55.7q-1.826,1.061-2.1,2.123c-.207.721.22,1.4,1.257,2a5.64,5.64,0,0,0,3.458.722M98.908,46.289a6.828,6.828,0,0,0,6.2,0c1.709-.993,1.7-2.6-.02-3.594a6.83,6.83,0,0,0-6.2,0c-1.711.994-1.7,2.6.02,3.594M80.635,35.739a6.82,6.82,0,0,0,6.181-.014c1.709-.993,1.7-2.6,0-3.58a6.83,6.83,0,0,0-6.2,0c-1.711.994-1.7,2.6.02,3.594m9.128,1.688c-1.711.994-1.7,2.6,0,3.58a6.828,6.828,0,0,0,6.2,0c1.709-.993,1.7-2.6-.02-3.594a6.823,6.823,0,0,0-6.181.014M55.537,56.962,63.2,52.51l-2.806-1.62-1.148.667,1.556.9L54.288,56.24Z"
                                            transform="translate(-20.418 -14.54)"
                                            fill="#96d696"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g
                                id="Groupe_925"
                                data-name="Groupe 925"
                                transform="translate(54.143 31.215)"
                            >
                                <g
                                    id="Groupe_923"
                                    data-name="Groupe 923"
                                    transform="translate(2.419 1.386)"
                                >
                                    <path
                                        id="Trac\xE9_1302"
                                        data-name="Trac\xE9 1302"
                                        d="M98.8,24.9,38.247,60.093,15.421,46.914l60.535-35.2Z"
                                        transform="translate(-15.421 -11.714)"
                                        fill="#e3e7f0"
                                    />
                                </g>
                                <g id="Groupe_924" data-name="Groupe 924">
                                    <path
                                        id="Trac\xE9_1303"
                                        data-name="Trac\xE9 1303"
                                        d="M75.43,11.4,14.877,46.591l2.419,1.4,60.535-35.2Z"
                                        transform="translate(-14.877 -11.402)"
                                        fill="#ed5d71"
                                    />
                                    <path
                                        id="Trac\xE9_1304"
                                        data-name="Trac\xE9 1304"
                                        d="M83.492,16.057,81.107,14.68,20.554,49.87l2.425,1.4Z"
                                        transform="translate(4.692 -0.105)"
                                        fill="#ed5d71"
                                    />
                                </g>
                            </g>
                        </g>
                        <g
                            id="Groupe_927"
                            data-name="Groupe 927"
                            transform="translate(128.506 74.148)"
                        >
                            <path
                                id="Trac\xE9_1305"
                                data-name="Trac\xE9 1305"
                                d="M92.165,25.4,31.612,60.555l0-1.387L92.161,24.018Z"
                                transform="translate(-31.57 -10.851)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1306"
                                data-name="Trac\xE9 1306"
                                d="M92.163,24.683,31.61,59.834l0-1.385L92.159,23.3Z"
                                transform="translate(-31.577 -13.338)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1307"
                                data-name="Trac\xE9 1307"
                                d="M31.6,56.246l60.553-35.19,0,1.723L31.6,57.931Z"
                                transform="translate(-31.599 -21.057)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1308"
                                data-name="Trac\xE9 1308"
                                d="M92.172,28,31.619,63.189l0-1.135L92.169,26.9Z"
                                transform="translate(-31.542 -0.907)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1309"
                                data-name="Trac\xE9 1309"
                                d="M92.169,26.845,31.616,62l0-1.385L92.165,25.46Z"
                                transform="translate(-31.556 -5.878)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1310"
                                data-name="Trac\xE9 1310"
                                d="M92.171,27.566,31.618,62.719l0-1.387L92.167,26.181Z"
                                transform="translate(-31.549 -3.393)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1311"
                                data-name="Trac\xE9 1311"
                                d="M92.167,26.124,31.614,61.277l0-1.387L92.163,24.739Z"
                                transform="translate(-31.563 -8.365)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1312"
                                data-name="Trac\xE9 1312"
                                d="M31.6,57.005,92.155,21.854l0,1.387L31.606,58.39Z"
                                transform="translate(-31.592 -18.309)"
                                fill="#5faf5f"
                            />
                            <path
                                id="Trac\xE9_1313"
                                data-name="Trac\xE9 1313"
                                d="M92.157,22.575l0,1.385L31.608,59.111l0-1.385Z"
                                transform="translate(-31.585 -15.823)"
                                fill="#5faf5f"
                            />
                        </g>
                    </g>
                    <g
                        id="Groupe_929"
                        data-name="Groupe 929"
                        transform="translate(0.002 72.438)"
                    >
                        <path
                            id="Trac\xE9_1314"
                            data-name="Trac\xE9 1314"
                            d="M35.375,74.332,1.008,25.134,1,27.058l34.367,49.2Z"
                            transform="translate(-0.993 -5.292)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1315"
                            data-name="Trac\xE9 1315"
                            d="M35.386,70.526,1.019,21.326l-.005,1.926,34.367,49.2Z"
                            transform="translate(-0.956 -18.419)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1316"
                            data-name="Trac\xE9 1316"
                            d="M35.378,73.569,1.01,24.373,1,26.3l34.367,49.2Z"
                            transform="translate(-0.986 -7.916)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1317"
                            data-name="Trac\xE9 1317"
                            d="M35.387,69.95,1.02,20.672l0,1.446,34.367,49.2Z"
                            transform="translate(-0.948 -20.672)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1318"
                            data-name="Trac\xE9 1318"
                            d="M35.379,72.809,1.012,23.611l-.005,1.924,34.367,49.2Z"
                            transform="translate(-0.978 -10.542)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1319"
                            data-name="Trac\xE9 1319"
                            d="M35.382,72.047,1.014,22.849l-.005,1.924,34.367,49.2Z"
                            transform="translate(-0.971 -13.167)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1320"
                            data-name="Trac\xE9 1320"
                            d="M35.384,71.286,1.017,22.088l-.005,1.926,34.367,49.2Z"
                            transform="translate(-0.963 -15.793)"
                            fill="#47a047"
                        />
                        <path
                            id="Trac\xE9_1321"
                            data-name="Trac\xE9 1321"
                            d="M35.373,75.09,1.006,25.9,1,27.818l34.367,49.2Z"
                            transform="translate(-1 -2.665)"
                            fill="#47a047"
                        />
                    </g>
                </g>
                <g
                    id="Groupe_944"
                    data-name="Groupe 944"
                    transform="translate(83.895 147.36)"
                >
                    <g id="Groupe_936" data-name="Groupe 936">
                        <g id="Groupe_935" data-name="Groupe 935">
                            <g
                                id="Groupe_931"
                                data-name="Groupe 931"
                                transform="translate(4.237 2.322)"
                            >
                                <path
                                    id="Trac\xE9_1322"
                                    data-name="Trac\xE9 1322"
                                    d="M63.112,49.431l-.014,4.5c0-3.184-2.09-6.339-6.27-8.748a30.7,30.7,0,0,0-15.045-3.6,30.363,30.363,0,0,0-15.017,3.6c-4.111,2.394-6.173,5.523-6.173,8.65l.014-4.512c0-3.142,2.062-6.256,6.173-8.65A30.477,30.477,0,0,1,41.8,37.057,30.854,30.854,0,0,1,56.843,40.67C61.036,43.092,63.126,46.261,63.112,49.431Z"
                                    transform="translate(-20.594 -37.057)"
                                    fill="#efa143"
                                />
                            </g>
                            <g
                                id="Groupe_932"
                                data-name="Groupe 932"
                                transform="translate(4.246 4.303)"
                            >
                                <path
                                    id="Trac\xE9_1323"
                                    data-name="Trac\xE9 1323"
                                    d="M56.839,41.111c8.324,4.806,8.368,12.6.1,17.408s-21.738,4.811-30.062.005-8.377-12.607-.1-17.418S48.506,36.3,56.839,41.111Z"
                                    transform="translate(-20.596 -37.502)"
                                    fill="#ffdd94"
                                />
                            </g>
                            <g
                                id="Groupe_933"
                                data-name="Groupe 933"
                                transform="translate(0 14.686)"
                            >
                                <path
                                    id="Trac\xE9_1324"
                                    data-name="Trac\xE9 1324"
                                    d="M70.638,39.991l-.014,3.515c-.011,3.755-2.477,7.5-7.4,10.367-9.927,5.769-26.064,5.764-36.047,0-5.03-2.9-7.544-6.716-7.533-10.521l.014-3.515c-.011,3.805,2.5,7.616,7.533,10.521,9.982,5.763,26.119,5.769,36.047,0C68.162,47.495,70.628,43.746,70.638,39.991Z"
                                    transform="translate(-19.641 -39.837)"
                                    fill="#efa143"
                                />
                            </g>
                            <g
                                id="Groupe_934"
                                data-name="Groupe 934"
                                transform="translate(0.014)"
                            >
                                <path
                                    id="Trac\xE9_1325"
                                    data-name="Trac\xE9 1325"
                                    d="M63.106,40.862c9.982,5.763,10.035,15.116.118,20.879s-26.064,5.764-36.047,0-10.054-15.116-.127-20.885S53.114,35.093,63.106,40.862ZM30.155,59.885c8.324,4.806,21.783,4.806,30.062-.005s8.226-12.6-.1-17.408-21.792-4.811-30.062,0S21.822,55.074,30.155,59.885Z"
                                    transform="translate(-19.644 -36.535)"
                                    fill="#febb61"
                                />
                            </g>
                        </g>
                    </g>
                    <g
                        id="Groupe_943"
                        data-name="Groupe 943"
                        transform="translate(14.004 6.363)"
                    >
                        <g
                            id="Groupe_937"
                            data-name="Groupe 937"
                            transform="translate(0.013 1.771)"
                        >
                            <path
                                id="Trac\xE9_1326"
                                data-name="Trac\xE9 1326"
                                d="M31.69,47.157l7.428-4.326v1.674L31.69,48.831l-1.714-1.02-.123-3.633a7.711,7.711,0,0,0-.2-1.674,1.311,1.311,0,0,0-.694-.857,2.372,2.372,0,0,0-1.224-.285,2.631,2.631,0,0,0-1.265.285,1.468,1.468,0,0,0-.449.409v1.51l-2.979.448a2.841,2.841,0,0,1-.245-1.224V41.118a3.273,3.273,0,0,0,.245,1.224l2.775-.367v-.9a1.284,1.284,0,0,1,.653-1.1,2.61,2.61,0,0,1,1.265-.287,2.353,2.353,0,0,1,1.224.287,1.721,1.721,0,0,1,.735.9,11.29,11.29,0,0,1,.163,1.632l.123,3.633Z"
                                transform="translate(-22.793 -33.797)"
                                fill="#efa143"
                            />
                            <path
                                id="Trac\xE9_1327"
                                data-name="Trac\xE9 1327"
                                d="M25.657,41.4V39.726c0-.327.245-.654.735-.939a3.482,3.482,0,0,1,2.53-.327,9.277,9.277,0,0,1,3.061,1.184A5.039,5.039,0,0,1,34.023,41.4a.994.994,0,0,1,.163.532V43.6a.9.9,0,0,0-.163-.53,5.039,5.039,0,0,0-2.041-1.754,9.234,9.234,0,0,0-3.061-1.184,3.472,3.472,0,0,0-2.53.327A1.1,1.1,0,0,0,25.657,41.4Z"
                                transform="translate(-12.924 -38.364)"
                                fill="#efa143"
                            />
                            <path
                                id="Trac\xE9_1328"
                                data-name="Trac\xE9 1328"
                                d="M39.619,40.472v1.674c0,.815-.612,1.59-1.837,2.284a8.1,8.1,0,0,1-4.775,1.062,12.571,12.571,0,0,1-5.061-1.674c-1.673-.978-2.612-1.959-2.9-2.937a2.051,2.051,0,0,1-.082-.49V38.716a2.051,2.051,0,0,0,.082.49c.286.978,1.224,1.959,2.9,2.939a12.568,12.568,0,0,0,5.061,1.672,7.735,7.735,0,0,0,4.775-1.06C39.007,42.064,39.619,41.289,39.619,40.472Z"
                                transform="translate(-15.296 -37.151)"
                                fill="#efa143"
                            />
                        </g>
                        <g id="Groupe_942" data-name="Groupe 942">
                            <g
                                id="Groupe_939"
                                data-name="Groupe 939"
                                transform="translate(9.7)"
                            >
                                <g id="Groupe_938" data-name="Groupe 938">
                                    <path
                                        id="Trac\xE9_1329"
                                        data-name="Trac\xE9 1329"
                                        d="M39.541,42.609q.4,1.489-1.776,2.753A7.857,7.857,0,0,1,33.017,46.4a12.425,12.425,0,0,1-5.074-1.67q-2.5-1.444-2.9-2.933c-.291-1.008.3-1.925,1.751-2.768a7.783,7.783,0,0,1,4.772-1.023,12.233,12.233,0,0,1,5.074,1.67Q39.1,41.1,39.541,42.609ZM33.293,44.58a3.642,3.642,0,0,0,2.543-.331c.7-.406.878-.9.563-1.474a5.336,5.336,0,0,0-2.062-1.76,9.387,9.387,0,0,0-3.045-1.189,3.649,3.649,0,0,0-2.543.331c-.7.406-.879.9-.563,1.474a5.238,5.238,0,0,0,2.061,1.761,9.477,9.477,0,0,0,3.046,1.188"
                                        transform="translate(-24.972 -37.966)"
                                        fill="#febb61"
                                    />
                                </g>
                            </g>
                            <g
                                id="Groupe_941"
                                data-name="Groupe 941"
                                transform="translate(0 5.838)"
                            >
                                <g id="Groupe_940" data-name="Groupe 940">
                                    <path
                                        id="Trac\xE9_1330"
                                        data-name="Trac\xE9 1330"
                                        d="M39.146,44.252l-7.43,4.318-1.746-1.008-.1-3.626a7.993,7.993,0,0,0-.192-1.669,1.441,1.441,0,0,0-.705-.858,2.357,2.357,0,0,0-1.223-.316,2.529,2.529,0,0,0-1.271.316c-.647.376-.826,1.023-.457,1.926l-2.984.436A2.857,2.857,0,0,1,22.9,41.83a3.483,3.483,0,0,1,1.654-1.655,7.1,7.1,0,0,1,3.293-.887,6.09,6.09,0,0,1,3.406.767,2.633,2.633,0,0,1,1.124,1.158,4.724,4.724,0,0,1,.4,2l.091,2.241,4.167-2.422Z"
                                        transform="translate(-22.79 -39.279)"
                                        fill="#febb61"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g
                    id="Groupe_958"
                    data-name="Groupe 958"
                    transform="translate(47.283 154.703)"
                >
                    <g id="Groupe_950" data-name="Groupe 950">
                        <g id="Groupe_949" data-name="Groupe 949">
                            <g
                                id="Groupe_945"
                                data-name="Groupe 945"
                                transform="translate(4.237 2.322)"
                            >
                                <path
                                    id="Trac\xE9_1331"
                                    data-name="Trac\xE9 1331"
                                    d="M54.879,51.082l-.014,4.5c0-3.184-2.09-6.339-6.27-8.748a30.7,30.7,0,0,0-15.045-3.6,30.363,30.363,0,0,0-15.017,3.6c-4.111,2.394-6.173,5.523-6.173,8.65l.014-4.512c0-3.142,2.062-6.256,6.173-8.65a30.477,30.477,0,0,1,15.017-3.613,30.854,30.854,0,0,1,15.045,3.613C52.8,44.743,54.893,47.913,54.879,51.082Z"
                                    transform="translate(-12.361 -38.708)"
                                    fill="#efa143"
                                />
                            </g>
                            <g
                                id="Groupe_946"
                                data-name="Groupe 946"
                                transform="translate(4.246 4.303)"
                            >
                                <path
                                    id="Trac\xE9_1332"
                                    data-name="Trac\xE9 1332"
                                    d="M48.605,42.763c8.324,4.806,8.368,12.6.1,17.408s-21.738,4.811-30.062.005-8.377-12.607-.1-17.418S40.272,37.952,48.605,42.763Z"
                                    transform="translate(-12.363 -39.154)"
                                    fill="#ffdd94"
                                />
                            </g>
                            <g
                                id="Groupe_947"
                                data-name="Groupe 947"
                                transform="translate(0 14.686)"
                            >
                                <path
                                    id="Trac\xE9_1333"
                                    data-name="Trac\xE9 1333"
                                    d="M62.405,41.642l-.014,3.515c-.011,3.755-2.477,7.5-7.4,10.367-9.927,5.769-26.064,5.764-36.047,0C13.911,52.62,11.4,48.808,11.408,45l.014-3.515c-.011,3.805,2.5,7.616,7.533,10.521,9.982,5.763,26.119,5.769,36.047,0C59.928,49.146,62.395,45.4,62.405,41.642Z"
                                    transform="translate(-11.408 -41.489)"
                                    fill="#efa143"
                                />
                            </g>
                            <g
                                id="Groupe_948"
                                data-name="Groupe 948"
                                transform="translate(0.014)"
                            >
                                <path
                                    id="Trac\xE9_1334"
                                    data-name="Trac\xE9 1334"
                                    d="M54.873,42.513c9.982,5.763,10.035,15.116.118,20.879s-26.064,5.764-36.047,0S8.89,48.277,18.818,42.508,44.881,36.745,54.873,42.513ZM21.922,61.536c8.324,4.806,21.783,4.806,30.062-.005s8.226-12.6-.1-17.408-21.792-4.811-30.062,0S13.589,56.725,21.922,61.536Z"
                                    transform="translate(-11.411 -38.186)"
                                    fill="#febb61"
                                />
                            </g>
                        </g>
                    </g>
                    <g
                        id="Groupe_957"
                        data-name="Groupe 957"
                        transform="translate(13.259 7.993)"
                    >
                        <g
                            id="Groupe_951"
                            data-name="Groupe 951"
                            transform="translate(0.009 1.786)"
                        >
                            <path
                                id="Trac\xE9_1335"
                                data-name="Trac\xE9 1335"
                                d="M26.961,46.612,29.247,45.3v1.634l-2.285,1.345-8.57-4.978-2,1.184-2-1.184V41.674l2,1.142,2-1.142Z"
                                transform="translate(-14.392 -35.944)"
                                fill="#efa143"
                            />
                            <path
                                id="Trac\xE9_1336"
                                data-name="Trac\xE9 1336"
                                d="M29.161,46.42a8.015,8.015,0,0,1-4.775,1.06,12.746,12.746,0,0,1-5.06-1.674c-1.673-.978-2.653-1.957-2.9-2.937a2.05,2.05,0,0,1-.081-.49V40.747a2.109,2.109,0,0,0,.081.448c.245.98,1.224,1.959,2.9,2.939a12.742,12.742,0,0,0,5.06,1.672,8.3,8.3,0,0,0,4.775-1.02C30.426,44.053,31,43.277,31,42.46v1.674C31,44.949,30.426,45.726,29.161,46.42Z"
                                transform="translate(-7.654 -39.14)"
                                fill="#efa143"
                            />
                            <path
                                id="Trac\xE9_1337"
                                data-name="Trac\xE9 1337"
                                d="M17.035,43.421V41.789c0-.369.285-.7.735-.98a3.543,3.543,0,0,1,2.53-.327,9.714,9.714,0,0,1,3.061,1.144,5.564,5.564,0,0,1,2.081,1.8.985.985,0,0,1,.163.53v1.632a1.156,1.156,0,0,0-.163-.488,5.568,5.568,0,0,0-2.081-1.8A9.9,9.9,0,0,0,20.3,42.114a3.73,3.73,0,0,0-2.53.369C17.28,42.768,17.035,43.094,17.035,43.421Z"
                                transform="translate(-5.281 -40.385)"
                                fill="#efa143"
                            />
                        </g>
                        <g id="Groupe_956" data-name="Groupe 956">
                            <g
                                id="Groupe_953"
                                data-name="Groupe 953"
                                transform="translate(8.721)"
                            >
                                <g id="Groupe_952" data-name="Groupe 952">
                                    <path
                                        id="Trac\xE9_1338"
                                        data-name="Trac\xE9 1338"
                                        d="M30.936,44.621c.264.994-.3,1.926-1.777,2.783a7.93,7.93,0,0,1-4.774,1.023,12.231,12.231,0,0,1-5.074-1.67q-2.5-1.444-2.9-2.933t1.777-2.754a7.888,7.888,0,0,1,4.773-1.053,12.23,12.23,0,0,1,5.074,1.67Q30.538,43.132,30.936,44.621Zm-6.248,2a3.569,3.569,0,0,0,2.517-.346c.725-.421.929-.9.589-1.459a5.365,5.365,0,0,0-2.063-1.79,9.7,9.7,0,0,0-3.07-1.174,3.463,3.463,0,0,0-2.517.346c-.7.406-.9.888-.589,1.459a5.765,5.765,0,0,0,2.062,1.76,10.3,10.3,0,0,0,3.071,1.2"
                                        transform="translate(-16.351 -39.984)"
                                        fill="#febb61"
                                    />
                                </g>
                            </g>
                            <g
                                id="Groupe_955"
                                data-name="Groupe 955"
                                transform="translate(0 5.014)"
                            >
                                <g id="Groupe_954" data-name="Groupe 954">
                                    <path
                                        id="Trac\xE9_1339"
                                        data-name="Trac\xE9 1339"
                                        d="M29.265,47.234l-2.278,1.324-8.6-4.964L16.4,44.751,14.39,43.593l4.271-2.482Z"
                                        transform="translate(-14.39 -41.111)"
                                        fill="#febb61"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
