import { proxy } from "valtio";

import { keycloak } from "@keycloak";

type ProductType = {
    designation: string;
    family: string;
    image: string;
    reference: string;
    subFamily: string;
};

type SettingsArticlesStoreType = {
    groupName: string;
    groupedArticles: any[];
    products: ProductType[];
    filteredProducts: ProductType[];
    families: string[];
    subFamilies: string[];
    globalFilterValue: string;
};

const store = proxy<SettingsArticlesStoreType>({
    groupName: "",
    groupedArticles: [],
    products: [],
    filteredProducts: [],
    families: [],
    subFamilies: [],
    globalFilterValue: "",
});

export async function getProducts() {
    const url = `${process.env.REACT_APP_API_V1_URL}/settings/products`;
    const response = await fetch(url, {
        headers: {
            accept: "application/json",
            "content-type": "application/json",
            authorization: `Bearer ${keycloak.token}`,
            "x-authorization": `Bearer ${localStorage.getItem("jwt")}`,
        },
    });

    const rawData = await response.json();

    if (rawData.error === false) {
        store.products = rawData.data;
        store.filteredProducts = store.products;
        setFamilies(store.products);
        setSubFamilies(store.products);
    }
}

export function setGroupName(groupName: string) {
    store.groupName = groupName;
}

export function setGroupedArticles(groupedArticles: any[]) {
    store.groupedArticles = groupedArticles;
}

function setFamilies(products: ProductType[]) {
    const localFamilies: string[] = [];

    products.forEach((article) => {
        if (article.family !== "") {
            localFamilies.push(article.family);
        }
    });

    store.families = [...new Set(localFamilies)];
}

function setSubFamilies(products: ProductType[]) {
    const localSubFamilies: string[] = [];

    products.forEach((article) => {
        if (article.subFamily !== "") {
            localSubFamilies.push(article.subFamily);
        }
    });

    store.subFamilies = [...new Set(localSubFamilies)];
}

export function setGlobalFilterValue(value: string) {
    store.globalFilterValue = value;
}

export function setFilteredProducts(products: ProductType[]) {
    store.filteredProducts = products;
}

export default store;
