import React from "react";

export default function RestaurantTicketSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8767">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g fill="#fff">
                    <path
                        data-name="Trac\xE9 8611"
                        d="M32.454 16.147a.879.879 0 00.879-.879V9.879A.879.879 0 0032.454 9H14.879a.879.879 0 00-.879.879v5.39a.879.879 0 00.879.879.879.879 0 010 1.758.879.879 0 00-.879.878v19.333a.879.879 0 00.879.879h17.575a.879.879 0 00.879-.879V18.784a.879.879 0 00-.879-.879.879.879 0 110-1.758zm-.879-1.607a2.637 2.637 0 000 4.972v17.726H15.758V19.512a2.637 2.637 0 000-4.972v-3.782h15.818z"
                    />
                    <path
                        data-name="Trac\xE9 8612"
                        d="M22.788 28.362v1.846h-.879a.879.879 0 100 1.758h3.515a.879.879 0 000-1.758h-.879v-1.846a4.4 4.4 0 003.515-4.305v-1.758a.879.879 0 00-.879-.879h-7.03a.879.879 0 00-.879.879v1.758a4.4 4.4 0 003.516 4.305zm-1.758-5.184h5.273v.879a2.636 2.636 0 11-5.273 0z"
                    />
                    <path
                        data-name="Trac\xE9 8613"
                        d="M20.152 35.481h7.03a.879.879 0 100-1.758h-7.03a.879.879 0 100 1.758z"
                    />
                    <path
                        data-name="Trac\xE9 8614"
                        d="M20.152 17.905h1.758a.879.879 0 000-1.758h-1.758a.879.879 0 000 1.758z"
                    />
                    <path
                        data-name="Trac\xE9 8615"
                        d="M25.424 17.905h1.758a.879.879 0 100-1.758h-1.758a.879.879 0 100 1.758z"
                    />
                </g>
            </g>
        </svg>
    );
}
