import React from "react";
import {
    StyledParagraph,
    StyledTextInput,
    StyledButton,
} from "@aureskonnect/react-ui";
import { Modal, ModalBody, Alert, ModalFooter } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { useKeycloak } from "@react-keycloak/web";

import { store as vStore } from "@vStore";
import store, { setGroupName } from "../store";

import { SettingsArticlesContext } from "../context";

type PropsType = {
    isModalOpened: boolean;
    isGroupNameEmpty: boolean;
    isNameExistsInGroupsList: boolean;
    toggleOpeningModal: () => void;
    setNewGroupDesignation: React.Dispatch<React.SetStateAction<string>>;
    setIsGroupNameEmpty: React.Dispatch<React.SetStateAction<boolean>>;
    setIsNameExistsInGroupsList: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ModalWrapper({
    isModalOpened,
    isGroupNameEmpty,
    isNameExistsInGroupsList,
    toggleOpeningModal,
    setNewGroupDesignation,
    setIsGroupNameEmpty,
    setIsNameExistsInGroupsList,
}: PropsType) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { mainColor } = useSnapshot(vStore);
    const { groupName } = useSnapshot(store);

    const { groups: articlesGroups } = React.useContext(
        SettingsArticlesContext
    );

    const [
        newGroupDesignationInModal,
        setNewGroupDesignationInModal,
    ] = React.useState<string>("");

    const [
        isGroupNameContainsSpecialStrings,
        setIsGroupNameContainsSpecialStrings,
    ] = React.useState<boolean>(false);

    function handleNewGroupDesignationInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        let specialStrings: string[] = [
            "\\",
            "+",
            "-",
            "&",
            "/",
            "*",
            "!",
            "?",
            "ç",
            "#",
            "~",
            "^",
            "@",
            '"',
            "]",
            "}",
            "#",
            "{",
            "[",
            "|",
            "-",
            "'",
        ];

        setIsGroupNameContainsSpecialStrings(
            specialStrings.filter((string: any) => {
                return e.target.value.includes(string);
            }).length > 0
        );

        setIsGroupNameEmpty(
            e.target.value.length === 0 || e.target.value.trim().length === 0
        );

        setIsNameExistsInGroupsList(
            articlesGroups.filter(
                (group: any) => group === e.target.value.trim()
            ).length > 0
        );
        setNewGroupDesignationInModal(e.target.value);
    }

    async function handleValidateNewGroupDesignationButtonOnClickEvent() {
        if (newGroupDesignationInModal === "") {
            setIsGroupNameEmpty(true);
        } else {
            toggleOpeningModal();
            setIsGroupNameEmpty(false);

            try {
                NProgress.start();

                const apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group-name`;

                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                        "x-authorization": `Bearer ${localStorage.getItem(
                            "jwt"
                        )}`,
                    },
                    body: JSON.stringify({
                        "old-name": groupName,
                        "new-name": newGroupDesignationInModal,
                    }),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        NProgress.done();

                        setGroupName(newGroupDesignationInModal);

                        setNewGroupDesignation(newGroupDesignationInModal);

                        if (data.error === true) {
                            throw Error(
                                "Error while editing products group name!"
                            );
                        }

                        return swal({
                            icon: "success",
                            content: <p>{t("group updated successfully")}!</p>,
                        });
                    });
            } catch (error: any) {
                setGroupName(groupName);
                setNewGroupDesignation(groupName);
                NProgress.done();
                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                });
            }
        }
    }

    return (
        <Modal isOpen={isModalOpened} centered={true}>
            <ModalBody className="justify-content-center">
                <StyledParagraph className="text-uppercase font-weight-bold text-center mt-4">
                    {t("Article groups")}
                </StyledParagraph>
                {isGroupNameEmpty ? (
                    <Alert className="mx-4" color="danger">
                        {t("Please enter the name of the group")}
                    </Alert>
                ) : null}
                {isGroupNameContainsSpecialStrings ? (
                    <Alert className="mx-4 mt-2" color="danger">
                        {t(
                            "The name should not contain any special characters"
                        )}
                    </Alert>
                ) : null}
                {isNameExistsInGroupsList ? (
                    <Alert className="mx-4 mt-2" color="danger">
                        {t("This group name is used before")}
                    </Alert>
                ) : null}
                <AvForm>
                    <StyledTextInput
                        id="articles-group-designation"
                        name="articles-group-designation"
                        placeholder={t("Enter the designation")}
                        type="text"
                        className="mt-5 ml-4"
                        style={{ width: "90%" }}
                        defaultValue={newGroupDesignationInModal}
                        onChange={handleNewGroupDesignationInputOnChangeEvent}
                    />
                </AvForm>
            </ModalBody>
            <ModalFooter className="justify-content-center mt-3">
                <StyledButton
                    rounded={true}
                    outline={true}
                    variant={mainColor === null ? "primary" : ""}
                    className="w-25 white-space-nowrap"
                    style={{
                        color: mainColor,
                        borderColor: mainColor,
                    }}
                    onClick={toggleOpeningModal}
                >
                    {t("Return")}
                </StyledButton>
                <StyledButton
                    rounded={true}
                    variant={mainColor === null ? "primary" : ""}
                    className="w-25 white-space-nowrap"
                    style={{
                        background: mainColor,
                    }}
                    onClick={
                        handleValidateNewGroupDesignationButtonOnClickEvent
                    }
                    disabled={
                        isGroupNameContainsSpecialStrings ||
                        isGroupNameEmpty ||
                        isGroupNameContainsSpecialStrings ||
                        isNameExistsInGroupsList
                    }
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
