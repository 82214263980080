import i18n from "i18next";

import { CHANGE_PERIOD, CLEAR_PERIOD } from "./actions-types";

export const changePeriod = (
    date: string
): {
    type: string;
    payload: any;
} => {
    return {
        type: CHANGE_PERIOD,
        payload: date,
    };
};

export const clearPeriod = (): {
    type: string;
    payload: any;
} => {
    return {
        type: CLEAR_PERIOD,
        payload: i18n.t("Current week"),
    };
};
