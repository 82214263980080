import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

import MiniCard from "@components/Common/MiniCard";

import { formatCurrency } from "@helpers/general";

import {
    AdditionalSaleSvgIcon,
    OffersAndAdditionalSaleSvgIcon,
    OffersSvgIcon,
} from "@components/Common/SvgIcons";

export default function Cards() {
    const { t } = useTranslation();
    const allAddOffersAndSalesValue: number = 2551.95;
    const offersValue: number = 2300.72;
    const additionalSalesValue: number = 2150.51;

    return (
        <Row className="cards__clz">
            <Col>
                <MiniCard
                    icon={<OffersAndAdditionalSaleSvgIcon />}
                    title={t("Add. Offers and sale")}
                    amount={formatCurrency(allAddOffersAndSalesValue)}
                />
            </Col>
            <Col>
                <MiniCard
                    icon={<OffersSvgIcon />}
                    title={t("Offres")}
                    amount={formatCurrency(offersValue)}
                />
            </Col>
            <Col>
                <MiniCard
                    icon={<AdditionalSaleSvgIcon />}
                    title={t("Additional sales")}
                    amount={formatCurrency(additionalSalesValue)}
                />
            </Col>
        </Row>
    );
}
