import React from "react";

export default function MobileSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            width="1em"
            height="1em"
            style={{
                msTransform: "rotate(360deg)",
                WebkitTransform: "rotate(360deg)",
            }}
            viewBox="0 0 48 48"
            transform="rotate(360)"
            {...props}
        >
            <path
                fill="#37474F"
                d="M12 40V8c0-2.2 1.8-4 4-4h16c2.2 0 4 1.8 4 4v32c0 2.2-1.8 4-4 4H16c-2.2 0-4-1.8-4-4z"
            />
            <path
                fill="#BBDEFB"
                d="M32 7H16c-.6 0-1 .4-1 1v29c0 .6.4 1 1 1h16c.6 0 1-.4 1-1V8c0-.6-.4-1-1-1z"
            />
            <path fill="#78909C" d="M21 40h6v2h-6z" />
        </svg>
    );
}
