import React from "react";

type SettingsFamiliesContextType = {
    groups: any[];
    setGroups: Function;
};

export const SettingsFamiliesContext = React.createContext<
    SettingsFamiliesContextType
>({
    groups: [],
    setGroups: () => {},
});
