import {
    ADD_FAVORITES,
    CLEAN_FAVORITES,
    REMOVE_FAVORITES,
} from "./actions-types";

type ReturnedActionsDataType = {
    type: string;
    payload: any;
};

export function addFavorite(favorite: any): ReturnedActionsDataType {
    return {
        type: ADD_FAVORITES,
        payload: favorite,
    };
}

export function cleanFavorites(): ReturnedActionsDataType {
    return {
        type: CLEAN_FAVORITES,
        payload: [],
    };
}

export function removeFavorite(
    franchise_id: string | number,
    shop_id: string | number
): ReturnedActionsDataType {
    return {
        type: REMOVE_FAVORITES,
        payload: {
            franchise_id,
            shop_id,
        },
    };
}
