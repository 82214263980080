import React from "react";
import { Pagination as ReactBootstrapPagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type PaginationPropsType = {
    total: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: Function;
};

export default function Pagination({
    total,
    itemsPerPage,
    currentPage,
    onPageChange,
}: PaginationPropsType) {
    const { t } = useTranslation();
    const [totalPages, setTotalPages] = React.useState(0);

    React.useEffect(() => {
        if (total > 0 && itemsPerPage > 0) {
            setTotalPages(Math.ceil(total / itemsPerPage));
        }
    }, [total, itemsPerPage]);

    if (totalPages === 0) return null;

    return (
        <ReactBootstrapPagination className="justify-content-end mt-3">
            <button
                className="btn p-0"
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
            >
                {"<<"}
            </button>
            <button
                className="btn"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                {"<"}
            </button>
            <span className="d-flex align-items-center">
                {t("Page of", {
                    page_number: currentPage,
                    total: totalPages,
                })}{" "}
            </span>
            <button
                className="btn"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                {">"}
            </button>
            <button
                className="btn p-0"
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
            >
                {">>"}
            </button>{" "}
        </ReactBootstrapPagination>
    );
}
