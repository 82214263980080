import React from "react";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { Container } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { loginUser } from "@store/actions";

import NProgressWrapper from "@components/Common/NProgressWrapper";

export function LoginWithSSO() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState<boolean>(true);

    const checkCustomerAccount = React.useCallback(
        async function () {
            setLoading(true);

            try {
                dispatch(
                    loginUser({
                        email: keycloak.tokenParsed?.email.toLowerCase(),
                    } as any)
                );
            } catch (_) {
                setLoading(false);
                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                    buttons: false,
                    timer: 2000,
                });
            }
        },
        [keycloak, dispatch, t]
    );

    React.useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const { entityUid } = JSON.parse(
                localStorage.getItem("authUser") as string
            );

            if (
                entityUid !== null &&
                entityUid === keycloak.tokenParsed?.uuid
            ) {
                history.push("/dashboard");
            }
        } else if (keycloak.authenticated === true) {
            checkCustomerAccount();
        }
    }, [
        keycloak.authenticated,
        keycloak.tokenParsed,
        checkCustomerAccount,
        history,
    ]);

    return (
        <Container fluid={true} className="p-0 m-0 overflow-hidden">
            <NProgressWrapper isLoading={loading} />
        </Container>
    );
}
