import {
    CLEAN_CARDS_MODES,
    INIT_CARDS_MODES,
    SELECT_CARDS_MODES,
} from "./actions-types";

export const selectCardsModes = (
    cardsModes: CardsModesType
): {
    type: string;
    payload: CardsModesType;
} => {
    return {
        type: SELECT_CARDS_MODES,
        payload: cardsModes,
    };
};

export const initCardsModes = (
    cardsModes: CardsModesType
): {
    type: string;
    payload: CardsModesType;
} => {
    return {
        type: INIT_CARDS_MODES,
        payload: cardsModes,
    };
};

export function cleanCardsModes(): {
    type: string;
    payload: [];
} {
    return {
        type: CLEAN_CARDS_MODES,
        payload: [],
    };
}
