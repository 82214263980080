import React from "react";

export default function ApplicationSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8781">
                <g data-name="Groupe 8759">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g fill="#fff" data-name="Groupe 8774">
                    <path
                        data-name="Trac\xE9 8574"
                        d="M14 9.846v26.688a2.619 2.619 0 002.3 2.846h15.32a2.619 2.619 0 002.3-2.846V9.846A2.619 2.619 0 0031.62 7H16.3A2.619 2.619 0 0014 9.846zm18.386 0v26.688a.873.873 0 01-.766.949H16.3a.873.873 0 01-.766-.949V9.846A.873.873 0 0116.3 8.9h15.32a.873.873 0 01.766.946z"
                    />
                    <path
                        data-name="Trac\xE9 8575"
                        d="M21.661 34.783h4.6v1.532h-4.6z"
                    />
                </g>
            </g>
        </svg>
    );
}
