import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";

import { logoutUser } from "@store/actions";

export default function ProfileMenu() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { user } = useSelector((state: any) => state.Login);

    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");

    React.useEffect(() => {
        if (user !== undefined) {
            setName(
                `${
                    user.firstname
                        ? `${user.firstname} ${user.lastname}`
                        : user.username
                }`
            );
        } else if (localStorage.getItem("authUser") !== null) {
            if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
                const authUser = JSON.parse(
                    localStorage.getItem("authUser") as string
                );
                setName(
                    `${
                        authUser.firstname !== undefined
                            ? `${authUser.firstname} ${authUser.lastname}`
                            : authUser.username
                    }`
                );
            }
        }
    }, [user]);

    function logout(): void {
        dispatch(logoutUser(null));
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    style={{ backgroundColor: "transparent" }}
                >
                    <Avatar name={name} size="40" round />
                    <span
                        style={{ color: "white" }}
                        className=" d-xl-inline-block ml-2 mr-1"
                    >
                        {name}
                    </span>
                    <i
                        style={{ color: "white" }}
                        className="mdi mdi-chevron-down d-xl-inline-block"
                    ></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => logout()}
                        className="dropdown-item"
                    >
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{t("Logout")}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}
