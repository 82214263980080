import { AnyAction } from "redux";

import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
} from "./actionTypes";

export const loginUser = (user: UserType): AnyAction => {
    return {
        type: LOGIN_USER,
        payload: { user },
    };
};

export const loginSuccess = (user: UserType): AnyAction => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    };
};

export const logoutUser = (history: any): AnyAction => {
    return {
        type: LOGOUT_USER,
        payload: { history },
    };
};

export const logoutUserSuccess = (): AnyAction => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
    };
};

export const apiError = (error: any): AnyAction => {
    return {
        type: API_ERROR,
        payload: error,
    };
};
