import React from "react";

export default function CheckoutSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8777">
                <g data-name="Groupe 8755">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(8 7)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8544"
                        d="M30.8 20.985l-1.017-8.1a.3.3 0 010-.073V7.3a2.77 2.77 0 00-.562-1.686 2.809 2.809 0 00.562-1.686V2.809A2.809 2.809 0 0026.969 0h-8.99a2.809 2.809 0 00-2.809 2.809v1.124a3.073 3.073 0 00.056.562H5.619A2.809 2.809 0 002.809 7.3v5.517a.3.3 0 010 .073l-1.017 8.095A2.809 2.809 0 000 23.6v5.619a2.809 2.809 0 002.809 2.809h26.969a2.809 2.809 0 002.809-2.809V23.6a2.809 2.809 0 00-1.787-2.615zM17.418 2.809a.562.562 0 01.562-.562h8.99a.562.562 0 01.562.562v1.124a.562.562 0 01-.562.562h-8.99a.562.562 0 01-.562-.562zM5.057 13.164a2.416 2.416 0 000-.348V7.3a.562.562 0 01.562-.562h21.35a.562.562 0 01.562.562v5.517a2.416 2.416 0 000 .348l.972 7.619H4.085zM30.34 29.217a.562.562 0 01-.562.562H2.809a.562.562 0 01-.562-.562V23.6a.562.562 0 01.562-.562h26.969a.562.562 0 01.562.562z"
                    />
                    <path
                        data-name="Trac\xE9 8545"
                        d="M17.417 25.284H15.17a1.124 1.124 0 000 2.247h2.247a1.124 1.124 0 100-2.247z"
                    />
                    <path
                        data-name="Trac\xE9 8546"
                        d="M16.294 8.99H8.428a1.124 1.124 0 100 2.247h7.866a1.124 1.124 0 000-2.247z"
                    />
                    <ellipse
                        data-name="Ellipse 625"
                        cx={1.5}
                        cy={1}
                        rx={1.5}
                        ry={1}
                        transform="translate(15.088 13)"
                    />
                    <circle
                        data-name="Ellipse 626"
                        cx={1}
                        cy={1}
                        r={1}
                        transform="translate(19.088 13)"
                    />
                    <ellipse
                        data-name="Ellipse 627"
                        cx={1.5}
                        cy={1}
                        rx={1.5}
                        ry={1}
                        transform="translate(22.088 13)"
                    />
                    <ellipse
                        data-name="Ellipse 628"
                        cx={1.5}
                        cy={1}
                        rx={1.5}
                        ry={1}
                        transform="translate(15.088 17)"
                    />
                    <circle
                        data-name="Ellipse 629"
                        cx={1}
                        cy={1}
                        r={1}
                        transform="translate(19.088 17)"
                    />
                    <ellipse
                        data-name="Ellipse 630"
                        cx={1.5}
                        cy={1}
                        rx={1.5}
                        ry={1}
                        transform="translate(22.088 17)"
                    />
                </g>
            </g>
        </svg>
    );
}
