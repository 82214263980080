import { proxy } from "valtio";
import i18n from "i18next";

import { keycloak } from "@keycloak";

type StoreType = {
    shops: Array<MyShopsType | string>;
    groups: string[];
    selectedShops: Array<MyShopsType>;
    selectedGroups: Array<string>;
};
type ShopsApiDataType = { id: string; shops: { id: string; name: string }[] };

export const store = proxy<StoreType>({
    shops: [],
    groups: [],
    selectedShops: [],
    selectedGroups: [],
});

export async function fetchShops() {
    try {
        const token = localStorage.getItem("jwt");
        const response = await fetch(
            `${process.env.REACT_APP_API_V1_URL}/selections/franchises-shops-list`,
            {
                headers: {
                    authorization: `Bearer ${keycloak.token}`,
                    "x-authorization": `Bearer ${token}`,
                },
            }
        );

        if (response.status === 401) {
            return keycloak.logout();
        }

        const { data } = await response.json();

        let rawShops: MyShopsType[] = [];

        data.forEach((datum: ShopsApiDataType) => {
            const franchisesShops = datum.shops.map((shop) => ({
                franchiseId: datum.id,
                shopId: shop.id,
                shopName: shop.name,
            }));
            rawShops = [...rawShops, ...franchisesShops];
        });

        setShops([i18n.t("All shops"), ...rawShops]);
    } catch (error) {
        console.log(
            `There's an error while fetching shops, with message: ${
                (error as Error).message
            }`
        );
    }
}

export async function fetchGroups() {
    try {
        const token = localStorage.getItem("jwt");
        const response = await fetch(
            `${process.env.REACT_APP_API_V1_URL}/selections/selections-list`,
            {
                headers: {
                    authorization: `Bearer ${keycloak.token}`,
                    "x-authorization": `Bearer ${token}`,
                },
            }
        );

        if (response.status === 401) {
            return keycloak.logout();
        }

        const { data } = await response.json();

        const rawGroups: string[] = data.map(
            (datum: { designation: string }) => datum.designation
        );

        setGroups([i18n.t("All groups"), ...rawGroups]);
    } catch (error) {
        console.log(
            `There's an error while fetching groups, with message: ${
                (error as Error).message
            }`
        );
    }
}

export function setShops(shops: Array<MyShopsType | string>) {
    store.shops = shops;
}

export function setSelectedShops(shops: Array<MyShopsType>) {
    store.selectedShops = shops;
}

export function setSelectedGroups(groups: Array<string>) {
    store.selectedGroups = groups;
}

export function setGroups(groups: string[]) {
    store.groups = groups;
}
