import React from "react";
import { useTranslation } from "react-i18next";

export default function ArrowLeftSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    const { t } = useTranslation();
    return (
        <svg
            width={13}
            height={20}
            viewBox="0 0 13 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>{t("Go back to the previous step")}</title>
            <g fill="none" fillRule="evenodd">
                <path opacity={0.87} d="M-5-2h24v24H-5z" />
                <path
                    fill="#1D1D1D"
                    d="M12.51 1.87L10.73.1.84 10l9.9 9.9 1.77-1.77L4.38 10z"
                />
            </g>
        </svg>
    );
}
