import React from "react";

export default function ShopSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8776">
                <g data-name="Groupe 8754">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="Groupe 8310">
                    <path
                        data-name="Trac\xE9 8566"
                        d="M39.97 18.758l-2-8A1 1 0 0037 10H11a1 1 0 00-.97.758l-2 8A.978.978 0 008 19a5.072 5.072 0 002 4.062V37a1 1 0 001 1h10a1 1 0 001-1v-7h4v7a1 1 0 001 1h10a1 1 0 001-1V23.062A5.072 5.072 0 0040 19a.978.978 0 00-.03-.242zM36 36h-8v-7a1 1 0 00-1-1h-6a1 1 0 00-1 1v7h-8V23.92a4.436 4.436 0 00.75.08 4.664 4.664 0 003.75-1.934 4.6 4.6 0 007.5 0 4.6 4.6 0 007.5 0A4.664 4.664 0 0035.25 24a4.436 4.436 0 00.75-.08zm-.75-14a2.887 2.887 0 01-2.75-3 1 1 0 00-2 0 2.887 2.887 0 01-2.75 3A2.887 2.887 0 0125 19a1 1 0 00-2 0 2.887 2.887 0 01-2.75 3 2.887 2.887 0 01-2.75-3 1 1 0 00-2 0 2.887 2.887 0 01-2.75 3A2.876 2.876 0 0110 19.116L11.782 12H36.22L38 19.116A2.879 2.879 0 0135.25 22z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
}
