import React from "react";
import { TabContent, TabPane } from "reactstrap";

import { useSnapshot } from "valtio";

import { store as headerStore } from "@pages/Statistics/Transactions/Header/store";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import NewSegment from "@pages/Statistics/Transactions/NewSegment";
import SavedSegments from "@pages/Statistics/Transactions/SavedSegments";

export function TabContentWrapper() {
    const { activeTab } = useSnapshot(headerStore);

    return (
        <TabContent activeTab={activeTab} className="w-100">
            <TabPane tabId={1}>
                <NewSegment />
            </TabPane>
            <TabPane tabId={2}>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SavedSegments />
                    </CustomErrorBoundary>
                </React.Suspense>
            </TabPane>
        </TabContent>
    );
}
