import React from "react";

export default function BankCardSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8770">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8483" fill="#fff">
                    <path
                        data-name="Trac\xE9 8624"
                        d="M35.2 13H12.8A3.8 3.8 0 009 16.8v14.4a3.8 3.8 0 003.8 3.8h22.4a3.8 3.8 0 003.8-3.8V16.8a3.8 3.8 0 00-3.8-3.8zM37 31.2a1.805 1.805 0 01-1.8 1.8H12.8a1.805 1.805 0 01-1.8-1.8V16.8a1.805 1.805 0 011.8-1.8h22.4a1.805 1.805 0 011.8 1.8z"
                    />
                    <path
                        data-name="Trac\xE9 8625"
                        d="M18 29h-4a1 1 0 000 2h4a1 1 0 000-2z"
                    />
                    <path
                        data-name="Trac\xE9 8626"
                        d="M34 18H14a1 1 0 000 2h20a1 1 0 000-2z"
                    />
                </g>
            </g>
        </svg>
    );
}
