import React from "react";

export default function OffersSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8257">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="discount (1)">
                    <g data-name="Groupe 8497">
                        <g data-name="Groupe 8496">
                            <path
                                data-name="Trac\xE9 8637"
                                d="M37.891 24.227a.927.927 0 010-.819l1.18-2.414a2.746 2.746 0 00-1.185-3.647l-2.373-1.259a.927.927 0 01-.482-.663l-.464-2.646a2.746 2.746 0 00-3.1-2.254l-2.66.376a.927.927 0 01-.779-.253L26.095 8.78a2.746 2.746 0 00-3.835 0l-1.931 1.868a.927.927 0 01-.779.253l-2.66-.376a2.746 2.746 0 00-3.1 2.254l-.464 2.646a.927.927 0 01-.482.663l-2.375 1.26a2.746 2.746 0 00-1.185 3.647l1.18 2.414a.927.927 0 010 .819l-1.18 2.414a2.746 2.746 0 001.185 3.647l2.372 1.258a.927.927 0 01.482.663l.464 2.646a2.745 2.745 0 002.707 2.282 2.821 2.821 0 00.4-.028l2.66-.376a.927.927 0 01.779.253l1.931 1.868a2.746 2.746 0 003.834 0l1.931-1.868a.928.928 0 01.779-.253l2.66.376a2.746 2.746 0 003.1-2.254l.464-2.646a.927.927 0 01.482-.663l2.373-1.259a2.746 2.746 0 001.185-3.647zm-.86 4.449l-2.373 1.259a2.74 2.74 0 00-1.424 1.959l-.464 2.646a.929.929 0 01-1.05.763l-2.66-.376a2.739 2.739 0 00-2.3.748l-1.931 1.868a.929.929 0 01-1.3 0l-1.931-1.868a2.737 2.737 0 00-1.911-.776 2.8 2.8 0 00-.393.028l-2.66.376a.929.929 0 01-1.05-.763l-.464-2.646a2.74 2.74 0 00-1.424-1.959l-2.373-1.259a.929.929 0 01-.4-1.234l1.18-2.414a2.74 2.74 0 000-2.422l-1.18-2.414a.929.929 0 01.4-1.234l2.374-1.256a2.74 2.74 0 001.424-1.959l.464-2.646a.929.929 0 011.05-.763l2.66.376a2.74 2.74 0 002.3-.748l1.934-1.87a.929.929 0 011.3 0l1.931 1.868a2.74 2.74 0 002.3.748l2.66-.376a.929.929 0 011.05.763l.464 2.646a2.74 2.74 0 001.424 1.961l2.373 1.259a.929.929 0 01.4 1.234l-1.179 2.407a2.74 2.74 0 000 2.422l1.18 2.414a.929.929 0 01-.401 1.238z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 8499">
                        <g data-name="Groupe 8498">
                            <path
                                data-name="Trac\xE9 8638"
                                d="M30.842 17.152a.912.912 0 00-1.29 0L17.511 29.193a.912.912 0 101.29 1.29l12.042-12.042a.912.912 0 00-.001-1.289z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 8501">
                        <g data-name="Groupe 8500">
                            <path
                                data-name="Trac\xE9 8639"
                                d="M20.528 15.607a3.345 3.345 0 103.345 3.345 3.349 3.349 0 00-3.345-3.345zm0 4.866a1.52 1.52 0 111.521-1.521 1.522 1.522 0 01-1.521 1.521z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 8503">
                        <g data-name="Groupe 8502">
                            <path
                                data-name="Trac\xE9 8640"
                                d="M27.826 25.338a3.345 3.345 0 103.345 3.345 3.349 3.349 0 00-3.345-3.345zm0 4.866a1.52 1.52 0 111.52-1.52 1.522 1.522 0 01-1.52 1.52z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
