import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import { capitalize } from "@helpers/general";

type TableRowPropsType = {
    key: string;
    franchise: FranchisesDataType;
};

export function TableRow({ franchise }: TableRowPropsType) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const expanderBody = React.useRef(null);

    return (
        <React.Fragment>
            <tr key={`franchises_row__${franchise.id}`}>
                <th></th>
                <td>{franchise.name}</td>
                <td>{franchise.shop}</td>
                <td>{t(capitalize(franchise.kind))}</td>
                <td>{franchise.address}</td>
                <td>{franchise.zip}</td>
                <td>{franchise.city}</td>
                <td>{franchise.regions}</td>
                <td>{franchise.country}</td>
                <td className="d-flex justify-content-center">
                    {franchise.shops !== undefined && (
                        <Button
                            type="button"
                            color={mainColor === null ? "secondary" : ""}
                            outline={true}
                            className="w-md"
                            onClick={() => setExpanded(!expanded)}
                            id={`franchise${franchise.id}_shops_details`}
                            style={{ borderColor: mainColor, color: mainColor }}
                        >
                            {t(expanded ? "Hide details" : "Details")}
                        </Button>
                    )}
                </td>
            </tr>
            {expanded === true &&
                franchise.shops !== undefined &&
                franchise.shops.map(function (shop: FranchisesDataType) {
                    return (
                        <tr ref={expanderBody} key={`shops_row__${shop.id}`}>
                            <td></td>
                            <th></th>
                            <td>{shop.name}</td>
                            <td>{t(capitalize(shop.kind))}</td>
                            <td>{shop.address}</td>
                            <td>{shop.zip}</td>
                            <td>{shop.city}</td>
                            <td>{shop.regions}</td>
                            <td>{shop.country}</td>
                            <td className="d-flex justify-content-center"></td>
                        </tr>
                    );
                })}
        </React.Fragment>
    );
}
