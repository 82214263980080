import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";
import { useSnapshot } from "valtio";

import MySelectionsContext from "@pages/MySelections/context";

import store, { setSearchedElementInputValue } from "@pages/MySelections/store";

export default function SearchFranchisesFormGroupWrapper() {
    const { t } = useTranslation();

    const { setSearchedFranchises } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    function handleSearchInputOnChangeEvent(e: any) {
        setSearchedElementInputValue(e!.target!.value);

        let allFranchises: FranchisesDataType[] = JSON.parse(
            JSON.stringify(MySelectionsStore.allFranchises)
        );

        let filteredFranchises: any = allFranchises.filter(
            (franchise: FranchisesDataType) => {
                if (
                    franchise.name
                        ?.toUpperCase()
                        .includes(e!.target!.value.toUpperCase())
                ) {
                    return franchise.name
                        ?.toUpperCase()
                        .includes(e!.target!.value.toUpperCase());
                } else {
                    franchise.shops = franchise.shops!.filter((shop: any) => {
                        return shop.name
                            .toUpperCase()
                            .includes(e!.target!.value.toUpperCase());
                    });

                    franchise.shop = franchise.shops?.length;

                    return (
                        franchise.name
                            ?.toUpperCase()
                            .includes(e!.target!.value.toUpperCase()) ||
                        franchise.shops!.filter((shop: any) => {
                            return shop.name
                                .toUpperCase()
                                .includes(e!.target!.value.toUpperCase());
                        }).length > 0
                    );
                }
            }
        );

        setSearchedFranchises!(filteredFranchises);
    }

    return (
        <FormGroup className="mt-4 w-100 w-xs-100">
            <div className="app-search pt-0">
                <div className="position-relative">
                    <input
                        value={MySelectionsStore.searchedElementInputValue}
                        type="text"
                        className="form-control franchises-font-size-xl__clz"
                        onChange={handleSearchInputOnChangeEvent}
                        placeholder={`${t("Search")} ...`}
                    />
                    <span className="bx bx-search-alt"></span>
                </div>
            </div>
        </FormGroup>
    );
}
