import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "@vStore";

type PropsType = {
    toggleOpeningModal: () => void;
};

export default function SaveMySelectionButtonWrapper({
    toggleOpeningModal,
}: PropsType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    return (
        <Button
            type="button"
            color={mainColor === null ? "dark" : ""}
            className={classNames(
                "px-0 w-xs-100 w-sm-100 mt-2 mt-md-0 font-size-sm franchises-font-size-xl__clz",
                {
                    "text-white": mainColor !== null,
                }
            )}
            onClick={toggleOpeningModal}
            style={{ background: mainColor }}
        >
            {t("Save my selection")}
        </Button>
    );
}
