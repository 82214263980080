import React from "react";

import ProfileMenu from "./ProfileMenu";

export default function Header() {
    return (
        <header
            id="page-topbar"
            style={{
                backgroundColor: "rgba(255, 255, 255, 0)",
            }}
        >
            <div className="navbar-header d-flex justify-content-end">
                <ProfileMenu />
            </div>
        </header>
    );
}
