import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "i18next";

import PageTitle from "@components/Common/PageTitle";
import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import Cards from "./Cards";
import SalesModeRow from "./SalesModeRow";
import SalesSupportRow from "./SalesSupportRow";
import ServicesRow from "./ServicesRow";
import Top10SubFamilies from "./Top10SubFamilies";

export default function SubFamilies() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Sub-families")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Sub-families")}
                />

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <Cards />
                    </CustomErrorBoundary>
                </React.Suspense>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10SubFamilies />
                </React.Suspense>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesModeRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesSupportRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <ServicesRow />
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
