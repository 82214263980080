import React from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next/";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cache } from "swr";
import { useKeycloak } from "@react-keycloak/web";

import { socket } from "@socket";

import { logoutUser } from "@store/actions";

import { decryptWord } from "@helpers/general";

import {
    KeySvgIcon,
    LogOutSvgIcon,
    ArrowDownIcon,
} from "@components/Common/SvgIcons";
import { AppearanceDropdownItemWrapper } from "./AppearanceDropdownItemWrapper";

import "./layout.css";

export function ProfileMenu() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const history = useHistory();

    const dispatch = useDispatch();

    const { user } = useSelector(
        (state: {
            Login: {
                user: UserType;
            };
        }) => state.Login
    );

    const [name, setName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [menu, setMenu] = React.useState<boolean>(false);

    function changePassword() {
        cache.clear();
        localStorage.clear();
        history.push("/change-password");
    }

    function logout(): void {
        socket.disconnect();

        dispatch(logoutUser(null));
        keycloak.logout();
    }

    function toggleDropdown() {
        setMenu((prevState) => !prevState);
    }

    React.useEffect(() => {
        if (user !== undefined) {
            const authUser = JSON.parse(localStorage.getItem("authUser")!);
            setName(
                `${
                    authUser.firstname
                        ? `${authUser.firstname} ${authUser.lastname}`
                        : authUser.username
                }`
            );

            setEmail(authUser.email);
        } else {
            setName(
                `${
                    (user as UserType).firstname
                        ? `${(user as UserType).firstname} ${
                              (user as UserType).lastname
                          }`
                        : (user as UserType).username
                }`
            );
            decryptWord((user as UserType).email as string, setEmail);
        }
    }, [user]);

    return (
        <Dropdown
            isOpen={menu}
            toggle={toggleDropdown}
            className="d-inline-block"
        >
            <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
            >
                <Avatar className="mr-0" name={name} round={true} size="40" />
                <ArrowDownIcon fill="black" className="pt-1" />
            </DropdownToggle>
            <DropdownMenu right={true}>
                <DropdownItem style={{ pointerEvents: "none" }}>
                    <div
                        style={{
                            width: "300px",
                            overflow: "hidden",
                        }}
                        className="d-flex justify-content-start"
                    >
                        <Avatar
                            className="mt-2 mb-2"
                            name={name}
                            round={true}
                            size="70"
                            maxInitials={2}
                        />
                        <div className="pt-4 pl-3">
                            <div className="line1__clz">{name}</div>
                            <div className="line2__clz">{email}</div>
                        </div>
                    </div>
                </DropdownItem>

                <hr style={{ color: "#000000" }} />
                <DropdownItem
                    tag="a"
                    className="dropdown-item pl-4"
                    onClick={changePassword}
                >
                    <KeySvgIcon className="mr-3" height="20" width="20" />
                    {t("Change password")}
                </DropdownItem>
                <AppearanceDropdownItemWrapper />
                <DropdownItem
                    style={{ cursor: "pointer" }}
                    onClick={logout}
                    className="dropdown-item pl-4"
                >
                    <LogOutSvgIcon className="mr-2" height="25" width="25" />
                    {t("Logout")}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
