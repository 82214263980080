import { SET_REGULATION_TYPE } from "./actions-types";

export const setRegulationType = (
    regulationType: string
): {
    type: string;
    payload: string;
} => {
    return {
        type: SET_REGULATION_TYPE,
        payload: regulationType,
    };
};
