import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import moment from "moment";
import i18n from "i18next";

import { changeType } from "@store/dashboard/actions";

import GlobalBreadcrumb from "@components/Common/GlobalBreadcrumb";
import PageTitle from "@components/Common/PageTitle";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import StatisticsChart from "../StatisticsChart";
import SalesModeRow from "./SalesModeRow";
import SaleSupportRow from "./SaleSupportRow";
import Top10ArticlesRow from "./Top10ArticlesRow";
import ServiceRow from "./ServiceRow";
import Top10FamiliesRow from "./Top10FamiliesRow";
import Top10EmployeesRow from "./Top10EmployeesRow";
import RegulationRow from "./RegulationRow";

import "@assets/grid.css";

export default function StatisticsAverageBasket() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    moment.locale(i18n.language);

    React.useEffect(() => {
        dispatch(changeType(t("Average basket")));
    }, [t, dispatch]);

    return (
        <React.Fragment>
            <PageTitle title={t("Average basket")} />
            <div className="page-content">
                <GlobalBreadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Average basket")}
                />
                <CustomErrorBoundary>
                    <StatisticsChart
                        title={t("Average basket")}
                        iconClass="bx-archive-in"
                    />
                </CustomErrorBoundary>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesModeRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SaleSupportRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <RegulationRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <ServiceRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10FamiliesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10ArticlesRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Top10EmployeesRow />
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
