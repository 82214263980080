import React from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useTable, useGlobalFilter } from "react-table";
import BTable from "react-bootstrap/Table";
import { mutate } from "swr";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { useSnapshot } from "valtio";
import {
    AngleSmallLeftIcon,
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useKeycloak } from "@react-keycloak/web";

import { store as vStore } from "@vStore";
import { store, setGroupedRegulations } from "./store";

import { ITEMS_PER_PAGE } from "@constants/index";

import { NoImagePlaceholderIcon } from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

export default function FourthStep({ go }: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { mainColor } = useSnapshot(vStore);
    const { groupedRegulations, groupName } = useSnapshot(store);

    const [selectedArticles, setSelectedArticles] = React.useState<any[]>([]);
    const [filteredArticles, setFilteredArticles] = React.useState<any[]>(
        groupedRegulations
    );

    const handleDeleteArticleButtonOnClickEvent = React.useCallback(
        (article: any) => {
            let localSelectedArticles: any[] = [...selectedArticles, article];
            setSelectedArticles(localSelectedArticles);
            let localArticles: any[] = filteredArticles.filter(
                (article: any) => {
                    return !localSelectedArticles
                        .map((selectedArticle: any) =>
                            `${selectedArticle.reference}|${selectedArticle.designation}|${selectedArticle.family}|${selectedArticle.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                }
            );

            setFilteredArticles(localArticles);

            setGroupedRegulations(localArticles);
        },
        [selectedArticles, filteredArticles]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Product"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (article: any) {
                    return (
                        <div>
                            {article.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                article.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Families"),
                accessor: "family",
                disableGlobalFilter: true,
            },
            {
                Header: t("Sub-families"),
                accessor: "subFamily",
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (article: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleDeleteArticleButtonOnClickEvent(
                                        article
                                    )
                                }
                            >
                                {t("Delete")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none m-0"
                                icon="TrashIcon"
                                onClick={() =>
                                    handleDeleteArticleButtonOnClickEvent(
                                        article
                                    )
                                }
                            >
                                <TrashIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleDeleteArticleButtonOnClickEvent, mainColor]
    );

    React.useEffect(() => {
        if (filteredArticles.length === 0) {
            go(1);
        }
    }, [filteredArticles, go]);

    async function sendSavingSelectionsRequest(selection: SelectionType) {
        let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group`;

        try {
            NProgress.start();

            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                        "x-authorization": `Bearer ${localStorage.getItem(
                            "jwt"
                        )}`,
                    },
                    body: JSON.stringify(selection),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        NProgress.done();
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        go(0);
                        setGroupedRegulations([]);
                        return swal({
                            icon: "success",
                            content: <p>{t(data.message)}!</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    })
            );
        } catch (e: any) {
            NProgress.done();
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
            });
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: groupedRegulations,

            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    function handlePreviousStepAction() {
        go(1);
    }

    function getSelectedObj(currentSelections: any[] | undefined) {
        let obj: any = {
            designation: groupName,
            groupment: [],
        };

        currentSelections?.forEach(function (selection: any) {
            obj.groupment.push(
                `${selection.reference}|${selection.designation}${
                    selection.family.length > 0 ? `|${selection.family}` : ""
                }${
                    selection.subFamily.length > 0
                        ? `|${selection.subFamily}`
                        : ""
                }`
            );
        });

        return obj;
    }

    function handleSaveButtonClickEvent() {
        sendSavingSelectionsRequest(getSelectedObj(filteredArticles));
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <div className="d-md-flex justify-content-md-between">
                <div className="d-md-flex align-items-md-center my-2">
                    <div className="d-flex align-items-center">
                        <StyledIconButton
                            icon="AngleSmallLeftIcon"
                            className="m-0 p-0"
                            onClick={handlePreviousStepAction}
                        >
                            <AngleSmallLeftIcon height={35} width={35} />
                        </StyledIconButton>
                        <span className="mb-0 ml-2 mr-0 font-size-14 h5 white-space-nowrap">
                            {groupName}
                        </span>
                    </div>

                    <span
                        className="divider mt-0 mx-4 d-none d-md-block"
                        style={{
                            backgroundColor: "#808080a6",
                            height: "2.25rem",
                            width: "2px",
                        }}
                    />
                    <StyledSearchInput
                        onChange={(e) => {
                            setGlobalFilter(e.target.value);
                        }}
                        placeholder={`${t("Search")} ...`}
                        className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                    />
                </div>
                <StyledButton
                    rounded={true}
                    variant="success"
                    className="mr-0 ml-2 mt-0 my-md-2 w-xs-95 mx-md-2 w-sm-95 w-md-unset"
                    onClick={handleSaveButtonClickEvent}
                    disabled={pageCount === 0}
                >
                    {t("Save")}
                </StyledButton>
            </div>
            {page.length > 0 ? (
                <React.Fragment>
                    <BTable responsive {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="white-space-nowrap"
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="white-space-nowrap align-middle"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </BTable>
                    {filteredArticles.length > ITEMS_PER_PAGE ? (
                        <Pagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                        />
                    ) : null}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
}
