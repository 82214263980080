import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import NProgress from "nprogress";
import { StaticContext } from "react-router";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import "nprogress/nprogress.css";

export default function NonAuthMiddleware({
    component: Component,
    layout: Layout,
}: any) {
    NProgress.start();

    return (
        <Route
            render={(
                props: RouteComponentProps<any, StaticContext, unknown>
            ) => {
                NProgress.done();

                return (
                    <Layout>
                        <CustomErrorBoundary>
                            <Component {...props} />
                        </CustomErrorBoundary>
                    </Layout>
                );
            }}
        />
    );
}
