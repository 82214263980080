import React from "react";

export function ColorSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M7.08 11.25A4.84 4.84 0 018 9.05L4.43 5.49A9.88 9.88 0 002 11.25zM9.05 8a4.84 4.84 0 012.2-.91V2a9.88 9.88 0 00-5.76 2.43zm3.7-6v5A4.84 4.84 0 0115 8l3.56-3.56A9.88 9.88 0 0012.75 2zM8 15a4.84 4.84 0 01-.91-2.2H2a9.88 9.88 0 002.39 5.76zm3.25 1.92a4.84 4.84 0 01-2.2-.92l-3.56 3.57A9.88 9.88 0 0011.25 22zM16 9.05a4.84 4.84 0 01.91 2.2h5a9.88 9.88 0 00-2.39-5.76zM15 16a4.84 4.84 0 01-2.2.91v5a9.88 9.88 0 005.76-2.39zm1.92-3.25A4.84 4.84 0 0116 15l3.56 3.56A9.88 9.88 0 0022 12.75z" />
        </svg>
    );
}
