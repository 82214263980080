import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { history } from "./store";

import { userRoutes, authRoutes } from "@routes/allRoutes";

import AuthMiddleware from "@routes/middleware/AuthMiddleware";
import NonAuthMiddleware from "@routes/middleware/NonAuthMiddleware";

import VerticalLayout from "@components/VerticalLayout/";
import NonAuthLayout from "@components/NonAuthLayout";

import { KeycloakProvider } from "./KeycloakProvider";

import "@assets/scss/theme.scss";

export function App() {
    return (
        <KeycloakProvider>
            <BrowserRouter>
                <ConnectedRouter history={history}>
                    <Switch>
                        {authRoutes.map((route, idx) => (
                            <NonAuthMiddleware
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                            />
                        ))}
                        {userRoutes.map((route, idx) => (
                            <AuthMiddleware
                                path={route.path}
                                layout={VerticalLayout}
                                component={route.component}
                                key={idx}
                            />
                        ))}
                    </Switch>
                </ConnectedRouter>
            </BrowserRouter>
        </KeycloakProvider>
    );
}
