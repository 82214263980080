import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";

import PageTitle from "@components/Common/PageTitle";
import MySelectionsCard from "./MySelectionsCard";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

export default function MySelections() {
    const { t } = useTranslation();

    return (
        <CustomErrorBoundary>
            <PageTitle title={t("My selection")} />
            <div
                className="page-content"
                style={{ paddingBottom: "0px", paddingTop: "70px" }}
            >
                <Row>
                    <Col className="my-3">
                        <div className="float-left">
                            <span className="font-weight-bold text-uppercase">
                                {t("My shops")}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <React.Suspense fallback={<SpinnerWrapper />}>
                            <MySelectionsCard />
                        </React.Suspense>
                    </Col>
                </Row>
            </div>
        </CustomErrorBoundary>
    );
}
