import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import {
    StyledButton,
    StyledIconButton,
    AddIcon,
    AngleSmallLeftIcon,
    StyledSearchInput,
} from "@aureskonnect/react-ui";
import { useKeycloak } from "@react-keycloak/web";

import { store as vStore } from "@vStore";
import store from "./store";

import { useResource } from "@hooks/useResource";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import { NoImagePlaceholderIcon } from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

export default function AddSubFamiliesEditStep({ go }: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { mainColor } = useSnapshot(vStore);
    const { groupedSubFamilies, groupName } = useSnapshot(store);

    const ITEMS_PER_PAGE = 5;

    const [subFamilies, setSubFamilies] = React.useState<any[]>([]);
    const [filteredSubFamilies, setFilteredSubFamilies] = React.useState<any[]>(
        []
    );
    const [selectedSubFamilies, setSelectedSubFamilies] = React.useState<any[]>(
        []
    );

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/sub-families`
    );

    React.useEffect(() => {
        let localSubFamilies: any[] = resourceData.data!.filter(
            (family: any) => {
                return !groupedSubFamilies
                    .map(
                        (subFamilyGroup: any) =>
                            `${subFamilyGroup.sub_family}|${subFamilyGroup.family}`
                    )
                    .includes(`${family.sub_family}|${family.family}`);
            }
        );
        setSubFamilies(localSubFamilies);
        setFilteredSubFamilies(localSubFamilies);
    }, [resourceData, groupedSubFamilies]);

    const handleAddingSubFamilyButtonOnClickEvent = React.useCallback(
        (subFamily: any) => {
            swal({
                text: t("Are you sure you want to add this sub-family?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Add")],
                dangerMode: true,
            }).then(async (willDelete: any) => {
                if (willDelete) {
                    let localSelectedSubFamilies: any[] = [
                        ...selectedSubFamilies,
                        subFamily,
                    ];
                    setSelectedSubFamilies(localSelectedSubFamilies);
                    let localSubFamilies: any[] = (filteredSubFamilies.length >
                    0
                        ? filteredSubFamilies
                        : subFamilies
                    ).filter((subFamily) => {
                        return !localSelectedSubFamilies
                            .map((selectedSubFamily) =>
                                `${selectedSubFamily.sub_family}|${selectedSubFamily.family}`.toLowerCase()
                            )
                            .includes(
                                `${subFamily.sub_family}|${subFamily.family}`.toLowerCase()
                            );
                    });

                    setFilteredSubFamilies(localSubFamilies);

                    let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/groups`;
                    let savedData = {
                        designation: groupName,
                        groupment: [
                            ...groupedSubFamilies,
                            ...localSelectedSubFamilies,
                        ].map((s: any) => `${s.sub_family}|${s.family}`),
                    };
                    try {
                        NProgress.start();
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${keycloak.token}`,
                                    "x-authorization": `Bearer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "PUT",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    NProgress.done();
                                    if (data.error) {
                                        throw Error(
                                            "Error while adding a sub-family in an sub-families group!"
                                        );
                                    }
                                    mutate(
                                        `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/group-detail?group-name=${groupName}`
                                    );
                                    return swal({
                                        icon: "success",
                                        content: <p>{t(data.message)}!</p>,
                                        buttons: false,
                                        timer: 2000,
                                    });
                                })
                        );
                    } catch (e: any) {
                        NProgress.done();
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
            });
        },
        [
            keycloak.token,
            selectedSubFamilies,
            filteredSubFamilies,
            subFamilies,
            groupedSubFamilies,
            groupName,
            t,
        ]
    );
    const columns = React.useMemo(
        () => [
            {
                Header: t("Subfamily"),
                accessor: "sub_family",
            },
            {
                Header: t("Picture"),
                accessor: function (subFamily: any) {
                    return (
                        <div>
                            {subFamily.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                subFamily.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Family"),
                accessor: "family",
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (subFamily: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleAddingSubFamilyButtonOnClickEvent(
                                        subFamily
                                    )
                                }
                            >
                                {t("Add")}
                            </StyledButton>
                            <StyledIconButton
                                icon="PlusIcon"
                                className="d-block d-sm-block d-md-none m-0"
                                onClick={() =>
                                    handleAddingSubFamilyButtonOnClickEvent(
                                        subFamily
                                    )
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingSubFamilyButtonOnClickEvent, mainColor]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredSubFamilies,

            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    function handleMultipleAddingSubFamiliesButtonOnClickEvent() {
        swal({
            text: t(
                "Are you sure you want to add the selected element to the group?"
            ),
            icon: "warning",
            buttons: [t("Cancel"), t("Add")],
            dangerMode: true,
        }).then(async (willDelete: any) => {
            if (willDelete) {
                let localSelectedSubFamilies: any[] = [
                    ...selectedSubFamilies,
                    ...selectedFlatRows.map((row: any) => row.original),
                ];
                setSelectedSubFamilies(localSelectedSubFamilies);
                let localSubFamilies: any[] = filteredSubFamilies.filter(
                    (subFamily) => {
                        return !localSelectedSubFamilies
                            .map((s) =>
                                `${s.sub_family}|${s.family}`.toLowerCase()
                            )
                            .includes(
                                `${subFamily.sub_family}|${subFamily.family}`.toLowerCase()
                            );
                    }
                );
                setFilteredSubFamilies(localSubFamilies);

                let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/groups`;
                let savedData = {
                    designation: groupName,
                    groupment: [
                        ...groupedSubFamilies,
                        ...localSelectedSubFamilies,
                    ].map((s) => `${s.sub_family}|${s.family}`.toLowerCase()),
                };

                try {
                    NProgress.start();

                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${keycloak.token}`,
                                "x-authorization": `Bearer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                NProgress.done();

                                if (data.error) {
                                    throw Error(
                                        "Error while adding multiple articles in an articles group!"
                                    );
                                }

                                mutate(
                                    `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/group-detail?group-name=${groupName}`
                                );

                                return swal({
                                    icon: "success",
                                    content: <p>{t(data.message)}!</p>,
                                    buttons: false,
                                    timer: 2000,
                                });
                            })
                    );
                } catch (e: any) {
                    NProgress.done();
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            }
        });
    }

    function handleSelectionListButtonOnClickEvent() {
        go(2);
    }

    function handlePreviousStepAction() {
        go(2);
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between align-items-center">
                        <div className="d-md-flex align-items-center mt-2 mt-md-2 mb-2">
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    icon="AngleSmallLeftIcon"
                                    className="m-0 p-0"
                                    onClick={handlePreviousStepAction}
                                >
                                    <AngleSmallLeftIcon
                                        height={35}
                                        width={35}
                                    />
                                </StyledIconButton>
                                <span className="mt-md-2 mb-2 mx-2 white-space-nowrap h5">
                                    {groupName}
                                </span>
                            </div>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleAddingSubFamiliesButtonOnClickEvent
                                }
                            >
                                {t("Add sub-families")}
                            </StyledButton>
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={
                                    selectedSubFamilies.length +
                                        groupedSubFamilies.length ===
                                    0
                                }
                                onClick={handleSelectionListButtonOnClickEvent}
                            >
                                {`${t("My selection")} (${
                                    selectedSubFamilies.length +
                                    groupedSubFamilies.length
                                })`}
                            </StyledButton>
                        </div>
                    </div>
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {filteredSubFamilies.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
