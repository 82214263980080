import React from "react";

export default function TablePlanSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8794">
                <g data-name="Groupe 8788">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="restaurant (1)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8602"
                        d="M33.811 10.124a3.124 3.124 0 00-6.173-.677c-.122.548-.22 1.1-.32 1.655a13.394 13.394 0 00-3.608-.48V7h-1.443v8.659a3.631 3.631 0 01-.722 2.165 3.59 3.59 0 01-.741.735 3.489 3.489 0 00-1.363 2.952l.661 16.543a1.36 1.36 0 01-.385 1 1.4 1.4 0 01-1.971 0 1.362 1.362 0 01-.385-1l.517-16.545a3.484 3.484 0 00-1.364-2.948 3.59 3.59 0 01-.741-.736 3.627 3.627 0 01-.722-2.164V7h-1.443v8.072a13.664 13.664 0 002.385 20.558l-.074 2.376a2.814 2.814 0 105.624 0l-.006-.151a13.455 13.455 0 006.706-.6l-.049.664a2.812 2.812 0 105.617.194v-4.549a13.651 13.651 0 000-18.491zM16.048 33.883a12.219 12.219 0 01-2.223-16.8 4.94 4.94 0 001.832 2.641 2.049 2.049 0 01.779 1.739zm12.312 1.769a11.732 11.732 0 01-6.883.706l-.6-14.9a2.049 2.049 0 01.778-1.737 5.019 5.019 0 001.04-1.031 5.078 5.078 0 001.01-3.031v-3.6a11.942 11.942 0 013.372.471 43.83 43.83 0 00-.486 6.407v7.541a.722.722 0 00.722.722h1.662zm4.008 2.451a1.368 1.368 0 11-2.734-.091l.841-11.476a.722.722 0 00-.719-.774H28.04v-6.82a42.346 42.346 0 011.007-9.177 1.68 1.68 0 013.322.359zm3.608-13.784a12.175 12.175 0 01-2.165 6.936V17.382a12.176 12.176 0 012.165 6.936z"
                    />
                    <path
                        data-name="Trac\xE9 8603"
                        d="M16.494 7.001h1.443v7.937h-1.443z"
                    />
                    <path
                        data-name="Trac\xE9 8604"
                        d="M19.38 7.001h1.443v7.937H19.38z"
                    />
                </g>
            </g>
        </svg>
    );
}
