import { proxy } from "valtio";

type StoreType = {
    regulationModesList: any[];
};

export const store = proxy<StoreType>({
    regulationModesList: [],
});

export function setRegulationModesList(data: any[]) {
    store.regulationModesList = data;
}
