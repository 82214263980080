import React from "react";

export default function OffersAndAdditionalSaleSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8257">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g fill="#fff">
                    <path
                        data-name="Trac\xE9 8633"
                        d="M17.921 29.9l4.488-13.465a.952.952 0 10-1.807-.6L16.114 29.3a.952.952 0 101.807.6z"
                    />
                    <path
                        data-name="Trac\xE9 8634"
                        d="M24.648 25.723a2.856 2.856 0 10-2.856-2.856 2.86 2.86 0 002.856 2.856zm0-3.809a.952.952 0 11-.952.952.953.953 0 01.952-.952z"
                    />
                    <path
                        data-name="Trac\xE9 8635"
                        d="M13.875 20.007a2.856 2.856 0 102.856 2.856 2.86 2.86 0 00-2.856-2.856zm0 3.809a.952.952 0 11.952-.952.953.953 0 01-.952.955z"
                    />
                    <path
                        data-name="Trac\xE9 8636"
                        d="M37.31 19.807l-3.321-4.427.13-.907a.952.952 0 00-.269-.808l-2.02-2.02 2.02-2.02a.953.953 0 10-1.35-1.346L30.483 10.3l-2.02-2.021a.953.953 0 00-.808-.269L18.23 9.356a.952.952 0 00-.539.269L6.021 21.3a3.5 3.5 0 000 4.937l9.879 9.871a3.483 3.483 0 00.657.515v.386a3.5 3.5 0 003.487 3.491h13.965a3.5 3.5 0 003.491-3.491V20.378a.952.952 0 00-.19-.571zM7.368 24.886a1.589 1.589 0 010-2.244L18.813 11.2l8.64-1.234 1.683 1.683-1.346 1.343-.673-.673a.952.952 0 00-1.347 1.346l2.693 2.693a.952.952 0 001.347-1.346l-.673-.673 1.347-1.347 1.683 1.683-1.234 8.64-11.447 11.446a1.587 1.587 0 01-2.244 0zM35.6 37.009a1.589 1.589 0 01-1.591 1.591H20.044a1.589 1.589 0 01-1.582-1.47 3.478 3.478 0 002.371-1.019L32.5 24.438a.952.952 0 00.269-.539l.836-5.853L35.6 20.7z"
                    />
                </g>
            </g>
        </svg>
    );
}
