import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import {
    StyledButton,
    StyledIconButton,
    AddIcon,
    AngleSmallLeftIcon,
    StyledSearchInput,
} from "@aureskonnect/react-ui";
import { useKeycloak } from "@react-keycloak/web";

import { store as vStore } from "@vStore";
import store from "./store";

import { useResource } from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import Pagination from "@components/Common/StatisticsPagination";
import { NoImagePlaceholderIcon } from "../components/NoImagePlaceholderIcon";

import "@assets/widths.css";

export default function AddFamiliesEditStep({ go }: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { mainColor } = useSnapshot(vStore);
    const { groupedFamilies, groupName } = useSnapshot(store);

    const [families, setFamilies] = React.useState<any[]>([]);
    const [filteredFamilies, setFilteredFamilies] = React.useState<any[]>([]);
    const [selectedFamilies, setSelectedFamilies] = React.useState<any[]>([]);

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/families/families-list`
    );

    React.useEffect(() => {
        let localFamilies: any[] = resourceData.data!.filter((family: any) => {
            return !groupedFamilies
                .map((familyGroup: any) => familyGroup.designation)
                .includes(family.designation);
        });
        setFamilies(localFamilies);
        setFilteredFamilies(localFamilies);
    }, [resourceData, groupedFamilies]);

    const handleAddingFamilyButtonOnClickEvent = React.useCallback(
        async (family: any) => {
            swal({
                text: t("Are you sure you want to add this family?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Add")],
                dangerMode: true,
            }).then(async (willDelete: any) => {
                if (willDelete) {
                    let localSelectedFamilies: any[] = [
                        ...selectedFamilies,
                        family,
                    ];
                    setSelectedFamilies(localSelectedFamilies);
                    let localFamilies: any[] = (filteredFamilies.length > 0
                        ? filteredFamilies
                        : families
                    ).filter((family) => {
                        return !localSelectedFamilies
                            .map((selectedFamily): string =>
                                `${selectedFamily.designation}|${selectedFamily.franchise_schema}|${selectedFamily.shop_id}`.toLowerCase()
                            )
                            .includes(
                                `${family.designation}|${family.franchise_schema}|${family.shop_id}`.toLowerCase()
                            );
                    });
                    setFilteredFamilies(localFamilies);

                    let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/families/update-group`;
                    let savedData = {
                        designation: groupName,
                        groupment: [
                            ...groupedFamilies,
                            ...localSelectedFamilies,
                        ].map((s: any) => s.designation),
                    };
                    try {
                        NProgress.start();
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${keycloak.token}`,
                                    "x-authorization": `Bearer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "PUT",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    NProgress.done();
                                    if (data.error) {
                                        throw Error(
                                            "Error while adding a family in an families group!"
                                        );
                                    }
                                    mutate(
                                        `${process.env.REACT_APP_API_V1_URL}/settings/families/group-detail?group-name=${groupName}`
                                    );
                                    return swal({
                                        icon: "success",
                                        content: <p>{t(data.message)}!</p>,
                                        buttons: false,
                                        timer: 2000,
                                    });
                                })
                        );
                    } catch (e: any) {
                        NProgress.done();
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
            });
        },
        [
            selectedFamilies,
            filteredFamilies,
            families,
            groupedFamilies,
            groupName,
            t,
            keycloak.token,
        ]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Family"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (family: any) {
                    return (
                        <div>
                            {family.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                family.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (family: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleAddingFamilyButtonOnClickEvent(family)
                                }
                            >
                                {t("Add")}
                            </StyledButton>
                            <StyledIconButton
                                icon="PlusIcon"
                                className="d-block d-sm-block d-md-none m-0"
                                onClick={() =>
                                    handleAddingFamilyButtonOnClickEvent(family)
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingFamilyButtonOnClickEvent, mainColor]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredFamilies,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    function handleMultipleAddingFamiliesButtonOnClickEvent() {
        swal({
            text: t(
                "Are you sure you want to add the selected element to the group?"
            ),
            icon: "warning",
            buttons: [t("Cancel"), t("Add")],
            dangerMode: true,
        }).then(async (willDelete: any) => {
            if (willDelete) {
                let localSelectedFamilies: any[] = [
                    ...selectedFamilies,
                    ...selectedFlatRows.map((row: any) => row.original),
                ];
                setSelectedFamilies(localSelectedFamilies);
                let localFamilies: any[] = filteredFamilies.filter((family) => {
                    return !localSelectedFamilies
                        .map((selectedFamily) =>
                            `${selectedFamily.designation}|${selectedFamily.franchise_schema}|${selectedFamily.shop_id}`.toLowerCase()
                        )
                        .includes(
                            `${family.designation}|${family.franchise_schema}|${family.shop_id}`.toLowerCase()
                        );
                });
                setFilteredFamilies(localFamilies);

                let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/families/update-group`;
                let savedData = {
                    designation: groupName,
                    groupment: [
                        ...groupedFamilies,
                        ...localSelectedFamilies,
                    ].map((s: any) => s.designation),
                };

                try {
                    NProgress.start();

                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${keycloak.token}`,
                                "x-authorization": `Bearer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                NProgress.done();

                                if (data.error) {
                                    throw Error(
                                        "Error while adding multiple articles in an articles group!"
                                    );
                                }

                                mutate(
                                    `${process.env.REACT_APP_API_V1_URL}/settings/families/group-detail?group-name=${groupName}`
                                );

                                return swal({
                                    icon: "success",
                                    content: <p>{t(data.message)}!</p>,
                                    buttons: false,
                                    timer: 2000,
                                });
                            })
                    );
                } catch (e: any) {
                    NProgress.done();
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            }
        });
    }

    function handleSelectionListButtonOnClickEvent() {
        go(2);
    }

    function handlePreviousStepAction() {
        go(2);
    }

    React.useEffect(() => {
        setPageSize(5);
    }, [setPageSize]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between align-items-center">
                        <div className="d-md-flex align-items-center mt-2 mt-md-2 mb-2">
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    icon="AngleSmallLeftIcon"
                                    className="m-0 p-0"
                                    onClick={handlePreviousStepAction}
                                >
                                    <AngleSmallLeftIcon
                                        height={35}
                                        width={35}
                                    />
                                </StyledIconButton>
                                <span className="mt-md-2 mb-2 mx-2 h5 white-space-nowrap">
                                    {groupName}
                                </span>
                            </div>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleAddingFamiliesButtonOnClickEvent
                                }
                            >
                                {t("Add families")}
                            </StyledButton>
                            <StyledButton
                                rounded={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                style={{
                                    background: mainColor,
                                }}
                                disabled={
                                    selectedFamilies.length +
                                        groupedFamilies.length ===
                                    0
                                }
                                onClick={handleSelectionListButtonOnClickEvent}
                            >
                                {`${t("My selection")} (${
                                    selectedFamilies.length +
                                    groupedFamilies.length
                                })`}
                            </StyledButton>
                        </div>
                    </div>
                    {error ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {filteredFamilies.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </CustomErrorBoundary>
                    )}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
