import React from "react";
import ReactApexChart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "@sindresorhus/slugify";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-duration-format";

import {
    getRandomInt,
    getColorsArray,
    formatCurrency,
    formatChartLabels,
    getDuration,
} from "@helpers/general";

import "./ChartWrapper.css";

import "@assets/scss/dashboard.scss";

type ChartWrapperPropsType = {
    categories?: string[];
    series: SeriesDataType[];
    link?: string;
    productsData?: any[];
    chartId?: string;
};

type CustomTooltipFormatterType = {
    series: any;
    seriesIndex: any;
    dataPointIndex: any;
    w: any;
};

export default function ChartWrapper({
    series,
    categories,
    ...props
}: ChartWrapperPropsType) {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { type, period, advancedDate } = useSelector((state: any) => state);

    let matchOutOfStockRoute: boolean = pathname === "/statistics-out-of-stock";

    function customTooltipFormatter({
        series,
        seriesIndex,
        dataPointIndex,
        w,
    }: CustomTooltipFormatterType) {
        return [t("Quantity")].includes(type)
            ? `
            <div class="card m-0">
                    <div class="card-header">
                        ${w.globals.categoryLabels[dataPointIndex]}
                    </div>
                    <div class="card-body py-1 pt-1 pb-0" >
                        <div class="card-title d-flex">
                            <div class ="mt-0" style="width: 15px;height: 14px;border-radius: 20px;background:${
                                w.globals.colors[seriesIndex]
                            }">                
                            </div>                                
                            <h6 class="ml-2">
                                ${w.globals.seriesNames[seriesIndex]}:
                            </h6>                
                            <h6 class="font-weight-bold ml-1">
                                ${series[seriesIndex][dataPointIndex]}
                            </h6>
                        </div> 
                        <div style="width: 300px; max-height: 100px;overflow-x: auto;scrollbar-width: thin">          
                        ${props
                            .productsData![seriesIndex][dataPointIndex].map(
                                (el: any) => {
                                    return `<div class="d-flex small">
                                                          <p>${el.designation}:</p>
                                                          <p class="font-weight-bold ml-1">${el.value}</p>
                                                         </div>`;
                                }
                            )
                            .join("")}
                            </div>
                </div>
            </div>`
            : `<div class="card m-0">
                    <div class="card-header">
                        ${w.globals.categoryLabels[dataPointIndex]}
                    </div>
                    <div class="card-body py-1 pt-1 pb-0">
                        <div class="card-title d-flex">
                            <div class ="mt-0" style="width: 15px;height: 14px;border-radius: 20px;background: ${
                                w.globals.colors[seriesIndex]
                            }">
                            </div>
                            <div class="ml-2 d-flex">
                                <h6>${w.globals.seriesNames[seriesIndex]}:</h6>
                                <h6 class="font-weight-bold ml-1">
                                    ${moment
                                        .duration(
                                            series[seriesIndex][dataPointIndex],
                                            "h"
                                        )
                                        .format(
                                            `y[${t("y")}] M[${t("m")}] w[${t(
                                                "w"
                                            )}] D[${t("d")}] H[${t("h")}] m[${t(
                                                "min"
                                            )}] s[${t("sec")}]`,
                                            { trim: "all" }
                                        )}
                                </h6>
                            </div>
                        </div>
                        ${props
                            .productsData![seriesIndex][dataPointIndex].map(
                                (el: any) => {
                                    return `<div class="d-flex small"> 
                                                            <p>${
                                                                el.designation
                                                            }:</p>
                                                            <p class="font-weight-bold ml-1">
                                                                ${moment
                                                                    .duration(
                                                                        el.value,
                                                                        "h"
                                                                    )
                                                                    .format(
                                                                        `y[${t(
                                                                            "y"
                                                                        )}] M[${t(
                                                                            "m"
                                                                        )}] w[${t(
                                                                            "w"
                                                                        )}] D[${t(
                                                                            "d"
                                                                        )}] H[${t(
                                                                            "h"
                                                                        )}] m[${t(
                                                                            "min"
                                                                        )}] s[${t(
                                                                            "sec"
                                                                        )}]`,
                                                                        {
                                                                            trim:
                                                                                "all",
                                                                        }
                                                                    )}
                                                            </p>
                                                        </div>`;
                                }
                            )
                            .join("")}
                    </div>
        </div>`;
    }

    function handleBarClick(_: any, __: any, config: any) {
        if (matchOutOfStockRoute) {
            if (config.globals.chartID === "families") {
                document
                    .getElementsByClassName(
                        "apexcharts-tooltip apexcharts-theme-light"
                    )[1]
                    .classList.toggle("tooltips__clz");
            }

            if (config.globals.chartID === "subFamilies") {
                document
                    .getElementsByClassName(
                        "apexcharts-tooltip apexcharts-theme-light"
                    )[2]
                    .classList.toggle("tooltips__clz");
            }
        }
    }

    const options: object = {
        colors: getColorsArray(series.length),
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    legend: {
                        show: false,
                    },
                },
            },
        ],
        chart: {
            id: props.chartId,
            events: {
                click: handleBarClick,
            },
            width: "10%",
            height: "100%",
            stacked: true,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "10%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories,
            tickPlacement: "on",
            labels: {
                formatter: (value: any) =>
                    formatChartLabels(value, advancedDate, period),
            },
        },
        yaxis: {
            tickAmount: 1,
            labels: {
                formatter: function (value: any) {
                    return [
                        t("Tickets"),
                        t("Quantity sold"),
                        t("Item number"),
                        t("Quantity"),
                    ].includes(type)
                        ? Math.round(value)
                        : [t("Duration")].includes(type)
                        ? getDuration(value)
                        : formatCurrency(value, 0);
                },
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: "bottom",
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            intersect: true,
            shared: false,
            y: {
                formatter:
                    props.productsData === undefined
                        ? function (value: any) {
                              return [
                                  t("Tickets"),
                                  t("Quantity sold"),
                                  t("Item number"),
                                  t("Quantity"),
                              ].includes(type)
                                  ? Math.round(value)
                                  : [t("Duration")].includes(type)
                                  ? moment
                                        .duration(value, "h")
                                        .format(
                                            `y[${t("y")}] M[${t("m")}] w[${t(
                                                "w"
                                            )}] D[${t("d")}] H[${t("h")}] m[${t(
                                                "min"
                                            )}] s[${t("sec")}]`,
                                            { trim: "all" }
                                        )
                                  : formatCurrency(value, 0);
                          }
                        : undefined,
            },
            custom:
                props.productsData !== undefined
                    ? function ({
                          series,
                          seriesIndex,
                          dataPointIndex,
                          w,
                      }: CustomTooltipFormatterType) {
                          return customTooltipFormatter({
                              series,
                              seriesIndex,
                              dataPointIndex,
                              w,
                          });
                      }
                    : undefined,
        },
        noData: {
            text: t("No data"),
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: "14px",
                fontFamily: undefined,
            },
        },
        markers: {
            size: [4, 7],
        },
    };

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height="345"
            key={`apex-chart__${getRandomInt(
                series.length + Math.random()
            )}_${slugify(
                `${props.link || "react-apex-chart"}_${type}`
            )}_${Date.now()}`}
        />
    );
}
