import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { keycloak } from "@keycloak";

const initOptions = {
    onLoad: "login-required",
    redirectUri: process.env.REACT_APP_APP_SSO_REDIRECT_URL,
};

export function KeycloakProvider({ children }: ChildrenPropsType) {
    return (
        <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
            {children}
        </ReactKeycloakProvider>
    );
}
