import React from "react";

export default function SoftapaySvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8771">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8752" fill="#fff">
                    <path
                        data-name="Trac\xE9 1814"
                        d="M17.105 21.529c.026 0 .048.01.073.013l2.474.118v-.01h4.994l-2.341-2.436h-3.87a4.99 4.99 0 01-4.844-3.774l-2.586-2.433v1.207c0 .128 0 .255.01.382.006.1.013.207.022.309s.019.178.032.268a.783.783 0 00.009.086c.01.061.016.118.029.175q.019.134.048.267a.085.085 0 00.006.026c.019.1.042.2.067.3.035.147.076.293.121.433.032.1.067.2.1.3v.016q.062.167.134.334c.044.105.092.21.14.312a6.453 6.453 0 00.306.57c.054.092.108.181.169.271.035.054.07.108.108.162s.083.121.128.182.089.121.137.178c.061.076.121.156.185.229a7.38 7.38 0 001.178 1.118c.083.064.169.128.252.185a19.701 19.701 0 00.271.181c.086.054.172.105.258.153.013.01.026.016.035.022a6.858 6.858 0 00.485.255c.093.045.2.1.306.137.07.032.143.06.216.086a5.412 5.412 0 00.66.219c.15.044.3.08.453.111.082.019.158.035.235.048z"
                    />
                    <path
                        data-name="Trac\xE9 1815"
                        d="M24.602 27.867h3.758l-2.341-2.437h-3.873a2.515 2.515 0 01-2.013-1.009 3.02 3.02 0 01-.121-.175 2.778 2.778 0 01-.073-.128 2.67 2.67 0 01-.143-.312 2.568 2.568 0 01-.163-.873V21.65s-2.5-.111-2.475-.108l.042 1.379.009.256a4.927 4.927 0 001.057 2.809 5.515 5.515 0 00.472.519c.054.051.111.1.165.15s.134.111.2.165.108.083.162.121.124.089.191.13.131.083.195.121.137.076.2.111.137.07.207.105a4.768 4.768 0 00.43.175 4.667 4.667 0 00.949.233c.07.013.14.019.21.025a4.832 4.832 0 00.487.026h2.455z"
                    />
                    <path
                        data-name="Trac\xE9 1816"
                        d="M34.563 31.657h-7.046v-.006h-.363a2.511 2.511 0 01-2.51-2.51h-.006v-1.268h-2.43v1.475h.009a4.955 4.955 0 001.682 3.507l.178.149a5.186 5.186 0 00.774.507l.21.1c.076.035.153.07.229.1l.2.076c.073.029.15.051.223.073s.153.045.229.064a4.819 4.819 0 001.2.15h9.843z"
                    />
                    <path
                        data-name="Trac\xE9 1817"
                        d="M37 34.089v-12.7A8.4 8.4 0 0028.611 13H11l2.537 2.433h15.074a5.968 5.968 0 015.959 5.956v10.26z"
                    />
                </g>
            </g>
        </svg>
    );
}
