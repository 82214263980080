import React from "react";
import countryList from "react-select-country-list";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";

import { setCountry } from "./store";

export default function CountriesSelect() {
    const { t } = useTranslation();

    const [selectedValueState, setSelectedValueState] = React.useState<any[]>([
        { value: "FR", label: "France" },
    ]);

    const countries = countryList().getData();

    function CountryFlag(props: any) {
        return (
            <span
                className={`flag-icon flag-icon-${props.code} mr-2`}
                style={{ fontSize: "20px" }}
            />
        );
    }

    const Option = ({ getValue, setValue, ...props }: any) => {
        return (
            <components.Option {...props}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CountryFlag code={props.value.toLowerCase()} />
                    <label style={{ fontSize: "15px" }} className="mb-0">
                        {props.label}
                    </label>
                </div>
            </components.Option>
        );
    };

    const ValueContainer = ({ children, getValue, ...props }: any) => {
        const code = (props.hasValue && getValue()[0].value) || false;

        return (
            <div style={{ display: "flex", flexGrow: 1 }} className="ml-3">
                {code ? <CountryFlag code={code.toLowerCase()} /> : null}
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            </div>
        );
    };

    function handleSelectOnChangeEvent(selectedValue: any) {
        setSelectedValueState(selectedValue);
        setCountry(selectedValue.value);
    }

    return (
        <Select
            isSearchable
            options={countries}
            value={selectedValueState}
            onChange={handleSelectOnChangeEvent}
            components={{
                Option,
                ValueContainer,
            }}
            placeholder={t("Select a country")}
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderRadius: "0px",
                    borderColor: "unset",
                    boxShadow: "unset",
                }),
                indicatorSeparator: (provided: any) => ({
                    ...provided,
                    display: "none",
                }),
                placeholder: (provider: any) => ({
                    ...provider,
                    fontSize: "0.8125rem",
                }),
                valueContainer: (provider: any) => ({
                    ...provider,
                    fontSize: "15px",
                    paddingLeft: 0,
                    paddingRight: 0,
                }),
                singleValue: (provider: any) => ({
                    ...provider,
                    color: "black",
                }),
            }}
        />
    );
}
