import React from "react";

export default function FidelitySvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8772">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="credit-card (4)" fill="#fff" stroke="#fff">
                    <path
                        data-name="Trac\xE9 8627"
                        d="M37.405 34.715H10.262A2.265 2.265 0 018 32.453V15.262A2.265 2.265 0 0110.262 13h27.143a2.265 2.265 0 012.262 2.262v17.191a2.265 2.265 0 01-2.262 2.262zM10.262 13.9A1.357 1.357 0 008.9 15.262v17.191a1.357 1.357 0 001.362 1.357h27.143a1.357 1.357 0 001.357-1.357V15.262a1.357 1.357 0 00-1.357-1.362z"
                    />
                    <path
                        data-name="Trac\xE9 8628"
                        d="M39.215 21.142H8.452A.452.452 0 018 20.69v-3.167a.452.452 0 01.452-.452h30.763a.452.452 0 01.452.452v3.167a.452.452 0 01-.452.452zm-30.31-.9h29.857v-2.271H8.9z"
                    />
                    <path
                        data-name="Trac\xE9 8629"
                        d="M33.786 31.548a.452.452 0 01-.293-.108 11.106 11.106 0 01-1.9-2.12.454.454 0 01.766-.486 9.427 9.427 0 001.427 1.653 5.691 5.691 0 001.8-2.563.825.825 0 00-.226-.626.809.809 0 00-1.226.037.468.468 0 01-.71 0 .8.8 0 00-1.159-.1.452.452 0 01-.591-.685 1.734 1.734 0 012.108-.11 1.746 1.746 0 012.243.247 1.719 1.719 0 01.465 1.306c-.1 1.436-2.178 3.249-2.415 3.451a.452.452 0 01-.289.104z"
                    />
                    <path
                        data-name="Trac\xE9 8630"
                        d="M30.167 31.547a.452.452 0 01-.293-.108c-.237-.2-2.316-2.014-2.417-3.45h0a1.726 1.726 0 01.467-1.309 1.744 1.744 0 012.243-.245 1.744 1.744 0 012.243.244 1.726 1.726 0 01.467 1.309c-.1 1.437-2.18 3.249-2.417 3.45a.452.452 0 01-.293.109zm-1.814-3.619a5.681 5.681 0 001.81 2.562 5.716 5.716 0 001.81-2.562.829.829 0 00-.22-.63.814.814 0 00-1.228.04.468.468 0 01-.707 0 .814.814 0 00-1.232-.045.829.829 0 00-.226.629z"
                    />
                    <path
                        data-name="Trac\xE9 8631"
                        d="M18.857 28.15h-6.334a.452.452 0 110-.9h6.333a.452.452 0 010 .9z"
                    />
                    <path
                        data-name="Trac\xE9 8632"
                        d="M16.595 30.412h-4.072a.452.452 0 110-.9h4.071a.452.452 0 010 .9z"
                    />
                </g>
            </g>
        </svg>
    );
}
