import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { StyledButton } from "@aureskonnect/react-ui";
import { useKeycloak } from "@react-keycloak/web";

import { store as vStore } from "@vStore";

import store, {
    clearStore,
    setSegmentQueryString,
    setSelectedSegments,
    clearSelectedSegments,
} from "@pages/Statistics/Transactions/store";

type PropsType = { toggleNewSegmentModal: () => void };

export default function CustomModalFooter({
    toggleNewSegmentModal,
}: PropsType) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { mainColor } = useSnapshot(vStore);
    const advancedStore = useSnapshot(store);

    const [segmentNameModal, setSegmentNameModal] = React.useState(false);
    const [segmentName, setSegmentName] = React.useState<string>("");
    const [products, setProducts] = React.useState<any[]>([]);

    function toggleSegmentNameModal() {
        setSegmentNameModal(!segmentNameModal);
    }

    function handleSegmentDesignationInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setSegmentName(e.target.value);
    }

    React.useEffect(() => {
        if (
            advancedStore.productsSelection.length ===
            advancedStore.productsNumber
        ) {
            setProducts(["all"]);
        } else {
            let localProducts: any[] = [];
            advancedStore.productsSelection.forEach((product) => {
                if (product.reference === "multi") {
                    localProducts.push(product.designation);
                } else {
                    localProducts.push(
                        `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`
                    );
                }
            });
            setProducts(localProducts);
        }
        setSegmentName(advancedStore.segmentName);
    }, [advancedStore]);

    async function sendSavingSegmentRequest() {
        let apiUrl = `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/segment`;

        let savedData = {
            designation: segmentName,
            value: [
                {
                    designation: "sales_mode",
                    value: advancedStore.salesModeSelection,
                },
                {
                    designation: "sales_support",
                    value: advancedStore.salesSupportSelection.map(
                        (selection) => selection.toUpperCase()
                    ),
                },
                {
                    designation: "regulations",
                    value: advancedStore.regulationModesSelection,
                },
                {
                    designation: "articles",
                    value: products,
                },
                {
                    designation: "customer_city",
                    value: advancedStore.customersCitiesSelection,
                },
                {
                    designation: "transaction_amount",
                    value: advancedStore.transactionsAmountRange,
                },
            ],
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: advancedStore.segmentName !== "" ? "PUT" : "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        setSegmentNameModal(!segmentNameModal);
                        clearStore();
                        clearSelectedSegments();
                        setSelectedSegments(segmentName);
                        setSegmentQueryString(segmentName);
                        toggleNewSegmentModal();

                        return swal({
                            icon: "success",
                            content: <p>{t(data.message)}!</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    })
            );
        } catch (e) {
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
                buttons: false,
                timer: 2000,
            });
        }
    }

    async function handleValidateButtonOnClickEvent() {
        let segmentObject = {
            designation: "temp",
            value: [
                {
                    designation: "sales_mode",
                    value: advancedStore.salesModeSelection,
                },
                {
                    designation: "sales_support",
                    value: advancedStore.salesSupportSelection.map(
                        (selection) => selection.toUpperCase()
                    ),
                },
                {
                    designation: "regulations",
                    value: advancedStore.regulationModesSelection,
                },
                {
                    designation: "articles",
                    value: products,
                },
                {
                    designation: "customer_city",
                    value: advancedStore.customersCitiesSelection,
                },
                {
                    designation: "transaction_amount",
                    value: advancedStore.transactionsAmountRange,
                },
            ],
        };

        setSegmentQueryString(
            encodeURIComponent(JSON.stringify(segmentObject))
        );

        clearSelectedSegments();
        toggleNewSegmentModal();
    }

    async function sendUpdateSegmentNameRequest() {
        try {
            NProgress.start();

            mutate(
                `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/edit-segment-name`,
                await fetch(
                    `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/edit-segment-name`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${keycloak.token}`,
                            "x-authorization": `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            "old-name": advancedStore.segmentName,
                            "new-name": segmentName,
                        }),
                        method: "PUT",
                    }
                )
                    .then((response) => response.json())
                    .then(async (data) => {
                        NProgress.done();

                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        setSegmentNameModal(!segmentNameModal);
                        clearStore();
                        toggleNewSegmentModal();
                        sendSavingSegmentRequest();

                        return swal({
                            icon: "success",
                            content: <p>{t(data.message)}!</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    })
            );
        } catch (e) {
            NProgress.done();

            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
                buttons: false,
                timer: 2000,
            });
        }
    }

    function handleSegmentValidateButtonOnClickEvent() {
        if (advancedStore.productsSelection.length === 0) {
            return swal({
                icon: "error",
                content: <p>{t("Please select a product")!}</p>,
                buttons: false,
                timer: 2000,
            });
        }

        toggleSegmentNameModal();
    }

    function handleSaveButtonOnClickEvent() {
        if (advancedStore.segmentName !== "") {
            if (segmentName === advancedStore.segmentName) {
                sendSavingSegmentRequest();
            } else {
                if (advancedStore.segments.includes(segmentName)) {
                    return swal({
                        icon: "error",
                        content: (
                            <p>
                                {t("The name of this segment already exists")!}
                            </p>
                        ),
                        buttons: false,
                        timer: 2000,
                    });
                }

                sendUpdateSegmentNameRequest();
            }
        } else {
            if (advancedStore.segments.includes(segmentName)) {
                return swal({
                    icon: "error",
                    content: (
                        <p>{t("The name of this segment already exists")!}</p>
                    ),
                    buttons: false,
                    timer: 2000,
                });
            }

            sendSavingSegmentRequest();
        }
    }

    return (
        <React.Fragment>
            <StyledButton
                rounded={true}
                outline={false}
                variant={mainColor === null ? "primary" : ""}
                onClick={handleSegmentValidateButtonOnClickEvent}
                style={{
                    marginRight: "0.5rem",
                    background: mainColor,
                }}
            >
                {t("Save")}
            </StyledButton>
            <StyledButton
                rounded
                outline={false}
                variant={mainColor === null ? "primary" : ""}
                onClick={() => {
                    handleValidateButtonOnClickEvent();
                }}
                style={{
                    marginRight: "0.5rem",
                    background: mainColor,
                }}
            >
                {t("Validate")}
            </StyledButton>
            <Modal
                isOpen={segmentNameModal}
                toggle={toggleSegmentNameModal}
                centered
                backdrop="static"
            >
                <ModalBody>
                    <p className="text-uppercase font-weight-bold font-size-16 text-center mt-4">
                        {t("Save the segment")}
                    </p>
                    <input
                        type="text"
                        value={segmentName}
                        className="form-control mt-4 ml-4"
                        style={{ width: "90%" }}
                        placeholder={t("Enter the designation")}
                        onChange={handleSegmentDesignationInputOnChangeEvent}
                    />
                </ModalBody>
                <ModalFooter className="justify-content-center mt-3">
                    <Button
                        color={mainColor === null ? "secondary" : ""}
                        outline={true}
                        onClick={toggleSegmentNameModal}
                        className="w-25"
                        style={{
                            background: mainColor,
                        }}
                    >
                        {t("Return")}
                    </Button>
                    <Button
                        type="button"
                        color="success"
                        onClick={handleSaveButtonOnClickEvent}
                        className="w-25"
                    >
                        {t("Save")}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
