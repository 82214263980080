import React from "react";

export default function PhysicalPaymentMethodSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8764">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8278">
                    <g data-name="Groupe 8277">
                        <circle
                            data-name="Ellipse 631"
                            cx={1.495}
                            cy={1.495}
                            r={1.495}
                            fill="#fff"
                            transform="translate(29.426 18.971)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8280">
                    <g data-name="Groupe 8279">
                        <circle
                            data-name="Ellipse 632"
                            cx={1.495}
                            cy={1.495}
                            r={1.495}
                            fill="#fff"
                            transform="translate(14.475 18.971)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8282">
                    <g data-name="Groupe 8281">
                        <path
                            data-name="Trac\xE9 8550"
                            d="M23.446 15.98a4.485 4.485 0 104.485 4.485 4.5 4.5 0 00-4.485-4.485zm0 7.475a2.99 2.99 0 112.99-2.99 3 3 0 01-2.99 2.988z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8284">
                    <g data-name="Groupe 8283">
                        <path
                            data-name="Trac\xE9 8551"
                            d="M37.649 10H9.243A2.2 2.2 0 007 12.243v16.446a2.2 2.2 0 002.243 2.243h9.718a.75.75 0 100-1.5H9.243a.706.706 0 01-.748-.748V12.243a.706.706 0 01.748-.748h28.406a.706.706 0 01.748.748v11.96a.75.75 0 101.5 0v-11.96A2.2 2.2 0 0037.649 10z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8286">
                    <g data-name="Groupe 8285">
                        <path
                            data-name="Trac\xE9 8552"
                            d="M36.079 14.485a.706.706 0 01-.748-.748.706.706 0 00-.748-.748h-22.35a.706.706 0 00-.748.748.706.706 0 01-.748.748.706.706 0 00-.748.748v10.465a.706.706 0 00.748.748.706.706 0 01.748.748.706.706 0 00.748.748h7.476a.75.75 0 100-1.5h-6.878a2.184 2.184 0 00-1.346-1.346v-9.27a2.184 2.184 0 001.346-1.346h21.155a2.184 2.184 0 001.346 1.346v6.13a.75.75 0 001.5 0v-6.723a.706.706 0 00-.753-.748z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8288">
                    <g data-name="Groupe 8287">
                        <path
                            data-name="Trac\xE9 8553"
                            d="M33.164 23.456a5.782 5.782 0 00-2.542.6.737.737 0 10.6 1.346 4.361 4.361 0 011.944-.449 4.45 4.45 0 013.663 7.027.791.791 0 00.15 1.047 1.063 1.063 0 00.448.149.68.68 0 00.6-.3 6.029 6.029 0 001.047-3.439 5.887 5.887 0 00-5.91-5.981z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8290">
                    <g data-name="Groupe 8289">
                        <path
                            data-name="Trac\xE9 8554"
                            d="M33.165 27.194a.706.706 0 00-.748.747v.748a.748.748 0 101.495 0v-.748a.706.706 0 00-.747-.747z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8292">
                    <g data-name="Groupe 8291">
                        <path
                            data-name="Trac\xE9 8555"
                            d="M30.701 26.745a5.973 5.973 0 10-8.447 8.447.723.723 0 001.047 0 .723.723 0 000-1.047 4.493 4.493 0 013.14-7.7 4.348 4.348 0 013.214 1.346.74.74 0 001.047-1.047z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8294">
                    <g data-name="Groupe 8293">
                        <path
                            data-name="Trac\xE9 8556"
                            d="M30.174 29.436a5.98 5.98 0 105.98 5.98 6 6 0 00-5.98-5.98zm0 10.466a4.485 4.485 0 114.485-4.485 4.5 4.5 0 01-4.485 4.485z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8296">
                    <g data-name="Groupe 8295">
                        <path
                            data-name="Trac\xE9 8557"
                            d="M30.174 33.174a.706.706 0 00-.747.748v2.99a.748.748 0 001.495 0v-2.99a.706.706 0 00-.748-.748z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8298">
                    <g data-name="Groupe 8297">
                        <circle
                            data-name="Ellipse 633"
                            cx={0.748}
                            cy={0.748}
                            r={0.748}
                            fill="#fff"
                            transform="translate(25.689 28.689)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
