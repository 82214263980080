import { proxy } from "valtio";

type StoreType = {
    salesSupportList: any[];
};

export const store = proxy<StoreType>({
    salesSupportList: [],
});

export function setSalesSupportList(data: any[]) {
    store.salesSupportList = data;
}
