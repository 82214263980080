import React from "react";
import { useRouteMatch } from "react-router-dom";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

import SidebarContent from "./SidebarContent";

type SidebarPropsType = { type: string };

export default function Sidebar({ type }: SidebarPropsType) {
    let matchMySelectionsRoute = useRouteMatch("/my-selections");
    let matchMySelectionsListRoute = useRouteMatch("/my-selection-list");
    let matchHomeRoute = useRouteMatch("/home");

    const { mainColor } = useSnapshot(store);

    return (
        <React.Fragment>
            <div
                className={classnames({
                    "vertical-menu":
                        !matchMySelectionsRoute?.isExact &&
                        !matchMySelectionsListRoute?.isExact,
                    "d-none":
                        matchMySelectionsRoute?.isExact ||
                        matchMySelectionsListRoute?.isExact ||
                        matchHomeRoute?.isExact,
                })}
                style={{ background: mainColor }}
            >
                <div data-simplebar className="h-100">
                    {type !== "condensed" ? (
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <SidebarContent />
                        </SimpleBar>
                    ) : (
                        <SidebarContent />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
