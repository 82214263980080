import i18n from "i18next";

export const shop = [i18n.t("Box"), i18n.t("Thick headed")];
export const clickCollect = [i18n.t("Web site"), i18n.t("Application")];
export const digitalPaymentMethod = [
    i18n.t("Bank card"),
    i18n.t("Softapay"),
    i18n.t("Fidelity"),
];
export const platform = [i18n.t("Uber eats"), i18n.t("Deliveroo")];
export const physicalPaymentMethod = [
    i18n.t("Cash box"),
    i18n.t("Coin operated cash"),
    i18n.t("Ticket restaurant"),
    i18n.t("Check"),
];
export const service = [i18n.t("Midday service"), i18n.t("Evening service")];
export const salesMode = [
    i18n.t("On the spot"),
    i18n.t("To take"),
    i18n.t("In delivery"),
    i18n.t("Seating plan"),
];
