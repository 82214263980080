import { TOGGLE_TAB } from "./actionsTypes";
import { ToggleTabActionType } from "./actions";

const TOGGLE_TAB_INIT_STATE: number | null = null;

export default function toggleTabReducer(
    state = TOGGLE_TAB_INIT_STATE,
    action: ToggleTabActionType
): number | null {
    return action.type === TOGGLE_TAB ? action.payload : state;
}
