import { proxy } from "valtio";

type StoreType = {
    mainColor: string;
    secondaryColor: string;
};

const mainColor = localStorage.getItem("mainColor");
const secondaryColor = localStorage.getItem("secondaryColor");

export const store = proxy<StoreType>({
    mainColor: mainColor !== null ? mainColor : "#323333",
    secondaryColor: secondaryColor !== null ? secondaryColor : "#c4bfbe",
});

export function setMainColor(color: string) {
    store.mainColor = color;
}

export function setSecondaryColor(color: string) {
    store.secondaryColor = color;
}
