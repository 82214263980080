import {
    ADD_REGULATION,
    RESET_REGULATION,
    REMOVE_REGULATION,
} from "../regulations/actions-types";

type ReturnedActionsDataType = {
    type: string;
    payload: any;
};

export function resetRegulation(): ReturnedActionsDataType {
    return {
        type: RESET_REGULATION,
        payload: [],
    };
}

export function addRegulation(regulationItem: any): ReturnedActionsDataType {
    return {
        type: ADD_REGULATION,
        payload: regulationItem,
    };
}

export function removeRegulation(item: string): ReturnedActionsDataType {
    return {
        type: REMOVE_REGULATION,
        payload: item,
    };
}
