import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import classNames from "classnames";
import swal from "@sweetalert/with-react";
import { mutate } from "swr";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

import { store as vStore } from "@vStore";
import store, {
    setCheckedFranchises,
    setSavedSelection,
} from "@pages/MySelections/store";
import { selectShops } from "@store/selections/actions";

import MySelectionsContext from "@pages/MySelections/context";

import { Table } from "./Table";

type PropsType = {
    toggleOpeningModal: () => void;
};

export function SavedSelectionModalWrapper({ toggleOpeningModal }: PropsType) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const { mainColor } = useSnapshot(vStore);
    const { savedSelection } = useSnapshot(store);

    const { setSelection } = React.useContext(MySelectionsContext);

    const [selectedSelection, setSelectedSelection] = React.useState<
        SelectionType
    >({ designation: "", selection: [] });

    const sendDeletingSelectionRequest = React.useCallback(
        async function (selection: SelectionType) {
            await fetch(
                `${process.env.REACT_APP_API_V1_URL}/selections/delete`,
                {
                    headers: {
                        accept: "application/json",
                        "content-type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                        "x-authorization": `Bearer ${localStorage.getItem(
                            "jwt"
                        )}`,
                    },
                    body: JSON.stringify(selection),
                    method: "DELETE",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("Error while saving selection!");
                    }

                    const newSelections = savedSelection.filter(
                        (el) => el.designation !== selection.designation
                    );

                    setSavedSelection(newSelections);

                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}!</p>,
                        timer: 2000,
                    });
                })
                .catch((_) => {
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        timer: 2000,
                    });
                });

            mutate(
                `${process.env.REACT_APP_API_V1_URL}/​selections​/selections-list`
            );
        },
        [savedSelection, t, keycloak.token]
    );

    const handleRemoveSelectedSelection = React.useCallback(
        (selection: SelectionType) => {
            swal({
                text: t("Are you sure you want to delete this selection?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Delete")],
                dangerMode: true,
            }).then((willDelete: boolean) => {
                if (willDelete === true) {
                    sendDeletingSelectionRequest(selection);
                }
            });
        },
        [sendDeletingSelectionRequest, t]
    );

    const columns: AdvancedTableColumnsType[] = React.useMemo(
        () => [
            {
                Header: t("Selection"),
                accessor: ({ designation }: SelectionType) => (
                    <div>{designation}</div>
                ),
            },
            {
                Header: t("Actions"),
                accessor: (selection: SelectionType) => (
                    <button
                        className="border-0 d-block d-sm-block p-1 bg-transparent__clz icon-color__clz"
                        onClick={() => handleRemoveSelectedSelection(selection)}
                    >
                        <i className="bx bxs-trash-alt font-size-24" />
                    </button>
                ),
            },
        ],
        [t, handleRemoveSelectedSelection]
    );

    function handleValidateSelectionButtonOnClickEvent() {
        dispatch(selectShops(selectedSelection));
        setSelection!(selectedSelection.designation);
        setCheckedFranchises([]);

        toggleOpeningModal();
        history.push("/dashboard");
    }

    return (
        <Modal
            isOpen={true}
            onClosed={() => {
                setSelection!("");
            }}
            centered={true}
        >
            <ModalBody className="justify-content-center">
                <p className="text-uppercase font-size-14 ml-5 mt-4">
                    {t("My saved selections")}
                </p>
                <Table
                    columns={columns}
                    data={savedSelection}
                    setSelectedSelection={setSelectedSelection}
                />
            </ModalBody>
            <ModalFooter className="justify-content-center mt-3">
                <Button
                    color={mainColor === null ? "secondary" : ""}
                    outline={true}
                    onClick={toggleOpeningModal}
                    className="w-25"
                    style={{ borderColor: mainColor, color: mainColor }}
                >
                    {t("Close")}
                </Button>
                <Button
                    type="button"
                    color={mainColor === null ? "dark" : ""}
                    onClick={handleValidateSelectionButtonOnClickEvent}
                    className={classNames("w-25", {
                        "text-white": mainColor !== null,
                    })}
                    style={{ background: mainColor }}
                >
                    {t("Validate")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
