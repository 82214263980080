import React from "react";
import { useLocation } from "react-router-dom";

import { init, sendPageview } from "./analytics";

export default function useGoogleAnalytics() {
    const location = useLocation();

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        sendPageview(location.pathname + location.search);
    }, [location]);
}
