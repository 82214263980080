import i18n from "i18next";
import React from "react";

import {
    AppleSvgIcon,
    MobileSvgIcon,
    DeliverooSvgIcon,
    UberEatsSvgIcon,
    ClickAndCollectSvgIcon,
    ThickHeadedSvgIcon,
    CheckoutSvgIcon,
    AllSalesSupportSvgIcon,
    AllSalesMethodsSvgIcon,
    TablePlanSvgIcon,
    OnTheSpotSvgIcon,
    TakeOutSvgIcon,
    InDeliverySvgIcon,
    PhysicalPaymentMethodSvgIcon,
    CashBoxSvgIcon,
    CheckSvgIcon,
    BankCardSvgIcon,
    SoftapaySvgIcon,
    RestaurantTicketSvgIcon,
    ShopSvgIcon,
    DesktopSvgIcon,
    AndroidSvgIcon,
    PlatformSvgIcon,
} from "@components/Common/SvgIcons";

export default function getItemIcon(name: string) {
    if (name === i18n.t("Box")) {
        return <CheckoutSvgIcon />;
    }
    if (name === i18n.t("Deliveroo")) {
        return <DeliverooSvgIcon />;
    }
    if (name === i18n.t("Thick headed")) {
        return <ThickHeadedSvgIcon />;
    }
    if (name === i18n.t("Uber eats")) {
        return <UberEatsSvgIcon />;
    }
    if (name === `${i18n.t("All sales supports")}`) {
        return <AllSalesSupportSvgIcon />;
    }
    if (name === i18n.t("Applications")) {
        return <MobileSvgIcon />;
    }
    if (name === i18n.t("Web") || name === i18n.t("Web site")) {
        return <ClickAndCollectSvgIcon />;
    }
    if (name === i18n.t("In delivery")) {
        return <InDeliverySvgIcon />;
    }
    if (name === i18n.t("To take")) {
        return <TakeOutSvgIcon />;
    }
    if (name === i18n.t("On the spot")) {
        return <OnTheSpotSvgIcon />;
    }
    if (name === i18n.t("Seating plan")) {
        return <TablePlanSvgIcon />;
    }
    if (name === i18n.t("Cash box")) {
        return <CashBoxSvgIcon />;
    }
    if (name === i18n.t("CB")) {
        return <AppleSvgIcon />;
    }
    if (name === i18n.t("Change machine")) {
        return <AppleSvgIcon />;
    }
    if (name === i18n.t("Online")) {
        return <AppleSvgIcon />;
    }
    if (name === `${i18n.t("All")} ${i18n.t("Regulations")}`) {
        return <AllSalesMethodsSvgIcon />;
    }
    if (name === `${i18n.t("All")} ${i18n.t("Services")}`) {
        return <AppleSvgIcon />;
    }
    if (name === i18n.t("Physical Payment Method")) {
        return <PhysicalPaymentMethodSvgIcon />;
    }
    if (name === i18n.t("Check")) {
        return <CheckSvgIcon />;
    }
    if (name === i18n.t("Digital Payment Method")) {
        return <PhysicalPaymentMethodSvgIcon />;
    }
    if (name === i18n.t("Bank card")) {
        return <BankCardSvgIcon />;
    }
    if (name === i18n.t("Softapay")) {
        return <SoftapaySvgIcon />;
    }
    if (name === i18n.t("Shop")) {
        return <ShopSvgIcon />;
    }
    if (name === i18n.t("Platform")) {
        return <CheckoutSvgIcon />;
    }
    if (name === i18n.t("Click & collect")) {
        return <ClickAndCollectSvgIcon />;
    }
    if (name === i18n.t("Ticket restaurant")) {
        return <RestaurantTicketSvgIcon />;
    }
    if (name === i18n.t("Click & collect")) {
        return <CheckoutSvgIcon />;
    }
    if (name === i18n.t("Desktop")) {
        return <DesktopSvgIcon />;
    }
    if (name === i18n.t("Application")) {
        return <MobileSvgIcon />;
    }
    if (name === i18n.t("Android")) {
        return <AndroidSvgIcon />;
    }
    if (name === i18n.t("IOS")) {
        return <AppleSvgIcon />;
    }
    if (name === i18n.t("IOS")) {
        return <AppleSvgIcon />;
    }

    if (name === i18n.t("Platform")) {
        return <PlatformSvgIcon />;
    }

    if (name === i18n.t("All modes")) {
        return <AllSalesMethodsSvgIcon />;
    }
}
