import React from "react";

export default function ReportingSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={114.523}
            height={114.5}
            {...props}
        >
            <g data-name="Groupe 9807">
                <g data-name="pie-chart (1)">
                    <g data-name="Groupe 9802">
                        <g data-name="Groupe 9801">
                            <path
                                data-name="Trac\xE9 9380"
                                d="M104.992 48.82a51.855 51.855 0 00-13.723-31.932 1.712 1.712 0 00-2.52 2.319 48.43 48.43 0 0112.669 28.016H74.83a22.853 22.853 0 00-17.134-17.071V3.575a48.749 48.749 0 0123.54 8.956 1.712 1.712 0 002-2.776A52.2 52.2 0 0056.097.004a1.712 1.712 0 00-1.825 1.708v29.847a1.712 1.712 0 001.436 1.69 19.429 19.429 0 0116.032 15.97 1.711 1.711 0 001.688 1.429h29.856a1.712 1.712 0 001.708-1.828z"
                                fill="#2a3042"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 9804">
                        <g data-name="Groupe 9803">
                            <path
                                data-name="Trac\xE9 9381"
                                d="M104.542 54.615a1.712 1.712 0 00-1.249-.541H73.454a1.712 1.712 0 00-1.691 1.442 19.457 19.457 0 01-3.381 8.22 1.712 1.712 0 00.181 2.207l21.116 21.116a1.713 1.713 0 001.211.5h.057a1.713 1.713 0 001.232-.585 51.913 51.913 0 0012.818-31.077 1.713 1.713 0 00-.455-1.282zm-13.76 28.7l-18.8-18.8a22.951 22.951 0 002.889-7.017h26.568a48.432 48.432 0 01-10.657 25.821z"
                                fill="#2a3042"
                            />
                        </g>
                    </g>
                    <g data-name="Groupe 9806">
                        <g data-name="Groupe 9805">
                            <path
                                data-name="Trac\xE9 9382"
                                d="M87.261 89.488L66.153 68.379a1.712 1.712 0 00-2.215-.176 19.445 19.445 0 11-14.529-34.954 1.712 1.712 0 001.436-1.69V1.712A1.712 1.712 0 0049.02.004a52.558 52.558 0 1038.16 91.984 1.712 1.712 0 00.081-2.5zm-34.7 12.089a49.135 49.135 0 01-5.137-98v26.576a22.873 22.873 0 005.137 45.162A22.669 22.669 0 0064.734 71.8l18.789 18.788a49.032 49.032 0 01-30.965 10.987z"
                                fill="#2a3042"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
