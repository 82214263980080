import React from "react";
import { useResource } from "@hooks/useResource";
import { usePagination, useTable, useGlobalFilter, Cell } from "react-table";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import BTable from "react-bootstrap/Table";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useKeycloak } from "@react-keycloak/web";

import store, {
    addCustomersCitiesSelectionItem,
    addProductsSelectionItem,
    addRegulationModesSelectionItem,
    addSalesModeSelectionItem,
    addSalesSupportSelectionItem,
    clearSelectedSegments,
    setAvgBasketRange,
    setSegmentName,
    setSegments,
    setSelectedSegments,
} from "../store";

import {
    capitalize,
    getAdvancedProductsAvgBasketRangeArray,
} from "@helpers/general";
import { fetcherWithToken } from "@helpers/fetcherWithToken";

import { ADVANCED_ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import Pagination from "@components/Common/Table/Pagination";
import { toggleNav, toggleNewSegmentModal } from "../Header/store";

export default function SavedSegments() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const advancedStore = useSnapshot(store);

    const [data, setData] = React.useState<any[]>([]);

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/segments`
    );

    const handleRemoveSelectedSegment = React.useCallback(
        async (segment: string) => {
            swal({
                text: t("Are you sure you want to delete this segment?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Delete")],
                dangerMode: true,
            }).then(async (willDelete: any) => {
                if (willDelete) {
                    try {
                        await fetch(
                            `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/segment`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${keycloak.token}`,
                                    "x-authorization": `Bearer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify({ designation: segment }),
                                method: "DELETE",
                            }
                        )
                            .then((response) => response.json())
                            .then((response) => {
                                NProgress.done();

                                if (response.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }

                                setData(
                                    data.filter(
                                        (datum: string) => datum !== segment
                                    )
                                );

                                return swal({
                                    icon: "success",
                                    content: (
                                        <p>
                                            {t(capitalize(response.message))}!
                                        </p>
                                    ),
                                    buttons: false,
                                    timer: 2000,
                                });
                            });
                    } catch (error) {
                        NProgress.done();

                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
            });
        },
        [t, data, keycloak.token]
    );

    const handleEditSegmentOnClickEvent = React.useCallback(
        async (segment: string) => {
            const apiResult = await fetcherWithToken(
                `${process.env.REACT_APP_API_V1_URL}/statistics/transactions/segment?segment-name=${segment}`
            );
            setSegmentName(apiResult.data.designation);
            apiResult.data.value.forEach((item: any) => {
                if (item.designation === "sales_mode") {
                    item.value.forEach((datum: string) => {
                        addSalesModeSelectionItem(datum);
                    });
                } else if (item.designation === "sales_support") {
                    item.value.forEach((datum: string) => {
                        addSalesSupportSelectionItem(datum);
                    });
                } else if (item.designation === "regulations") {
                    item.value.forEach((datum: string) => {
                        addRegulationModesSelectionItem(datum);
                    });
                } else if (item.designation === "articles") {
                    if (item.value[0] === "all") {
                        advancedStore.allProducts.forEach((product) => {
                            addProductsSelectionItem(product);
                        });
                    } else {
                        let localAvgBasketArray: any[] = [];
                        advancedStore.allProducts.forEach((product) => {
                            if (
                                item.value.includes(
                                    `${product.reference}|${product.designation}|${product.families}|${product.sub_families}`
                                ) ||
                                item.value.includes(product.designation)
                            ) {
                                addProductsSelectionItem(product);
                                localAvgBasketArray.push(
                                    product.average_basket
                                );
                            }
                        });
                        setAvgBasketRange(
                            getAdvancedProductsAvgBasketRangeArray(
                                localAvgBasketArray
                            )
                        );
                    }
                } else if (item.designation === "customer_city") {
                    item.value.forEach((datum: string) => {
                        addCustomersCitiesSelectionItem(datum);
                    });
                }
            });

            toggleNav(1);
        },
        [advancedStore.allProducts]
    );

    const handleSelectedSegment = React.useCallback(async (segment: string) => {
        clearSelectedSegments();
        setSelectedSegments(segment);
        toggleNewSegmentModal();
    }, []);

    const columns: AdvancedTableColumnsType[] = React.useMemo(() => {
        function DropdownActionsWrapper({ segment }: any) {
            const { t } = useTranslation();
            const [isDropdownOpened, setIsDropdownOpened] = React.useState<
                boolean
            >(false);

            return (
                <Dropdown
                    style={{ cursor: "pointer" }}
                    isOpen={isDropdownOpened}
                    tag="div"
                    toggle={() =>
                        setIsDropdownOpened((prevState: boolean) => !prevState)
                    }
                    direction="left"
                    className="mr-3"
                >
                    <DropdownToggle tag="div" className="align-middle">
                        <i className="bx bx-dots-vertical-rounded font-size-20"></i>
                    </DropdownToggle>
                    <DropdownMenu className="drop-down-menu__clz">
                        <DropdownItem
                            onClick={() => handleRemoveSelectedSegment(segment)}
                        >
                            {t("Delete")}
                        </DropdownItem>
                        <DropdownItem
                            onClick={() =>
                                handleEditSegmentOnClickEvent(segment)
                            }
                        >
                            {t("Edit")}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            );
        }

        return [
            {
                Header: t("Designation"),
                accessor: function (segment: any) {
                    return segment;
                },
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (segment: any) {
                    return (
                        <div
                            className="d-flex justify-content-end align-items-center"
                            style={{ gap: "20px" }}
                        >
                            <StyledButton
                                rounded
                                outline
                                variant="secondary"
                                onClick={() => handleSelectedSegment(segment)}
                            >
                                {t("Select")}
                            </StyledButton>
                            <DropdownActionsWrapper segment={segment} />
                        </div>
                    );
                },
            },
        ];
    }, [
        t,
        handleRemoveSelectedSegment,
        handleEditSegmentOnClickEvent,
        handleSelectedSegment,
    ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination
    );

    React.useEffect(() => {
        setPageSize(ADVANCED_ITEMS_PER_PAGE);
    }, [setPageSize]);

    React.useEffect(() => {
        setData(resourceData.data);
        setSegments(resourceData.data);
    }, [resourceData]);

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <React.Fragment>
                    <div
                        className="d-md-flex justify-content-md-between justify-content-center"
                        style={{
                            backgroundColor: "rgb(249, 249, 249)",
                            borderTopRightRadius: "calc(0.3rem - 1px)",
                        }}
                    >
                        <h4 className="mx-3 py-2 mb-0 align-self-center white-space-nowrap">
                            {t("Saved filters")}
                        </h4>
                        <div className="app-search m-2 p-0">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control w-xs-90"
                                    placeholder={`${t("Search")} ...`}
                                    onChange={(e) => {
                                        setGlobalFilter(e.target.value);
                                    }}
                                />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </div>
                    </div>

                    <div className="ml-2">
                        <BTable responsive {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                            return (
                                                <th
                                                    {...column.getHeaderProps()}
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);

                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(
                                                (cell: Cell<object, any>) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap py-1 align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </BTable>
                    </div>
                    {resourceData.data.length > ADVANCED_ITEMS_PER_PAGE ? (
                        <Pagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                        />
                    ) : null}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
