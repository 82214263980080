import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { setCity } from "./store";

type PropsType = {
    options: string[];
};

export default function CitiesSelect({ options }: PropsType) {
    const { t } = useTranslation();

    let localOptions: LocalOptionsType[] = options.map((option: string) => ({
        label: option,
        value: option,
    }));

    const [selectedValueState, setSelectedValueState] = React.useState<any[]>(
        []
    );

    function handleSelectOnChangeEvent(selectedValue: any) {
        setSelectedValueState(selectedValue);
        setCity(selectedValue.value);
    }

    return (
        <Select
            isSearchable
            options={localOptions}
            value={selectedValueState}
            onChange={handleSelectOnChangeEvent}
            placeholder={t("Select a city")}
            styles={{
                control: (provided: any) => ({
                    ...provided,
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderRadius: "0px",
                    borderColor: "unset",
                    boxShadow: "unset",
                }),
                indicatorSeparator: (provided: any) => ({
                    ...provided,
                    display: "none",
                }),
                placeholder: (provider: any) => ({
                    ...provider,
                    fontSize: "0.8125rem",
                }),
                valueContainer: (provider: any) => ({
                    ...provider,
                    fontSize: "15px",
                    paddingLeft: 0,
                    paddingRight: 0,
                }),
                singleValue: (provider: any) => ({
                    ...provider,
                    color: "black",
                    paddingLeft: "0.75rem",
                }),
                option: (provider: any) => ({
                    ...provider,
                    fontSize: "15px",
                }),
            }}
        />
    );
}
