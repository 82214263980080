import React from "react";

export default function InDeliverySvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8795">
                <g data-name="Groupe 8760">
                    <g data-name="Mask group">
                        <path
                            data-name="icon bg"
                            d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                            fill="#333"
                        />
                        <g data-name="Mask group" clipPath="url(#prefix__a)">
                            <path
                                data-name="Rectangle 2"
                                d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                                fill="rgba(255,255,255,0.1)"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="Groupe 8383">
                    <g data-name="Groupe 8382">
                        <path
                            data-name="Trac\xE9 8572"
                            d="M39.713 30.093c-.665-2.661-1.877-4.125-3.2-4.894a2.7 2.7 0 00.422-2.569h.155a2.71 2.71 0 002.706-2.706v-7.215a2.71 2.71 0 00-2.706-2.706h-7.22a2.71 2.71 0 00-2.707 2.706v7.217a2.691 2.691 0 00.191 1 2.711 2.711 0 00-2 2.611v1.8a.9.9 0 00.264.638 4.206 4.206 0 01.241 5.677H21.63v-9.924a4.487 4.487 0 00-.9-2.706h1.807a.9.9 0 100-1.8h-2.711v-.9a2.71 2.71 0 00-2.707-2.713h-1.8a.9.9 0 00-.9.9v3.609a.9.9 0 00.9.9h.581l-1.665 5.509A6.325 6.325 0 009 30.75v1.8a4.511 4.511 0 004.5 4.513 4.363 4.363 0 004.433-3.611h9.318a4.511 4.511 0 008.84 0h.993a2.706 2.706 0 002.629-3.359zM16.217 15.409h.9a.9.9 0 01.9.9v.9h-1.8v-1.8zm-1.33 19.518a2.742 2.742 0 01-4.087-2.373 2.71 2.71 0 012.71-2.707 2.71 2.71 0 012.706 2.706 2.592 2.592 0 01-1.329 2.374zm4.939-3.275h-1.9a4.518 4.518 0 00-4.42-3.609 4.482 4.482 0 00-2.156.55 4.51 4.51 0 018.472 2.156zm0-5.317a6.3 6.3 0 00-3.694-1.848l1.633-5.388a2.711 2.711 0 012.061 2.629zm9.142-13.626a.9.9 0 01.9-.9h7.217a.9.9 0 01.9.9v.9h-9.017zm0 2.706h9.022v4.511a.9.9 0 01-.9.9h-7.22a.9.9 0 01-.9-.9v-4.517zm-1.8 9.022v-.9a.9.9 0 01.9-.9h6.315a.9.9 0 010 1.8h-7.22zm4.511 10.826a2.722 2.722 0 01-2.551-1.816h5.1a2.721 2.721 0 01-2.554 1.813zm5.413-3.609h-9.078a6.034 6.034 0 000-5.413h5.478c2.286 0 3.748 1.4 4.47 4.292a.9.9 0 01-.875 1.119z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8385">
                    <g data-name="Groupe 8384">
                        <circle
                            data-name="Ellipse 635"
                            cx={0.902}
                            cy={0.902}
                            r={0.902}
                            fill="#fff"
                            transform="translate(12.609 31.652)"
                        />
                    </g>
                </g>
                <g data-name="Groupe 8387">
                    <g data-name="Groupe 8386">
                        <path
                            data-name="Trac\xE9 8573"
                            d="M33.478 28.043h-1.8a.9.9 0 000 1.8h1.8a.9.9 0 100-1.8z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
