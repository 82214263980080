import React from "react";
import { Progress } from "reactstrap";
import { useTranslation } from "react-i18next";

import {
    measurePasswordStrengthAsNumber,
    getPasswordStrength,
} from "@helpers/general";

type PropsType = {
    password: string;
};

export default function PasswordStrengthMeter({ password }: PropsType) {
    const { t } = useTranslation();

    let passwordStrength = getPasswordStrength(
        measurePasswordStrengthAsNumber(password)
    );

    return (
        <div className="mt-1">
            <Progress
                value={measurePasswordStrengthAsNumber(password)}
                color={passwordStrength.color}
                max="4"
            />
            <label>
                {password ? (
                    <div>
                        <span>{t("Security level")}: </span>
                        <span className={`text-${passwordStrength.color}`}>
                            {passwordStrength.strength}
                        </span>
                    </div>
                ) : null}
                <small>
                    {t(
                        "For optimal security of your password, we recommend a minimum of 8 characters, composed of at least one lower case, one upper case, one number (0-9) and punctuation"
                    )}
                </small>
            </label>
        </div>
    );
}
