import React from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "@components/Common/PageTitle";
import moment from "moment";
import i18n from "i18next";

import Breadcrumb from "@components/Common/GlobalBreadcrumb";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import Cards from "./Cards";
import SalesModeRow from "./SalesModeRow";
import SalesSupportRow from "./SalesSupportRow";
import ServicesRow from "./ServicesRow";
import RecapRow from "./RecapRow";
import SelectFilter from "./SelectFilter";
import CardSpinnerWrapper from "@components/Common/CardSpinnerWrapper";

import "@assets/grid.css";

export default function StatisticsRegulationModes() {
    const { t } = useTranslation();

    moment.locale(i18n.language);

    return (
        <React.Fragment>
            <PageTitle title={t("Payment methods")} />
            <div className="page-content">
                <Breadcrumb
                    section={t("Statistics")}
                    pageTitle={t("Payment methods")}
                />
                <React.Suspense fallback={<CardSpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <SelectFilter />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <Cards />
                    </CustomErrorBoundary>
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <RecapRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesModeRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <SalesSupportRow />
                </React.Suspense>

                <React.Suspense fallback={<SpinnerWrapper />}>
                    <ServicesRow />
                </React.Suspense>
            </div>
        </React.Fragment>
    );
}
