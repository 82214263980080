import React from "react";
import { useTranslation } from "react-i18next";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import {
    StyledButton,
    StyledIconButton,
    AddIcon,
    AngleSmallLeftIcon,
    StyledSearchInput,
} from "@aureskonnect/react-ui";

import { store as vStore } from "@vStore";
import store from "./store";

import { useResource } from "@hooks/useResource";

import { keycloak } from "@keycloak";

import { ITEMS_PER_PAGE } from "@constants/index";

import ErrorPage from "@components/Common/ErrorPage";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import CustomSelect from "./CustomSelect";
import { NoImagePlaceholderIcon } from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

import "@assets/widths.css";

export default function AddArticleEditStep({ go }: any) {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(vStore);
    const { groupedArticles, groupName } = useSnapshot(store);

    const { resourceData, error } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/products`
    );

    const [familiesSelectedValue, setFamiliesSelectedValue] = React.useState<
        any[]
    >([]);
    const [filteredProducts, setFilteredProducts] = React.useState<any[]>([]);
    const [selectedArticles, setSelectedArticles] = React.useState<any[]>([]);
    const [families, setFamilies] = React.useState<any[]>([]);
    const [subFamilies, setSubFamilies] = React.useState<any[]>([]);
    const [
        subFamiliesSelectedValue,
        setSubFamiliesSelectedValue,
    ] = React.useState<any[]>([]);

    React.useEffect(() => {
        let products: any = resourceData.data!;
        let localFamilies: string[] = [];
        let localSubFamilies: string[] = [];

        let localArticles: any[] = products.filter((article: any) => {
            return !groupedArticles
                .map((articleGroup: any) =>
                    `${articleGroup.reference}|${articleGroup.designation}|${articleGroup.family}|${articleGroup.subFamily}`.toLowerCase()
                )
                .includes(
                    `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                );
        });

        localArticles.forEach((article: any) => {
            if (article.family !== "") {
                localFamilies.push(article.family);
            }

            if (article.subFamily !== "") {
                localSubFamilies.push(article.subFamily);
            }
        });

        if (
            (familiesSelectedValue === null ||
                familiesSelectedValue!.length === 0) &&
            (subFamiliesSelectedValue === null ||
                subFamiliesSelectedValue!.length === 0)
        ) {
            setFilteredProducts(localArticles);
        }

        setFamilies([...new Set(localFamilies)]);
        setSubFamilies([...new Set(localSubFamilies)]);
    }, [
        resourceData,
        familiesSelectedValue,
        groupedArticles,
        subFamiliesSelectedValue,
    ]);

    const handleAddingArticleButtonOnClickEvent = React.useCallback(
        async (article: any) => {
            swal({
                text: t("Are you sure you want to add this article?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Add")],
                dangerMode: true,
            }).then(async (willDelete: any) => {
                if (willDelete) {
                    let localSelectedArticles: any[] = [
                        ...selectedArticles,
                        article,
                    ];

                    setSelectedArticles(localSelectedArticles);
                    let localArticles: any[] = filteredProducts.filter(
                        (article: any) => {
                            return !localSelectedArticles
                                .map((s) =>
                                    `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                                )
                                .includes(
                                    `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                                );
                        }
                    );

                    setFilteredProducts(localArticles);
                    let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group`;
                    let savedData = {
                        designation: groupName,
                        groupment: [
                            ...groupedArticles,
                            ...localSelectedArticles,
                        ].map((s) => {
                            return `${s.reference}|${s.designation}${
                                s.family.length > 0 ? `|${s.family}` : ""
                            }${
                                s.subFamily.length > 0 ? `|${s.subFamily}` : ""
                            }`;
                        }),
                    };
                    try {
                        NProgress.start();
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${keycloak.token}`,
                                    "x-authorization": `Bearer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "PUT",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    NProgress.done();
                                    if (data.error) {
                                        throw Error(
                                            "Error while deleting an article in an articles group!"
                                        );
                                    }
                                    mutate(
                                        `${process.env.REACT_APP_API_V1_URL}/settings/products/group?group-name=${groupName}`
                                    );
                                    return swal({
                                        icon: "success",
                                        content: <p>{t(data.message)}!</p>,
                                        buttons: false,
                                        timer: 2000,
                                    });
                                })
                        );
                    } catch (e: any) {
                        NProgress.done();
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
            });
        },
        [filteredProducts, selectedArticles, groupName, t, groupedArticles]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Product"),
                accessor: "designation",
            },
            {
                Header: t("Picture"),
                accessor: function (article: any) {
                    return (
                        <div>
                            {article.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                article.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Families"),
                accessor: "family",
                disableGlobalFilter: true,
            },
            {
                Header: t("Sub-families"),
                accessor: "subFamily",
                disableGlobalFilter: true,
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (article: any) {
                    return (
                        <div>
                            <StyledButton
                                rounded={true}
                                outline={true}
                                variant={mainColor === null ? "secondary" : ""}
                                className="d-none d-sm-none d-md-block white-space-nowrap"
                                style={{
                                    color: mainColor,
                                    borderColor: mainColor,
                                    float: "right",
                                }}
                                onClick={() =>
                                    handleAddingArticleButtonOnClickEvent(
                                        article
                                    )
                                }
                            >
                                {t("Add")}
                            </StyledButton>
                            <StyledIconButton
                                icon="AddIcon"
                                className="d-block d-sm-block d-md-none m-0"
                                onClick={() =>
                                    handleAddingArticleButtonOnClickEvent(
                                        article
                                    )
                                }
                            >
                                <AddIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleAddingArticleButtonOnClickEvent, mainColor]
    );

    let localFamilies: any[] = families.map((family: string) => ({
        label: family,
        value: family,
    }));

    let localSubFamilies: any[] = subFamilies.map((subFamily: string) => ({
        label: subFamily,
        value: subFamily,
    }));

    function handleFamiliesSelectOnChangeEvent(
        familiesSelectedValue: any,
        action: any
    ) {
        setFamiliesSelectedValue(familiesSelectedValue);
        setSubFamiliesSelectedValue([]);
        let filteredArticlesByFamilies: any[] = [];
        if (action.action === "clear") {
            setFilteredProducts(
                filteredProducts.filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                })
            );
        } else if (familiesSelectedValue) {
            familiesSelectedValue!.forEach((item: any) => {
                let localArticles = filteredProducts.filter(function (
                    article: any
                ) {
                    return article.family === item.value;
                });
                filteredArticlesByFamilies = [
                    ...filteredArticlesByFamilies,
                    ...localArticles,
                ].filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                });
            });
            setFilteredProducts(filteredArticlesByFamilies);
        }
    }

    function handleSubFamiliesSelectOnChangeEvent(
        subFamiliesSelectedValue: any,
        action: any
    ) {
        setFamiliesSelectedValue([]);
        setSubFamiliesSelectedValue(subFamiliesSelectedValue);
        let filteredArticlesBySubFamilies: any[] = [];

        if (action.action === "clear") {
            setFilteredProducts(
                filteredProducts.filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                })
            );
        } else if (subFamiliesSelectedValue) {
            subFamiliesSelectedValue!.forEach((item: any) => {
                let localArticles = filteredProducts.filter(function (
                    article: any
                ) {
                    return article.subFamily === item.value;
                });
                filteredArticlesBySubFamilies = [
                    ...filteredArticlesBySubFamilies,
                    ...localArticles,
                ].filter((article: any) => {
                    return !selectedArticles
                        .map((s) =>
                            `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                        )
                        .includes(
                            `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                        );
                });
            });
            setFilteredProducts(filteredArticlesBySubFamilies);
        }
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: filteredProducts,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    function handleSelectionListButtonOnClickEvent() {
        go(2);
    }

    function handleMultipleAddingArticlesButtonOnClickEvent() {
        swal({
            text: t(
                "Are you sure you want to add the selected element to the group?"
            ),
            icon: "warning",
            buttons: [t("Cancel"), t("Add")],
            dangerMode: true,
        }).then(async (willDelete: any) => {
            if (willDelete) {
                let localSelectedArticles: any[] = [
                    ...selectedArticles,
                    ...selectedFlatRows.map((row: any) => row.original),
                ];
                setSelectedArticles(localSelectedArticles);
                let localArticles: any[] = filteredProducts.filter(
                    (article) => {
                        return !localSelectedArticles
                            .map((s) =>
                                `${s.reference}|${s.designation}|${s.family}|${s.subFamily}`.toLowerCase()
                            )
                            .includes(
                                `${article.reference}|${article.designation}|${article.family}|${article.subFamily}`.toLowerCase()
                            );
                    }
                );
                setFilteredProducts(localArticles);

                let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/products/group`;
                let savedData = {
                    designation: groupName,
                    groupment: [
                        ...groupedArticles,
                        ...localSelectedArticles,
                    ].map((s) => {
                        return `${s.reference}|${s.designation}${
                            s.family.length > 0 ? `|${s.family}` : ""
                        }${s.subFamily.length > 0 ? `|${s.subFamily}` : ""}`;
                    }),
                };

                try {
                    NProgress.start();

                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${keycloak.token}`,
                                "x-authorization": `Bearer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                NProgress.done();

                                if (data.error) {
                                    throw Error(
                                        "Error while adding multiple articles in an articles group!"
                                    );
                                }

                                mutate(
                                    `${process.env.REACT_APP_API_V1_URL}/settings/products/group?group-name=${groupName}`
                                );

                                return swal({
                                    icon: "success",
                                    content: <p>{t(data.message)}!</p>,
                                    buttons: false,
                                    timer: 2000,
                                });
                            })
                    );
                } catch (e: any) {
                    NProgress.done();
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            }
        });
    }

    function handlePreviousStepAction() {
        go(2);
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    return (
        <React.Fragment>
            <div className="d-md-flex justify-content-md-between align-items-center">
                <div className="d-md-flex align-items-center mt-2 mt-md-2 mb-2">
                    <div className="d-flex align-items-center">
                        <StyledIconButton
                            icon="AngleSmallLeftIcon"
                            className="m-0 p-0"
                            onClick={handlePreviousStepAction}
                        >
                            <AngleSmallLeftIcon height={35} width={35} />
                        </StyledIconButton>
                        <span className="mt-md-2 mb-2 mx-2 h5 white-space-nowrap">
                            {groupName}
                        </span>
                    </div>
                    <span
                        className="divider mt-0 mx-4 d-none d-md-block"
                        style={{
                            backgroundColor: "#808080a6",
                            height: "2.25rem",
                            width: "2px",
                        }}
                    />

                    <StyledSearchInput
                        onChange={(e) => {
                            setGlobalFilter(e.target.value);
                        }}
                        placeholder={`${t("Search")} ...`}
                        className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                    />
                    <CustomSelect
                        options={localFamilies}
                        placeholder={t("Families")}
                        handleOnChangeEvent={handleFamiliesSelectOnChangeEvent}
                        selectedValue={familiesSelectedValue}
                    />
                    <CustomSelect
                        options={localSubFamilies}
                        placeholder={t("Sub-families")}
                        handleOnChangeEvent={
                            handleSubFamiliesSelectOnChangeEvent
                        }
                        selectedValue={subFamiliesSelectedValue}
                    />
                </div>
                <div className="d-md-flex mt-2 mx-2 mb-2">
                    <StyledButton
                        rounded={true}
                        variant={mainColor === null ? "secondary" : ""}
                        className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                        style={{
                            background: mainColor,
                        }}
                        disabled={selectedFlatRows.length === 0}
                        onClick={handleMultipleAddingArticlesButtonOnClickEvent}
                    >
                        {t("Add articles")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        variant={mainColor === null ? "secondary" : ""}
                        className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                        style={{
                            background: mainColor,
                        }}
                        disabled={
                            selectedArticles.length + groupedArticles.length ===
                            0
                        }
                        onClick={handleSelectionListButtonOnClickEvent}
                    >
                        {`${t("My selection")} (${
                            selectedArticles.length + groupedArticles.length
                        })`}
                    </StyledButton>
                </div>
            </div>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <BTable responsive {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="white-space-nowrap"
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="white-space-nowrap align-middle"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </BTable>
                    {filteredProducts.length > ITEMS_PER_PAGE ? (
                        <Pagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            pageIndex={pageIndex}
                        />
                    ) : null}
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
