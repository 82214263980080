import React from "react";
import { useTranslation } from "react-i18next";

type PropsType = {
    globalFilter: any;
    setGlobalFilter: (filterValue: any) => void;
};

export default function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}: PropsType) {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(globalFilter);

    const handleOnChangeEvent = (value: any) => {
        setGlobalFilter!(value || undefined);
    };

    return (
        <input
            value={value || ""}
            onChange={(e) => {
                setValue(e.target.value);
                handleOnChangeEvent(e.target.value);
            }}
            placeholder={`${t("Search")} ...`}
            className="form-control"
        />
    );
}
