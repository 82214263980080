import { proxy } from "valtio";

type SettingsFamiliesStoreType = {
    groupName: string;
    groupedFamilies: any[];
};

const store = proxy<SettingsFamiliesStoreType>({
    groupName: "",
    groupedFamilies: [],
});

export function setGroupName(groupName: string) {
    store.groupName = groupName;
}

export function setGroupedFamilies(groupedFamilies: any[]) {
    store.groupedFamilies = groupedFamilies;
}

export default store;
