import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem } from "reactstrap";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import classnames from "classnames";
import { SketchPicker, ColorResult } from "react-color";
import { useSelector } from "react-redux";
import swal from "@sweetalert/with-react";
import { useKeycloak } from "@react-keycloak/web";

import { setMainColor, store } from "@vStore";

import { decryptWord } from "@helpers/general";

import {
    ColorSvgIcon,
    MagicSvgIcon,
    RestoreSvgIcon,
} from "@components/Common/SvgIcons";

export function AppearanceDropdownItemWrapper() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const defaultMainColor = "#171616";

    const { user } = useSelector(
        (state: {
            Login: {
                user: UserType;
            };
        }) => state.Login
    );

    const sketchPickerRef = React.useRef(null);

    const { mainColor, secondaryColor } = useSnapshot(store);

    const [email, setEmail] = React.useState<string>("");
    const [isAppearanceModified] = React.useState(true);
    const [isMainColorPickerShown, setMainColorPickerShown] = React.useState(
        false
    );
    const [, setSecondaryColorPickerShown] = React.useState(false);

    function handleChangeMainColor() {
        setMainColorPickerShown((prevState) => !prevState);
        setSecondaryColorPickerShown(false);
    }

    async function handleColorRequest(
        email: string,
        mainColor: string,
        secondaryColor: string
    ) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_ADMIN_API_URL}/userColor`;

        await fetch(apiUrl, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: `Bearer ${keycloak.token}`,
            },
            body: `email=${email}&mainColor=${mainColor}&secondaryColor=${secondaryColor}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then((response) => {
                if (response.error === true) {
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            })
            .catch((_) => {
                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            });
    }

    function handleResetColor() {
        localStorage.setItem("mainColor", defaultMainColor);
        setMainColor(defaultMainColor);
        handleColorRequest(email, defaultMainColor, secondaryColor);
    }

    React.useEffect(() => {
        if (user !== undefined) {
            setEmail(keycloak.tokenParsed?.email);
        } else {
            decryptWord((user as UserType).email as string, setEmail);
        }
    }, [user, keycloak]);

    return (
        <React.Fragment>
            <DropdownItem
                className={classnames("pl-4", {
                    "d-none": isAppearanceModified === false,
                })}
                style={{ pointerEvents: "none" }}
            >
                <MagicSvgIcon
                    className="mr-2"
                    height="25"
                    width="25"
                    fill="black"
                />

                <span>{t("Appearance")}</span>
            </DropdownItem>
            <DropdownItem
                className={classnames("dropdown-item pl-5", {
                    "d-none": isAppearanceModified === false,
                })}
                toggle={false}
                onBlur={() => setMainColorPickerShown(false)}
            >
                <div className="d-flex align-items-center">
                    <span
                        style={{ pointerEvents: "none", cursor: "default" }}
                        className="ml-3 mr-3"
                    >
                        {t("Main color")}
                    </span>
                    <div
                        style={{
                            borderRadius: "3px",
                            width: "60px",
                            height: "20px",
                            backgroundColor: mainColor,
                            marginRight: "10px",
                        }}
                    />

                    <Tooltip
                        className="pick-main-color__clz"
                        title={t("Pick main color")}
                    >
                        <span
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <ColorSvgIcon
                                onClick={handleChangeMainColor}
                                height="20px"
                                width="20px"
                                color={mainColor}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip
                        className="restore-main-color__clz"
                        title={t("Restore main color")}
                    >
                        <span onClick={handleResetColor} className="pl-3">
                            <RestoreSvgIcon
                                height="20px"
                                width="20px"
                                stroke={mainColor}
                            />
                        </span>
                    </Tooltip>
                    {isMainColorPickerShown === true ? (
                        <div
                            style={{
                                position: "fixed",
                                top: "85%",
                                right: "65%",
                            }}
                            ref={sketchPickerRef}
                        >
                            <SketchPicker
                                color={mainColor}
                                onChange={(color: ColorResult) => {
                                    localStorage.setItem(
                                        "mainColor",
                                        color.hex
                                    );
                                    setMainColor(color.hex);
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </DropdownItem>
        </React.Fragment>
    );
}
