import { proxy } from "valtio";

type SettingsSubFamiliesStoreType = {
    groupName: string;
    groupedSubFamilies: any[];
};

const store = proxy<SettingsSubFamiliesStoreType>({
    groupName: "",
    groupedSubFamilies: [],
});

export function setGroupName(groupName: string) {
    store.groupName = groupName;
}

export function setGroupedSubFamilies(groupedSubFamilies: any[]) {
    store.groupedSubFamilies = groupedSubFamilies;
}

export default store;
