export const selectSubFamiliesGroupDesignation = (
    subFamiliesGroupDesignation: any | undefined
): {
    type: string;
    payload: any | undefined;
} => {
    return {
        type: "SUBFAMILIES_GROUP_DESIGNATION",
        payload: subFamiliesGroupDesignation,
    };
};
