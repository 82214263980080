import React from "react";
import Modali, { useModali } from "modali";
import { Button } from "reactstrap";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";

import ModalContent from "./ModalContent";

type ModalWrapperType = {
    order: OrderType;
    buttonText: string;
    buttonProps: any;
    buttonOnClickEvent?: Function;
};

export default function ModalWrapper({
    order,
    buttonText,
    buttonProps,
    buttonOnClickEvent,
}: ModalWrapperType & any) {
    const [orderModal, toggleOrderModal] = useModali({
        animated: true,
        large: false,
        closeButton: false,
    });

    return (
        <React.Fragment>
            <Button
                onClick={
                    buttonOnClickEvent ? buttonOnClickEvent : toggleOrderModal
                }
                {...buttonProps}
            >
                {buttonText}
            </Button>
            <Modali.Modal {...orderModal}>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <ModalContent
                            order={order}
                            toggleOrderModal={toggleOrderModal}
                        />
                    </CustomErrorBoundary>
                </React.Suspense>
            </Modali.Modal>
        </React.Fragment>
    );
}
