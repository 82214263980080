import React from "react";

export const DeliverySvgIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <filter
                id="Rectangle_13561"
                x={0}
                y={0}
                width={342}
                height={342}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.161} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_13571"
                    data-name="Rectangle 13571"
                    width={160.082}
                    height={135.632}
                    fill="none"
                />
            </clipPath>
        </defs>
        <g
            id="Groupe_1027"
            data-name="Groupe 1027"
            transform="translate(-5049 6583)"
        >
            <g
                transform="matrix(1, 0, 0, 1, 5049, -6583)"
                filter="url(#Rectangle_13561)"
            >
                <rect
                    id="Rectangle_13561-2"
                    data-name="Rectangle 13561"
                    width={324}
                    height={324}
                    rx={50}
                    transform="translate(9 6)"
                    fill="#f8f8f6"
                />
            </g>
            <g
                id="Groupe_1004"
                data-name="Groupe 1004"
                transform="translate(5139.959 -6482.816)"
            >
                <g
                    id="Groupe_1003"
                    data-name="Groupe 1003"
                    transform="translate(0 0)"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="Trac\xE9_1394"
                        data-name="Trac\xE9 1394"
                        d="M10.533,88.668c-1.081-.029-2.16-.076-3.24-.087-7.065-.076-8.63-2.152-6.263-8.672C2.173,76.756,3.666,73.73,5,70.643A22.563,22.563,0,0,0,7.319,68.4,42.286,42.286,0,0,1,24.773,55.285C44.9,46.924,61.6,56.3,64.663,77.834c.342,2.4,1.68,2.014,3.1,2.016,11.877.017,23.755-.046,35.63.062,2.135.019,3.112-.765,3.9-2.639a26.868,26.868,0,0,1,8.455-10.833c7.6-5.949,16.158-6.587,25.2-4.848,8.613,3.41,15.035,9.056,18.083,18.016,2.345,6.89.981,8.77-6.331,9.058l-8.341-.012-23.19,0-8.587.022c-6.417-.294-6.887-.653-6.941-6.519-.017-1.772-1-1.612-2.077-1.612-12.157-.008-24.314.025-36.47-.04-1.7-.009-2.608.591-2.735,2.211-.393,5.042-3.485,6.282-7.945,5.893a55.352,55.352,0,0,0-5.731.065l-8.6-.017-23.236-.011-8.316.02M26.82,64.184a3.912,3.912,0,0,0-.694-1.226c-1.921-1.689-3.63-.418-5.076.709-1.783,1.389-4.766,1.961-4.288,5.178a2.54,2.54,0,0,0,1.285,1.477c1.718.921,8.852-3.951,8.773-6.138"
                        transform="translate(0 28.636)"
                        fill="#ca9368"
                    />
                    <path
                        id="Trac\xE9_1395"
                        data-name="Trac\xE9 1395"
                        d="M139.215,74.262c-9.045-1.738-17.6-1.1-25.2,4.85a26.863,26.863,0,0,0-8.455,10.832c-.788,1.875-1.765,2.66-3.9,2.639-11.875-.107-23.753-.045-35.63-.06-1.418,0-2.756.387-3.1-2.016C59.863,68.975,43.169,59.6,23.039,67.958A42.272,42.272,0,0,0,5.586,81.073a22.737,22.737,0,0,1-2.321,2.243C2.337,68.468,7.843,56.845,20.571,48.849q23.683.014,47.367.04c1.6,0,3.276-.376,4.732.681,1.712,4.169.545,8-1.42,11.716a13.2,13.2,0,0,0-.878,11.4c1.978,5.146,5.033,7.316,10.536,7.3,7.579-.025,15.239.774,22.284-3.339a26.5,26.5,0,0,0,12.9-26.249c-.876-6.335-1.605-12.688-2.4-19.029.155-1.139-.034-2.618,1.9-2.15,4.028.975,8.35.327,12.209,2.217a141.826,141.826,0,0,1,11.414,42.83"
                        transform="translate(1.735 15.962)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1396"
                        data-name="Trac\xE9 1396"
                        d="M20.676,45.883c-5.184-.034-10.369-.15-15.552-.059-1.934.034-2.565-.5-2.54-2.5.133-10.366.121-20.734.159-31.1l41.616-.006q.053,16.756.108,33.512l-23.792.152"
                        transform="translate(1.416 6.696)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1397"
                        data-name="Trac\xE9 1397"
                        d="M15.691,34.019l23.792-.152c8.059.015,16.118.07,24.177.034,3.811-.015,7.508-.031,8.969,4.559,1.426,4.48-1.785,6.383-4.624,8.516-1.458-1.06-3.136-.681-4.734-.684q-23.685-.044-47.369-.04c-5.561-3.937-5.629-7.791-.212-12.233"
                        transform="translate(6.401 18.56)"
                        fill="#ca9368"
                    />
                    <path
                        id="Trac\xE9_1398"
                        data-name="Trac\xE9 1398"
                        d="M104.5,75.765l8.341.012c-1.119,9.861-8.889,17.717-17.925,18.121-11.985.537-19.744-5.794-22.193-18.107q4.293-.012,8.587-.022c1.413,5.986,5.774,9.773,11.343,9.849,5.65.077,9.963-3.509,11.847-9.853"
                        transform="translate(39.857 41.522)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1399"
                        data-name="Trac\xE9 1399"
                        d="M6.8,75.783q4.159-.012,8.316-.02c1.988,6.451,6.191,9.949,11.844,9.855,5.533-.091,9.9-3.864,11.394-9.844q4.3.009,8.6.015C45.586,88.155,33.512,97.448,20.408,92.966,12.454,90.245,7.719,84.378,6.8,75.783"
                        transform="translate(3.729 41.521)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1400"
                        data-name="Trac\xE9 1400"
                        d="M44.375,15.2,2.759,15.2c-.553-8.3-.553-8.3,7.691-8.3,9.68,0,19.366.224,29.035-.105,4.143-.141,5.8.985,5.07,5.138a26.178,26.178,0,0,0-.18,3.259"
                        transform="translate(1.4 3.719)"
                        fill="#ca9368"
                    />
                    <path
                        id="Trac\xE9_1401"
                        data-name="Trac\xE9 1401"
                        d="M89.809,37.28c-3.861-1.89-8.181-1.242-12.211-2.217-1.935-.468-1.745,1.012-1.9,2.15-.625-1.42-1.367-2.689-3.209-2.6,5.026-3.807,5.039-7.988.031-11.8l.274-.294L95.53,18.457q.232,9.374.463,18.745c-2.051.831-4.135-.923-6.184.077"
                        transform="translate(39.727 10.115)"
                        fill="#ca9368"
                    />
                    <path
                        id="Trac\xE9_1402"
                        data-name="Trac\xE9 1402"
                        d="M87.836,38.619q-.232-9.372-.463-18.745c.921-2.492,2.353-4.5,5.352-3.892,3.028.619,3.4,3.129,3.4,5.723-.006,4.491-.02,8.982.006,13.473.014,2.294-.372,4.333-2.714,5.455-2.562,1.228-4.042-.452-5.581-2.014"
                        transform="translate(47.884 8.698)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1403"
                        data-name="Trac\xE9 1403"
                        d="M69.894,11.411c-.307,2.992-1.282,5.44-4.929,5.073-3.472-.348-3.725-3.048-3.787-5.729-.046-2.039-.087-4.087.05-6.118C61.4,2.1,62.439.028,65.332,0c2.768-.028,4.109,1.729,4.4,4.369.076.673.147,1.348.22,2.022l-.06,5.02"
                        transform="translate(33.504 -0.001)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1404"
                        data-name="Trac\xE9 1404"
                        d="M66.793,9.148l.06-5.02c5.621,1.331,8.581,5.017,8.779,11.135.163,5.033.194,10.072.283,15.107l-.274.3q-2.41-.1-4.821-.2c-.02-4.7.039-9.409-.088-14.109-.08-3.008-.474-5.986-3.94-7.211"
                        transform="translate(36.605 2.262)"
                        fill="#ca9368"
                    />
                    <path
                        id="Trac\xE9_1405"
                        data-name="Trac\xE9 1405"
                        d="M70.825,21.143l4.821.2c5.008,3.813,5,7.994-.031,11.8-3.588.913-6.547.206-8.181-3.427-1.78-3.96.358-6.466,3.392-8.572"
                        transform="translate(36.601 11.587)"
                        fill="#0e0d0d"
                    />
                    <path
                        id="Trac\xE9_1406"
                        data-name="Trac\xE9 1406"
                        d="M35.413,75.774c-1.494,5.98-5.859,9.753-11.392,9.844-5.653.093-9.856-3.4-11.846-9.856l23.238.012"
                        transform="translate(6.672 41.52)"
                        fill="#f7f7f4"
                    />
                    <path
                        id="Trac\xE9_1407"
                        data-name="Trac\xE9 1407"
                        d="M101.463,75.765c-1.884,6.344-6.2,9.931-11.847,9.853-5.568-.076-9.929-3.862-11.342-9.849l23.19,0"
                        transform="translate(42.897 41.522)"
                        fill="#f7f7f4"
                    />
                    <path
                        id="Trac\xE9_1408"
                        data-name="Trac\xE9 1408"
                        d="M20.9,60.684c.079,2.187-7.054,7.059-8.773,6.138a2.54,2.54,0,0,1-1.285-1.477c-.478-3.217,2.5-3.79,4.288-5.178,1.446-1.127,3.155-2.4,5.076-.709a3.912,3.912,0,0,1,.694,1.226"
                        transform="translate(5.916 32.136)"
                        fill="#0e0d0d"
                    />
                </g>
            </g>
        </g>
    </svg>
);
