import React from "react";
import { Row, Col } from "reactstrap";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";
import { MyBestShop } from "../MyBestShop";

export function MyBestShopRow() {
    return (
        <Row>
            <Col md="12">
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <CustomErrorBoundary>
                        <MyBestShop />
                    </CustomErrorBoundary>
                </React.Suspense>
            </Col>
        </Row>
    );
}
