import React from "react";
import { Row, Col } from "reactstrap";
import { useSnapshot } from "valtio";

import store from "@pages/Statistics/store";

import MiniCard from "@components/Common/MiniCard";

export default function Cards() {
    const statisticsStore = useSnapshot(store);

    return (
        <Row>
            {statisticsStore.regulationModesCards.modes?.map(
                (title: string, index: number) => {
                    return (
                        <Col key={`${title}_${index}`}>
                            <MiniCard
                                title={title}
                                amount={
                                    statisticsStore.regulationModesCards
                                        .amounts[index] || 0
                                }
                            />
                        </Col>
                    );
                }
            )}
        </Row>
    );
}
