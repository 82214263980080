import { combineReducers } from "@reduxjs/toolkit";
import { History } from "history";
import { connectRouter } from "connected-react-router";

import Layout from "./layout/reducer";

import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import changeType from "./dashboard/reducer";
import period from "./statistics/reducer";

import selections from "./selections/reducer";
import favorites from "./favorites/reducer";

import toggleTabReducer from "./my-orders/reducer";
import advancedDate from "./advanced-date/reducer";
import groups from "./settings/reducer";
import regulations from "./regulations/reducer";
import salesModes from "./sales-modes/reducer";
import salesSupports from "./sales-support/reducer";
import regulationType from "./regulation-type/reducer";
import regulationModes from "./regulation-modes/reducer";
import cardsModesSelections from "./cards-modes/reducer";
import groupDesignation from "./articles-settings/reducer";
import articlesGroup from "./articles/reducer";
import familiesGroupDesignation from "./families-settings/reducer";
import familiesGroup from "./families/reducer";
import subFamiliesGroupDesignation from "./sub-families-settings/reducer";

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        Layout,
        Login,
        Account,
        ForgetPassword,
        Profile,
        type: changeType,
        period,
        toggleTabReducer,
        selections,
        favorites,
        advancedDate,
        groups,
        regulations,
        salesModes,
        salesSupports,
        regulationType,
        regulationModes,
        cardsModesSelections,
        groupDesignation,
        articlesGroup,
        familiesGroupDesignation,
        familiesGroup,
        subFamiliesGroupDesignation,
    });

export type RootState = ReturnType<typeof createRootReducer>;

export default createRootReducer;
