import { AnyAction } from "redux";

import {
    CLEAN_CARDS_MODES,
    SELECT_CARDS_MODES,
    INIT_CARDS_MODES,
} from "./actions-types";

const INIT_STATE: CardsModesType = {
    modes: [],
    amounts: [],
};

export default function cardsModesSelections(
    state = INIT_STATE,
    action: AnyAction
): CardsModesType | null {
    switch (action.type) {
        case SELECT_CARDS_MODES:
            return action.payload;
        case INIT_CARDS_MODES:
            return action.payload;
        case CLEAN_CARDS_MODES:
            return action.payload;
        default:
            return state !== undefined ? state : null;
    }
}
