import React from "react";

export default function Blanket(props: any) {
    return (
        <div
            className="blanket__clz"
            style={{
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
                position: "fixed",
                zIndex: 1,
            }}
            {...props}
        />
    );
}
