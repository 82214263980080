import React from "react";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { mutate } from "swr";
import { isMobile } from "react-device-detect";
import { useSnapshot } from "valtio";
import { useKeycloak } from "@react-keycloak/web";

import MySelectionsContext from "@pages/MySelections/context";

import { useResource } from "@hooks/useResource";

import store, { setSavedSelection } from "@pages/MySelections/store";

import SpinnerWrapper from "@components/Common/SpinnerWrapper";

import SearchFranchisesFormGroupWrapper from "./SearchFranchisesFormGroupWrapper";
import SelectFranchiseFormGroupWrapper from "./SelectFranchiseFormGroupWrapper";
import MyFavoritesButtonWrapper from "./MyFavoritesButtonWrapper";
import ValidateSelectionButtonWrapper from "./ValidateSelectionButtonWrapper";
import SaveMySelectionModalWrapper from "./SaveMySelectionModalWrapper";
import SaveMySelectionButtonWrapper from "./SaveMySelectionButtonWrapper";
import { SavedSelectionButtonWrapper } from "./SavedSelectionButtonWrapper";

import "./index.css";

export default function FranchisesSelection() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { selectedSelection } = React.useContext(MySelectionsContext);

    const { checkedFranchises, savedSelection } = useSnapshot(store);

    const customSelectStyles = {
        container: () => {
            return {
                marginTop: "0.25rem",
            };
        },
        control: (provided: any) => ({
            ...provided,
            minWidth: isMobile ? "260px" : "200px",
            minHeight: "40px",
            height: "40px",
            margin: "1px",
            borderRadius: "5px",
            padding: "0px",
        }),
    };

    const {
        resourceData: { data: selectionsData },
    } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/selections/selections-list`
    );

    const [
        isSaveMySelectionModalOpened,
        setIsSaveMySelectionModalOpened,
    ] = React.useState<boolean>(false);

    function toggleOpeningIsSaveMySelectionModal() {
        if (
            checkedFranchises.length === 0 ||
            selectedSelection.label !== undefined
        ) {
            return swal({
                icon: "warning",
                content: <p>{t("Choose a selection")}!</p>,
            });
        }
        setIsSaveMySelectionModalOpened((prevState) => !prevState);
    }

    async function sendSavingSelectionRequest(
        selection: SelectionType,
        isValidate: boolean = false
    ) {
        try {
            await fetch(
                process.env.REACT_APP_API_V1_URL + "/selections/save-selection",
                {
                    headers: {
                        accept: "application/json",
                        "content-type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                        "x-authorization": `Bearer ${localStorage.getItem(
                            "jwt"
                        )}`,
                    },
                    body: JSON.stringify(selection),
                    method: "POST",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("Error while saving selection!");
                    }

                    const newSelections = [...savedSelection, selection];

                    setSavedSelection(newSelections);

                    if (isValidate === false) {
                        return swal({
                            icon: "success",
                            content: (
                                <p>{t("Selection saved successfully")}!</p>
                            ),
                            timer: 2000,
                        });
                    }
                });

            mutate(
                `${process.env.REACT_APP_API_V1_URL}/​selections​/selections-list`
            );
        } catch (_) {
            return swal({
                icon: "error",
                content: <p>{t("There's an error")!}</p>,
                timer: 2000,
            });
        }
    }

    React.useEffect(() => {
        setSavedSelection(selectionsData);
    }, [selectionsData]);

    return (
        <React.Suspense fallback={<SpinnerWrapper />}>
            <React.Fragment>
                <div className="grid-xl-franchises-selection__clz grid-gap-xxl__clz mb-4">
                    <div className="w-xs-100 w-sm-100 grid-md-franchises-selection__clz">
                        <SearchFranchisesFormGroupWrapper />
                        <SelectFranchiseFormGroupWrapper
                            customSelectStyles={customSelectStyles}
                        />
                    </div>
                    <div className="mt-0 mt-xl-4 w-sm-100 grid-md-franchises-selection-btn__clz grid-xl-franchises-selection-btn__clz">
                        <SavedSelectionButtonWrapper />
                        <MyFavoritesButtonWrapper />
                        <SaveMySelectionButtonWrapper
                            toggleOpeningModal={
                                toggleOpeningIsSaveMySelectionModal
                            }
                        />
                        <ValidateSelectionButtonWrapper
                            sendSavingSelectionRequest={
                                sendSavingSelectionRequest
                            }
                        />
                    </div>
                </div>
                {isSaveMySelectionModalOpened === true ? (
                    <SaveMySelectionModalWrapper
                        toggleOpeningModal={toggleOpeningIsSaveMySelectionModal}
                        sendSavingSelectionRequest={sendSavingSelectionRequest}
                    />
                ) : null}
            </React.Fragment>
        </React.Suspense>
    );
}
