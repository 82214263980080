import React from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import NProgress from "nprogress";
import { StaticContext } from "react-router";
import { useKeycloak } from "@react-keycloak/web";

import {
    clearType,
    initInclTaxType,
    initInclTaxTypeInTurnoverPage,
    initInclQuantityType,
} from "@store/dashboard/actions";
import { clearPeriod, getAdvancedPeriodDate } from "@store/actions";

import useGoogleAnalytics from "@hooks/useGoogleAnalytics";

import "nprogress/nprogress.css";

export default function AuthMiddleware({
    component: Component,
    layout: Layout,
}: any) {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    useGoogleAnalytics();

    React.useEffect(() => {
        NProgress.start();
        NProgress.done();
    }, []);

    return (
        <Route
            render={(
                props: RouteComponentProps<any, StaticContext, unknown>
            ) => {
                if (keycloak.authenticated === false) {
                    dispatch(clearType());

                    return (
                        <Redirect
                            to={{
                                pathname: "/sso",
                                state: { from: props.location },
                            }}
                        />
                    );
                } else {
                    if ("/employees-delivery-man" === props.location.pathname) {
                        dispatch(clearPeriod());
                        dispatch(
                            getAdvancedPeriodDate({
                                startDate: "",
                                endDate: "",
                            })
                        );
                    }

                    if (
                        [
                            "/regulation-modes",
                            "/dashboard",
                            "/employees-cashier",
                            "/employees-cook",
                            "/employees-delivery-man",
                        ].includes(props.location.pathname)
                    ) {
                        dispatch(clearType());
                    }

                    if (props.location.pathname === "/statistics-turnover") {
                        dispatch(initInclTaxTypeInTurnoverPage());
                    }

                    if (
                        [
                            "/statistics-sales-mode",
                            "/statistics-sales-support",
                            "/families",
                            "/sub-families",
                            "/statistics-products",
                        ].includes(props.location.pathname)
                    ) {
                        dispatch(initInclTaxType());
                    }

                    if (
                        "/statistics-out-of-stock" === props.location.pathname
                    ) {
                        dispatch(initInclQuantityType());
                    }

                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    );
                }
            }}
        />
    );
}
