import React from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "@helpers/general";

import {
    InDeliverySvgIcon,
    TakeOutSvgIcon,
    OnTheSpotSvgIcon,
    AllSalesMethodsSvgIcon,
    TablePlanSvgIcon,
    CheckoutSvgIcon,
    ClickAndCollectSvgIcon,
    DeliverooSvgIcon,
    ThickHeadedSvgIcon,
    UberEatsSvgIcon,
    ShopSvgIcon,
    PlatformSvgIcon,
    AllSalesSupportSvgIcon,
    FidelitySvgIcon,
    SoftapaySvgIcon,
    BankCardSvgIcon,
    CheckSvgIcon,
    RestaurantTicketSvgIcon,
    CoinOperatedCashSvgIcon,
    CashBoxSvgIcon,
    DigitalPaymentMethodSvgIcon,
    PhysicalPaymentMethodSvgIcon,
    AllPaymentMethodsSvgIcon,
    WebsiteSvgIcon,
    ApplicationSvgIcon,
    TabletteSvgIcon,
    FamiliesAndSubfamiliesSvgIcon,
    FamiliesSvgIcon,
    SubFamiliesSvgIcon,
} from "@components/Common/SvgIcons";

type IconWrapperPropsType = {
    title: string;
};

export default function IconWrapper({ title }: IconWrapperPropsType) {
    const { t } = useTranslation();

    function getIcon(title: string): JSX.Element {
        switch (title) {
            case t(capitalize("All sales methods")):
                return <AllSalesMethodsSvgIcon />;
            case t(capitalize("delivery")):
                return <InDeliverySvgIcon />;
            case t(capitalize("to take")):
                return <TakeOutSvgIcon />;
            case t(capitalize("on the spot")):
                return <OnTheSpotSvgIcon />;
            case t(capitalize("seating plan")):
                return <TablePlanSvgIcon />;
            case t(capitalize("box")):
                return <CheckoutSvgIcon />;
            case t(capitalize("thick headed")):
                return <ThickHeadedSvgIcon />;
            case t(capitalize("application")):
                return <ApplicationSvgIcon />;
            case t(capitalize("web")):
                return <WebsiteSvgIcon />;
            case t(capitalize("UBER")):
                return <UberEatsSvgIcon />;
            case t(capitalize("DELIVEROO")):
                return <DeliverooSvgIcon />;
            case t(capitalize("shop")):
                return <ShopSvgIcon />;
            case t(capitalize("platform")):
                return <PlatformSvgIcon />;
            case t(capitalize("click_&_collect")):
                return <ClickAndCollectSvgIcon />;
            case t(capitalize("all sales supports")):
                return <AllSalesSupportSvgIcon />;
            case t(capitalize("Fidelity")):
                return <FidelitySvgIcon />;
            case t(capitalize("Softpay")):
                return <SoftapaySvgIcon />;
            case t(capitalize("Bank card")):
                return <BankCardSvgIcon />;
            case t(capitalize("Check")):
                return <CheckSvgIcon />;
            case t(capitalize("Restaurant coupon")):
                return <RestaurantTicketSvgIcon />;
            case t(capitalize("Coin acceptor")):
                return <CoinOperatedCashSvgIcon />;
            case t(capitalize("Digital Payment Method")):
                return <DigitalPaymentMethodSvgIcon />;
            case t(capitalize("Physical Payment Method")):
                return <PhysicalPaymentMethodSvgIcon />;
            case t(capitalize("All regulation methods")):
                return <AllPaymentMethodsSvgIcon />;
            case t(capitalize("Cash box")):
                return <CashBoxSvgIcon />;
            case t(capitalize("Families and sub-families")):
                return <FamiliesAndSubfamiliesSvgIcon />;
            case t(capitalize("families")):
                return <FamiliesSvgIcon />;
            case t(capitalize("sub-families")):
                return <SubFamiliesSvgIcon />;
            case t(capitalize("Tablette")):
                return <TabletteSvgIcon />;
            default:
                return <AllSalesMethodsSvgIcon />;
        }
    }

    return (
        <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
            <span className="avatar-title bg-dark">
                <div className="font-size-24">{getIcon(title)}</div>
            </span>
        </div>
    );
}
