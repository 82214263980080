import * as React from "react";
import { SVGProps } from "react";
export const ClickAndCollectSvgIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={63.85}
        height={54.851}
        viewBox="0 0 342 342"
        {...props}
    >
        <defs>
            <filter
                id="Rectangle_13560"
                x={0}
                y={0}
                width={342}
                height={342}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.161} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_13570"
                    data-name="Rectangle 13570"
                    width={166.252}
                    height={197.976}
                    fill="none"
                />
            </clipPath>
        </defs>
        <g
            id="Groupe_1028"
            data-name="Groupe 1028"
            transform="translate(-4710 6583)"
        >
            <g
                transform="matrix(1, 0, 0, 1, 4710, -6583)"
                filter="url(#Rectangle_13560)"
            >
                <rect
                    id="Rectangle_13560-2"
                    data-name="Rectangle 13560"
                    width={324}
                    height={324}
                    rx={50}
                    transform="translate(9 6)"
                    fill="#f8f8f6"
                />
            </g>
            <g
                id="Groupe_1002"
                data-name="Groupe 1002"
                transform="translate(4797.874 -6513.988)"
            >
                <g
                    id="Groupe_1001"
                    data-name="Groupe 1001"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="Trac\xE9_1388"
                        data-name="Trac\xE9 1388"
                        d="M119.7,178.912c-5.633.1-11.267.281-16.9.286-32.028.027-64.057-.079-96.085.11C1.91,179.336.36,177.84.394,173.087q.135-18.636-.355-37.28c-.438-16.714,2.8-33.068,8.082-49.068,4.232-12.829,8.19-25.8,10.8-39.1.835-4.256,2.742-6.476,7.4-5.866a45.225,45.225,0,0,0,6.955-.118l4.146.016H49.593l3.879,0,39.989.046,3.744,0,13.137,0,3.46-.015c4.311.024,8.623.108,12.933.05,2.493-.034,3.7.85,4.536,3.431,5.149,15.856,10.551,31.63,15.886,47.425,6.369,18.858,8.939,38.158,6.8,58.011L101.415,130.9c6.333,16.629,12.309,32.322,18.286,48.015"
                        fill="#c08050"
                    />
                    <path
                        id="Trac\xE9_1389"
                        data-name="Trac\xE9 1389"
                        d="M119.7,178.912c-5.977-15.693-11.953-31.386-18.286-48.015l52.542,19.734,9.669,4.343c-2.856,1.548-5.858,1.781-8.7,2.51l-11.8,3.37,11.737,12.526c3.325,3.446,6.537,7.011,10.02,10.289,1.909,1.8,1.749,2.851-.054,4.6-2.711,2.632-5.08,5.616-8.709,9.706-6.177-6.813-11.62-12.818-17.062-18.822a32.959,32.959,0,0,0-7.44-6.972c-.8,2.417-1.677,4.535-1.616,6.876l-.126.074c-.548-.012-.726.246-.551.759-1.083,4.307-2.166,8.614-3.41,13.563L119.7,178.912"
                        fill="#0b0a08"
                    />
                    <path
                        id="Trac\xE9_1390"
                        data-name="Trac\xE9 1390"
                        d="M37.418,41.674l-4.145-.016c-.59-14.05,4.067-25.964,14.857-35.114,6.488-5.5,14.109-8.183,22.516-5.485,5.076,1.629,10.165.215,15.161,1.276,16.72,3.55,23.989,15.525,27.382,30.989.594,2.711.428,5.589.613,8.39l-3.46.015a64.936,64.936,0,0,0-4.388-19.6C103.089,14.711,92.809,6.273,83.437,6.95c9.12,10.074,14.746,21.2,13.769,34.777l-3.745,0c.375-9.736-2.738-18.247-7.928-26.49-5.4-8.576-13.8-10.182-20.354-2.671-7.25,8.305-11.811,17.8-11.707,29.115q-1.939,0-3.879,0C49.952,26.6,56.038,14.409,68.577,5.343c-7.87-2.354-20.462,2.949-24.3,10.215C39.982,23.684,36,32,37.418,41.674"
                        fill="#0b0a08"
                    />
                    <path
                        id="Trac\xE9_1391"
                        data-name="Trac\xE9 1391"
                        d="M154.866,173.38l-11.737-12.526,11.8-3.37q-.034,7.949-.064,15.9"
                        fill="#c08050"
                    />
                    <path
                        id="Trac\xE9_1392"
                        data-name="Trac\xE9 1392"
                        d="M130.005,179.058c-.061-2.341.813-4.46,1.616-6.876a32.91,32.91,0,0,1,7.439,6.972l-9.055-.1"
                        fill="#c08050"
                    />
                    <path
                        id="Trac\xE9_1393"
                        data-name="Trac\xE9 1393"
                        d="M129.328,179.891c-.175-.514,0-.771.551-.759l-.551.759"
                        fill="#c08050"
                    />
                </g>
            </g>
        </g>
    </svg>
);
