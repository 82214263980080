import { AnyAction } from "redux";
import {
    ADD_FAVORITES,
    CLEAN_FAVORITES,
    REMOVE_FAVORITES,
} from "./actions-types";

const INIT_STATE: any[] = [];

export default function favorites(
    state = INIT_STATE,
    action: AnyAction
): any[] | null {
    switch (action.type) {
        case ADD_FAVORITES:
            return state.concat(action.payload);
        case CLEAN_FAVORITES:
            return action.payload;
        case REMOVE_FAVORITES:
            return state.filter(function (fav: any) {
                return (
                    fav.shop_id !== action.payload.shop_id ||
                    fav.franchise_id !== action.payload.franchise_id
                );
            });
        default:
            return state !== undefined ? state : null;
    }
}
