import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";

import { store } from "@vStore";

export default function MyFavoritesButtonWrapper() {
    const { t } = useTranslation();

    const { mainColor } = useSnapshot(store);
    const favorites = useSelector(
        (state: { favorites: any[] }) => state.favorites
    );

    return (
        <Button
            type="button"
            color={mainColor === null ? "secondary" : ""}
            outline={true}
            className="w-xs-100 w-sm-100 mt-2 mt-md-0 franchises-font-size-xl__clz"
            style={{ borderColor: mainColor, color: mainColor }}
        >
            {`${t("My favorites")} (${favorites ? favorites.length : 0})`}
        </Button>
    );
}
