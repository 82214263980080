import React from "react";
import i18n from "i18next";

type StatisticsContextType = {
    period: string;
    type: string;
    advancedDate: {
        startDate: string;
        endDate: string;
    };
    selections: SelectionType;
    percentageData?: any[];
    histogramData?: any[];
    categoriesData?: any[];
    setHistogramData?: Function;
    setPercentageData?: Function;
    setCategoriesData?: Function;
    salesSupportSelection?: string[];
    setSalesSupportSelection?: Function;
    salesModesSelection?: string[];
    setSalesModesSelection?: Function;
    servicesSelection?: string[];
    setServicesSelection?: Function;
    top10FamiliesSelection?: string;
    setTop10FamiliesSelection?: Function;
    top10SubFamiliesSelection?: string;
    setTop10SubFamiliesSelection?: Function;
    top10ProductsSelection?: string;
    setTop10ProductsSelection?: Function;
    employees?: string;
    setEmployees?: Function;
    employeesList?: string[];
    setEmployeesList?: Function;
};

export const StatisticsContext = React.createContext<StatisticsContextType>({
    period: "",
    type: "",
    advancedDate: {
        startDate: "",
        endDate: "",
    },
    selections: {
        designation: "",
        selection: [],
    },
    percentageData: [],
    histogramData: [],
    categoriesData: [],
    setPercentageData: () => {},
    setHistogramData: () => {},
    setCategoriesData: () => {},
    salesSupportSelection: [i18n.t("All sales supports")],
    setSalesSupportSelection: () => {},
    salesModesSelection: [i18n.t("All sales methods")],
    setSalesModesSelection: () => {},
    servicesSelection: [i18n.t("All services")],
    setServicesSelection: () => {},
    top10FamiliesSelection: "",
    setTop10FamiliesSelection: () => {},
    top10SubFamiliesSelection: "",
    setTop10SubFamiliesSelection: () => {},
    top10ProductsSelection: "",
    setTop10ProductsSelection: () => {},
    employees: "",
    employeesList: [],
    setEmployees: () => {},
    setEmployeesList: () => {},
});
