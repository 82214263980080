import React from "react";

export default function AllPaymentMethodsSvgIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                </clipPath>
            </defs>
            <g data-name="Groupe 8763">
                <g data-name="Mask group">
                    <path
                        data-name="icon bg"
                        d="M0 24a24 24 0 1124 24A24 24 0 010 24z"
                        fill="#333"
                    />
                    <g data-name="Mask group" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 2"
                            d="M33.901-1l6.764 4.24L12.128 49.03l-6.764-4.24z"
                            fill="rgba(255,255,255,0.1)"
                        />
                    </g>
                </g>
                <g transform="translate(10 8.879)" fill="#fff">
                    <path
                        data-name="Trac\xE9 8560"
                        d="M27.3 14v-3.414a3.118 3.118 0 00-3-3.112v-2.1a1.985 1.985 0 00-1.982-1.982h-5.459l-.7-1.959A1.982 1.982 0 0013.61.241l-9.9 3.6a1.983 1.983 0 00-1.187 2.55l.4 1.086A3.119 3.119 0 000 10.586v15.406a3.119 3.119 0 003.115 3.115h21.07a3.119 3.119 0 003.115-3.115V22.46A1.985 1.985 0 0029 20.5v-4.534A1.985 1.985 0 0027.3 14zm0 6.493a.284.284 0 01-.283.283h-4.531a2.549 2.549 0 010-5.1h4.531a.284.284 0 01.283.283zM4.119 5.806a.283.283 0 01.169-.364l9.9-3.6a.283.283 0 01.363.17l.5 1.384h-.381a1.985 1.985 0 00-1.982 1.982v2.1h-7.96zM22.6 5.375v2.1h-8.213v-2.1a.284.284 0 01.283-.283h7.646a.284.284 0 01.284.283zm1.586 22.033H3.115A1.418 1.418 0 011.7 25.992V10.586A1.418 1.418 0 013.115 9.17h21.07a1.418 1.418 0 011.415 1.416v3.4h-3.114a4.248 4.248 0 100 8.5H25.6v3.512a1.418 1.418 0 01-1.414 1.41z"
                    />
                    <circle
                        data-name="Ellipse 634"
                        cx={0.85}
                        cy={0.85}
                        r={0.85}
                        transform="translate(21.637 17.382)"
                    />
                </g>
            </g>
        </svg>
    );
}
