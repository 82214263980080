import i18n from "i18next";

import { CHANGE_PERIOD, CLEAR_PERIOD } from "./actions-types";

type PeriodActionType = {
    type: string;
    payload: any;
};

const INIT_STATE: InitStateType = i18n.t("Current week");

export default function period(
    state = INIT_STATE,
    action: PeriodActionType
): string | null {
    if (action.type === CHANGE_PERIOD) {
        return action.payload;
    } else if (action.type === CLEAR_PERIOD) {
        return action.payload;
    } else {
        return state !== undefined ? state : null;
    }
}
