import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store as vStore } from "@vStore";
import { store, setSelectedGroups } from "@pages/Statistics/Shops/store";

type PropsType = { group: string };

export function SelectedGroupWrapper({ group }: PropsType) {
    const { selectedGroups } = useSnapshot(store);
    const { mainColor } = useSnapshot(vStore);

    function handleRemoveGroupFromSelectedGroups(group: string) {
        const newSelectedGroups = selectedGroups.filter(
            (selectedGroup) => selectedGroup !== group
        );
        setSelectedGroups(newSelectedGroups);
    }

    return (
        <div
            className={classNames("mr-1 mb-1 p-2 badge", {
                "badge-dark": mainColor === null,
                "text-white": mainColor !== null,
            })}
            style={{ background: mainColor }}
        >
            {group}
            <span
                className="uppercase-text ml-1"
                onClick={() => handleRemoveGroupFromSelectedGroups(group)}
                style={{ cursor: "pointer" }}
            >
                X
            </span>
        </div>
    );
}
