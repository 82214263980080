import { proxy } from "valtio";
import i18n from "i18next";

import { keycloak } from "@keycloak";

type StoreType = {
    shops: Array<MyShopsType | string>;
    groups: string[];
    selectedShops: Array<MyShopsType>;
    selectedGroups: Array<string>;
    limitArticles: string | number;
    limitCategories: string | number;
};

export const store = proxy<StoreType>({
    shops: [],
    groups: [],
    selectedShops: [],
    selectedGroups: [],
    limitArticles: 5,
    limitCategories: 5,
});

export async function fetchShops() {
    store.shops = [i18n.t("All shops")];
    store.selectedShops = [];
    try {
        const token = localStorage.getItem("jwt");
        const response = await fetch(
            `${process.env.REACT_APP_API_V1_URL}/selections/franchises-shops-list`,
            {
                headers: {
                    authorization: `Bearer ${keycloak.token}`,
                    "x-authorization": `Bearer ${token}`,
                },
            }
        );

        if (response.status === 401) {
            return keycloak.logout();
        }

        const { data } = await response.json();

        const rawShops: MyShopsType[] = [];

        data.forEach((datum: any) => {
            datum.shops.forEach((shop: any) => {
                rawShops.push({
                    franchiseId: datum.id,
                    shopId: shop.id,
                    shopName: shop.name,
                });
            });
        });

        store.shops = [i18n.t("All shops"), ...rawShops];
        store.selectedShops = [...rawShops];
    } catch (error) {
        console.log(
            `There's an error while fetching shops, with message: ${
                (error as Error).message
            }`
        );
    }
}

export async function fetchGroups() {
    store.groups = [i18n.t("All selections")];
    store.selectedGroups = [];
    try {
        const token = localStorage.getItem("jwt");
        const response = await fetch(
            `${process.env.REACT_APP_API_V1_URL}/selections/selections-list`,
            {
                headers: {
                    authorization: `Bearer ${keycloak.token}`,
                    "x-authorization": `Bearer ${token}`,
                },
            }
        );

        if (response.status === 401) {
            return keycloak.logout();
        }

        const { data } = await response.json();
        const rawGroups: string[] = [];
        data.forEach((datum: any) => {
            rawGroups.push(datum.designation);
        });
        store.groups = [i18n.t("All selections"), ...rawGroups];
        store.selectedGroups = [...rawGroups];
    } catch (error) {
        console.log(
            `There's an error while fetching groups, with message: ${
                (error as Error).message
            }`
        );
    }
}

export function setShops(shops: Array<MyShopsType | string>) {
    store.shops = JSON.parse(JSON.stringify(shops));
}

export function setSelectedShops(shops: Array<MyShopsType>) {
    store.selectedShops = JSON.parse(JSON.stringify(shops));
}

export function setSelectedGroups(groups: Array<string>) {
    store.selectedGroups = JSON.parse(JSON.stringify(groups));
}

export function setGroups(groups: string[]) {
    store.groups = JSON.parse(JSON.stringify(groups));
}

export function setLimitArticles(limitArticles: string | number) {
    store.limitArticles = limitArticles;
}
export function setLimitCategories(limitCategories: string | number) {
    store.limitCategories = limitCategories;
}
