import React from "react";
import { Row, Col, Card, CardBody, Nav, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import faker from "faker";

import { getArrayOfRandomNumbers } from "@helpers/general";

import DonutChart from "../CommonCharts/DonutChart";
import ApexLineColumn from "../CommonCharts/ApexLineColumn";

export default function Top10FamiliesRow() {
    const { t } = useTranslation();

    const top10AdditionalSaleAndOffers: string[] = Array(10)
        .fill(null)
        .map((e) => (e = faker.fake("{{random.word}}")));

    const top10AdditionalSaleAndOffersChartSeries: SeriesDataType[] = [
        {
            name: top10AdditionalSaleAndOffers[0],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[1],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[2],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[3],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[4],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[5],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[6],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[7],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[8],
            data: getArrayOfRandomNumbers!(7),
        },
        {
            name: top10AdditionalSaleAndOffers[9],
            data: getArrayOfRandomNumbers!(7),
        },
    ];

    const DonutChartTop10AdditionalSaleAndOffersData: SeriesDataType = {
        data: getArrayOfRandomNumbers!(10),
        labels: top10AdditionalSaleAndOffers,
    };

    return (
        <Row className="top10-additional-sale-and-offer__clz">
            <Col md="4" sm="4">
                <DonutChart
                    title={t("Top 10 families")}
                    data={DonutChartTop10AdditionalSaleAndOffersData}
                />
            </Col>
            <Col md="8" sm="8">
                <Card>
                    <CardBody className="card-body__clz card-body__clz-sm">
                        <Nav pills className="navtab-bg nav__clz nav__clz-sm">
                            <Link
                                style={{ paddingTop: "8px" }}
                                to="/turnover-detail"
                                className="btn btn-outline-dark cards btn-sm float-right d-none"
                            >
                                {t("See more")}{" "}
                                <i className="mdi mdi-arrow-right ml-1"></i>
                            </Link>
                        </Nav>
                        <CardBody className="pb-0 chart-order chart-order-sm">
                            <TabPane className="p-2">
                                <div className="apex-charts">
                                    <ApexLineColumn
                                        data={
                                            top10AdditionalSaleAndOffersChartSeries
                                        }
                                    />
                                </div>
                            </TabPane>
                        </CardBody>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
