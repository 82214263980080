import { ADVANCED_DATE } from "./actions-types";

type ActionParamType = {
    startDate: string;
    endDate: string;
};

export const getAdvancedPeriodDate = (
    date: ActionParamType
): {
    type: string;
    payload: ActionParamType;
} => {
    return {
        type: ADVANCED_DATE,
        payload: date,
    };
};
