import { AnyAction } from "redux";
import { FAMILIES_GROUP_DESIGNATION } from "./actions-types";

const INIT_STATE: string = "";

export default function familiesGroupDesignation(
    state = INIT_STATE,
    action: AnyAction
): string | null {
    if (action.type === FAMILIES_GROUP_DESIGNATION) {
        return action.payload;
    } else {
        return state !== undefined ? state : null;
    }
}
