import React from "react";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import SpinnerWrapper from "@components/Common/SpinnerWrapper";

import "../index.css";

export default function SegmentContent({ title, content }: TabsType) {
    return (
        <React.Fragment>
            <div
                className="d-md-flex justify-content-md-between justify-content-center"
                style={{
                    backgroundColor: "rgb(249, 249, 249)",
                    borderTopRightRadius: "calc(0.3rem - 1px)",
                }}
            >
                <h4 className="mx-3 py-2 mb-0 align-self-center white-space-nowrap">
                    {title}
                </h4>
            </div>

            <React.Suspense fallback={<SpinnerWrapper />}>
                <CustomErrorBoundary>{content}</CustomErrorBoundary>
            </React.Suspense>
        </React.Fragment>
    );
}
