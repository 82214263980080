import React from "react";
import { Redirect } from "react-router-dom";

import { UserProfile } from "@pages/Authentication/UserProfile";
import LockScreen from "@pages/Authentication/LockScreen";

import Dashboard from "@pages/Dashboard";

import MySelections from "@pages/MySelections";
import MySelectionsList from "@pages/MySelections/MySelectionsList";

import StatisticsTickets from "@pages/Statistics/StatisticsTickets";
import StatisticsTurnover from "@pages/Statistics/StatisticsTurnover";
import StatisticsAverageBasket from "@pages/Statistics/StatisticsAverageBasket";
import StatisticsRegulationModes from "@pages/Statistics/RegulationModes";

import SalesMode from "@pages/Statistics/SalesMode/index";
import SaleSupport from "@pages/Statistics/SalesSupport/index";
import Products from "@pages/Statistics/NewProducts";
import AdditionalOffersAndSales from "@pages/Statistics/AdditionalOffersAndSales";

import Settings from "@pages/Settings";
import Families from "@pages/Statistics/Families";
import SubFamilies from "@pages/Statistics/Subfamilies";
import Cashier from "@pages/Statistics/Employees/Cashier";
import Cook from "@pages/Statistics/Employees/Cook";
import DeliveryMan from "@pages/Statistics/Employees/Delivery-man";
import OutOfStock from "@pages/Statistics/OutOfStock";
import Transactions from "@pages/Statistics/Transactions";
import Registration from "@pages/Registration";
import Home from "@pages/Home";
import { ChangePassword } from "@pages/Authentication/ChangePassword";
import { Shops } from "@pages/Statistics/Shops";
import { Clients } from "@pages/Statistics/Clients";
import { LoginWithSSO } from "@pages/Authentication/LoginWithSSO";

const userRoutes = [
    { path: "/profile", component: UserProfile },

    { path: "/my-selections", component: MySelections },
    { path: "/my-selection-list", component: MySelectionsList },
    { path: "/dashboard", component: Dashboard },

    { path: "/statistics-settings", component: Settings },

    { path: "/regulation-modes", component: StatisticsRegulationModes },
    { path: "/statistics-sales-mode", component: SalesMode },
    { path: "/statistics-sales-support", component: SaleSupport },
    { path: "/families", component: Families },
    { path: "/sub-families", component: SubFamilies },
    { path: "/statistics-products", component: Products },
    { path: "/statistics-turnover", component: StatisticsTurnover },
    { path: "/statistics-tickets", component: StatisticsTickets },
    { path: "/statistics-average-basket", component: StatisticsAverageBasket },
    {
        path: "/statistics-add-offers-and-sale",
        component: AdditionalOffersAndSales,
    },

    { path: "/employees-cashier", component: Cashier },
    { path: "/employees-cook", component: Cook },
    { path: "/employees-delivery-man", component: DeliveryMan },
    { path: "/transactions", component: Transactions },
    { path: "/statistics-out-of-stock", component: OutOfStock },
    { path: "/home", component: Home },
    { path: "/shops", component: Shops },
    { path: "/clients", component: Clients },
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/sso" /> },
];

const authRoutes = [
    { path: "/sso", component: LoginWithSSO },
    { path: "/auth-lock-screen", component: LockScreen },
    { path: "/registration", component: Registration },
    { path: "/change-password", component: ChangePassword },
];

export { userRoutes, authRoutes };
